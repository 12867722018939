'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('Rooms', [
    '$rootScope',
    'BAS_CURRENT_CORE',
    'BAS_APP_PROFILE',
    'BAS_ROOMS',
    'BAS_ROOM',
    'BAS_SOURCES',
    'BAS_UNITS',
    'BAS_SHARED_SERVER_STORAGE',
    'BAS_INTL',
    'BAS_SOURCE',
    'CurrentBasCore',
    'RoomFilter',
    'BasCollection',
    'BasRoomBuilding',
    'BasRoomFloor',
    'BasRoomsComponent',
    'RoomsHelper',
    'SourcesHelper',
    'BasCurrentAppProfile',
    'BasIntercomHelper',
    'BasRoom',
    'BasUtilities',
    'Logger',
    Rooms
  ])

/**
 * @callback CBasForEachRoom
 * @param {BasRoom} room
 * @param {number} index
 * @param {string[]} uuids
 */

/**
 * @typedef {Object} BasRoomControl
 * @property selectCurrentFilter
 */

/**
 * Manages rooms
 *
 * @constructor
 * @param $rootScope
 * @param {BAS_CURRENT_CORE} BAS_CURRENT_CORE
 * @param {BAS_APP_PROFILE} BAS_APP_PROFILE
 * @param BAS_ROOMS
 * @param {BAS_ROOM} BAS_ROOM
 * @param {BAS_SOURCES} BAS_SOURCES
 * @param {BAS_UNITS} BAS_UNITS
 * @param {BAS_SHARED_SERVER_STORAGE} BAS_SHARED_SERVER_STORAGE
 * @param {BAS_INTL} BAS_INTL
 * @param {BAS_SOURCE} BAS_SOURCE
 * @param {CurrentBasCore} CurrentBasCore
 * @param RoomFilter
 * @param BasCollection
 * @param BasRoomBuilding
 * @param BasRoomFloor
 * @param BasRoomsComponent
 * @param {RoomsHelper} RoomsHelper
 * @param {SourcesHelper} SourcesHelper
 * @param {BasCurrentAppProfile} BasCurrentAppProfile
 * @param {BasIntercomHelper} BasIntercomHelper
 * @param BasRoom
 * @param {BasUtilities} BasUtilities
 * @param Logger
 */
function Rooms (
  $rootScope,
  BAS_CURRENT_CORE,
  BAS_APP_PROFILE,
  BAS_ROOMS,
  BAS_ROOM,
  BAS_SOURCES,
  BAS_UNITS,
  BAS_SHARED_SERVER_STORAGE,
  BAS_INTL,
  BAS_SOURCE,
  CurrentBasCore,
  RoomFilter,
  BasCollection,
  BasRoomBuilding,
  BasRoomFloor,
  BasRoomsComponent,
  RoomsHelper,
  SourcesHelper,
  BasCurrentAppProfile,
  BasIntercomHelper,
  BasRoom,
  BasUtilities,
  Logger
) {
  var serviceName = 'Rooms'

  /**
   * @type {TCurrentBasCoreState}
   */
  var currentBasCoreState = CurrentBasCore.get()

  /**
   * @type {BasCurrentAppProfileState}
   */
  var currentProfile = BasCurrentAppProfile.get()

  var FILTER_ORDER_BUILDINGS = 10
  var FILTER_ORDER_FLOORS = 100
  var FILTER_ORDER_TAGS = 1000

  /**
   * @type {BasRooms}
   */
  var rooms = BAS_ROOMS.ROOMS

  /**
   * @type {BasRoomControl}
   */
  var basRoomControl = {
    selectCurrentFilter: selectCurrentFilter
  }

  var _sortedRooms = []
  var _sortedRoomsLength = 0
  var _sortedRoomsMusicGroups = []
  var _sortedRoomsMusicGroupsLength = 0
  var _sortedRoomsAVGroups = []
  var _sortedRoomsAVGroupsLength = 0

  this.getControl = getControl
  this.turnOffAllZones = turnOffAllZones
  this.clear = clear

  init()

  function init () {

    rooms.rooms = {}
    rooms.root = ''

    rooms.home = new BasRoomsComponent()
    rooms.music = new BasRoomsComponent()
    rooms.video = new BasRoomsComponent()
    rooms.thermostats = new BasRoomsComponent()
    rooms.openCloseDevices = new BasRoomsComponent()
    rooms.intercom = new BasRoomsComponent()

    resetRoomsInfo()

    // Current Core events
    $rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_CORE_CONNECTED,
      onServerConnected
    )
    $rootScope.$on(
      BAS_CURRENT_CORE.EVT_CURRENT_CORE_CHANGED,
      onCurrentCoreChanged
    )
    $rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_MUSIC_RECEIVED,
      onMusicConfig
    )
    $rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_ROOMS_RECEIVED,
      onRoomsUpdated
    )
    $rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_TIMERS_ORDER,
      onTimersOrderUpdated
    )
    $rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_SCHEDULES_ORDER,
      onSchedulesOrderUpdated
    )

    $rootScope.$on(
      BAS_ROOM.EVT_CORE_CLIENTS_UPDATED,
      onCoreClientsUpdated
    )
    $rootScope.$on(
      BAS_APP_PROFILE.EVT_INTERCOM_ROOM_SET,
      processRoomsInfo
    )
    $rootScope.$on(
      BAS_SHARED_SERVER_STORAGE.EVT_SCENE_ORDER_UPDATED,
      onScenesOrderUpdated
    )
    $rootScope.$on(
      BAS_SHARED_SERVER_STORAGE.EVT_SCENE_FAVOURITES_UPDATED,
      onScenesFavouritesUpdated
    )
    $rootScope.$on(
      BAS_SOURCE.EVT_AVAILABLE_CHANGE,
      onSourceAvailableChanged
    )
    $rootScope.$on(
      BAS_SOURCE.EVT_COMPATIBLE_ROOMS_CHANGE,
      onCompatibleRoomsChanged
    )
    $rootScope.$on(
      BAS_SOURCE.EVT_LISTENING_ROOMS_CHANGE,
      onListeningRoomsChanged
    )

    $rootScope.$on(
      BAS_SOURCES.EVT_SOURCES_UPDATED,
      onSourcesUpdated
    )
    $rootScope.$on(
      BAS_ROOM.EVT_OPEN_CLOSE_DEVICES_INITIALIZED,
      onRoomOpenCloseChanged
    )
    $rootScope.$on(
      BAS_ROOM.EVT_OPEN_CLOSE_DEVICES_UPDATED,
      onRoomOpenCloseChanged
    )
    $rootScope.$on(
      BAS_ROOM.EVT_SOURCE_CHANGED,
      onRoomSourceChanged
    )
    $rootScope.$on(
      BAS_ROOM.EVT_ACTIVITY_CHANGED,
      onRoomActivityChanged
    )
    $rootScope.$on(
      BAS_ROOM.EVT_IMAGES_UPDATED,
      onRoomImageUpdated
    )
    $rootScope.$on(
      BAS_ROOM.EVT_SCENES_UPDATED,
      onRoomScenesUpdated
    )
    $rootScope.$on(
      BAS_ROOM.EVT_GENERIC_DEVICES_INITIALIZED,
      onGenericDevicesInitialized
    )

    // App config changes
    $rootScope.$on(
      BAS_UNITS.EVT_TEMPERATURE_UNIT_CHANGED,
      updateThermostatUnit
    )
    $rootScope.$on(
      BAS_INTL.EVT_TIME_FORMAT_CHANGED,
      onTimeFormatChanged
    )
    $rootScope.$on(
      '$translateChangeSuccess',
      updateTranslations
    )
  }

  /**
   * Returns the control object
   *
   * @returns {BasRoomControl}
   */
  function getControl () {
    return basRoomControl
  }

  function turnOffAllZones () {
    RoomsHelper.forEachRoom(_onRoomDoMusicToggle)
  }

  /**
   * @private
   * @param {BasRoom} room
   */
  function _onRoomDoMusicToggle (room) {
    if (
      room.music &&
      room.music.toggle &&
      room.variant !== BAS_ROOM.VAR_AV_GROUP
    ) {

      room.music.toggle(false)
    }
  }

  /**
   * Clears the core variables and core listener.
   * Does not clear the Angular listeners.
   */
  function clear () {
    clearRooms()
    resetRoomsInfo()
  }

  function onServerConnected () {

    Logger.debug(serviceName + ' - Server Connected')

    resetRoomsInfo()
  }

  /**
   * Clear rooms because information is not valid anymore
   */
  function onCurrentCoreChanged () {

    Logger.debug(serviceName + ' - Current Core changed')

    suspendRooms()
  }

  /**
   * Sync with new API variables
   */
  function onMusicConfig () {

    Logger.info(serviceName + ' - Music config')

    syncApi()
  }

  function onRoomsUpdated () {

    Logger.info(serviceName + ' - Rooms updated')

    syncApi()
  }

  /**
   * @param _event
   * @param {string} type
   */
  function onSourcesUpdated (_event, type) {

    var _core

    Logger.info(serviceName + ' - Sources updated')

    RoomsHelper.forEachRoom(_roomOnSourcesUpdated)

    if (type === BAS_SOURCES.T_EVT_SOURCES) {

      if (CurrentBasCore.hasCore()) {

        _core = currentBasCoreState.core.core

        if (_core.supportsSystemProperties) {

          if (_core.system && !_core.system.propertiesDirty) {

            if (
              CurrentBasCore.hasAVPartialSupport() ||
              CurrentBasCore.hasAVFullSupport()
            ) {

              if (_core.roomsReceived) {

                syncAVGroups()

                _postRoomSync()
                _generateSortedCollections()
                processRoomsInfoMusic()

                $rootScope.$emit(BAS_ROOMS.EVT_ROOMS_UPDATED, BAS_ROOMS.P_AV)
              }
            }
          }
        }
      }
    }

    /**
     * @private
     * @param {BasRoom} room
     */
    function _roomOnSourcesUpdated (room) {

      if (room.music && room.music.onSourcesUpdated) {

        room.music.onSourcesUpdated()
      }

      if (room.video && room.video.onSourcesUpdated) {

        room.video.onSourcesUpdated()
      }
    }
  }

  function onCoreClientsUpdated () {

    processRoomsInfoIntercom()

    $rootScope.$applyAsync()
  }

  function onSchedulesOrderUpdated (event, data) {

    RoomsHelper.forEachRoom(_roomOnSchedulesOrderUpdated)

    $rootScope.$applyAsync()

    /**
     * @private
     * @param {BasRoom} room
     */
    function _roomOnSchedulesOrderUpdated (room) {

      if (room.scheduler && room.scheduler.onOrderUpdated) {

        room.scheduler.onOrderUpdated(event, data)
      }
    }
  }

  function onTimersOrderUpdated (event, data) {

    RoomsHelper.forEachRoom(_roomOnTimersOrderUpdated)

    $rootScope.$applyAsync()

    /**
     * @private
     * @param {BasRoom} room
     */
    function _roomOnTimersOrderUpdated (room) {

      if (room.timers && room.timers.onOrderUpdated) {

        room.timers.onOrderUpdated(event, data)
      }
    }
  }

  /**
   * @param {Object} event
   * @param {Object<string, string[]>} data
   */
  function onScenesOrderUpdated (event, data) {

    RoomsHelper.forEachRoom(_roomOnScenesOrderUpdated)

    $rootScope.$applyAsync()

    /**
     * @private
     * @param {BasRoom} room
     */
    function _roomOnScenesOrderUpdated (room) {

      if (room.scenes && room.scenes.onOrderUpdated) {

        room.scenes.onOrderUpdated(event, data)
      }
    }
  }

  /**
   * @param {Object} event
   * @param {Object<string, string[]>} data
   */
  function onScenesFavouritesUpdated (event, data) {

    RoomsHelper.forEachRoom(_roomOnScenesFavouritesUpdated)

    $rootScope.$applyAsync()

    /**
     * @private
     * @param {BasRoom} room
     */
    function _roomOnScenesFavouritesUpdated (room) {

      if (room.scenes && room.scenes.onFavouritesUpdated) {

        room.scenes.onFavouritesUpdated(event, data)
      }
    }
  }

  /**
   * @param {Object} _event
   * @param {string} sourceUuid
   */
  function onSourceAvailableChanged (_event, sourceUuid) {

    var doSync

    doSync = false

    RoomsHelper.forEachRoom(_roomOnSourceAvailableChanged)

    /**
     * @private
     * @param {BasRoom} room
     */
    function _roomOnSourceAvailableChanged (room) {

      if (
        room.music &&
        room.music.syncAvailability &&
        room.music.basSource &&
        room.music.basSource.uuid === sourceUuid
      ) {

        room.music.syncAvailability()

        doSync = true
      }
    }

    if (doSync) $rootScope.$applyAsync()
  }

  /**
   * @param {Object} _event
   * @param {string} sourceUuid
   */
  function onCompatibleRoomsChanged (_event, sourceUuid) {

    var doSync

    doSync = false

    RoomsHelper.forEachRoom(_roomOnSourceCompatibleRoomsChanged)

    /**
     * @private
     * @param {BasRoom} room
     */
    function _roomOnSourceCompatibleRoomsChanged (room) {

      if (
        room.music &&
        room.music.syncCanGroup &&
        room.music.basSource &&
        room.music.basSource.uuid === sourceUuid
      ) {

        if (room.music.syncCanGroup()) doSync = true
      }
    }

    if (doSync) $rootScope.$applyAsync()
  }

  function onListeningRoomsChanged (_event) {

    processRoomsInfoMusic()
  }

  /**
   * Update the translations
   */
  function updateTranslations () {

    updateRoomTranslations()
    updateCollectionTranslations()

    // Make sure rooms are translated before the filters
    translateFilters()
  }

  function translateFilters () {

    translateRoomFilters(rooms.home)
    translateRoomFilters(rooms.music)
    translateRoomFilters(rooms.video)
    translateRoomFilters(rooms.thermostats)
    translateRoomFilters(rooms.openCloseDevices)
    translateRoomFilters(rooms.intercom)
  }

  function translateRoomFilters (comp) {

    var keys, i, length, filterId, filter, room

    keys = Object.keys(comp.filters)
    length = keys.length
    for (i = 0; i < length; i++) {

      filterId = keys[i]
      filter = comp.filters[filterId]

      if (filter) {

        switch (filterId) {
          case RoomFilter.SRV_ALL:
            filter.setName(BasUtilities.translate(
              'all'
            ))
            break
          case RoomFilter.SRV_GRP:
            filter.setName(BasUtilities.translate(
              'groups'
            ))
            break
          case RoomFilter.SRV_AV_GRP:
            filter.setName(BasUtilities.translate(
              'groups'
            ))
            break
          case RoomFilter.SRV_ACT:

            if (comp === rooms.openCloseDevices) {

              filter.setName(BasUtilities.translate(
                'open_state'
              ))

            } else {

              filter.setName(BasUtilities.translate(
                'active'
              ))
            }

            break
          default:

            if (filter.property === RoomFilter.P_BUILDING ||
              filter.property === RoomFilter.P_FLOOR) {

              if (BasUtil.isNEString(filter.roomId)) {

                room = rooms.rooms[filter.roomId]

                if (room instanceof BasRoom) {

                  filter.setName(room.uiTitle)

                } else {

                  filter.setName(filter.roomId)
                }

              } else {

                Logger.warn(
                  serviceName +
                  ' - Translate Filters' +
                  ' - Unable to translate',
                  filter
                )

                filter.setName('')
              }
            }
        }
      }
    }
  }

  function updateRoomTranslations () {

    RoomsHelper.forEachRoom(_roomUpdateTranslation)
  }

  /**
   * @private
   * @param {BasRoom} room
   */
  function _roomUpdateTranslation (room) {

    room.updateTranslation()
  }

  function updateThermostatUnit () {

    RoomsHelper.forEachRoom(_updateRoomUnits)
  }

  /**
   * @private
   * @param {BasRoom} room
   */
  function _updateRoomUnits (room) {

    room.updateTemperatureUnit()
  }

  function updateCollectionTranslations () {

    _componentUpdateTranslation(rooms.home)
    _componentUpdateTranslation(rooms.music)
    _componentUpdateTranslation(rooms.video)
    _componentUpdateTranslation(rooms.thermostats)
    _componentUpdateTranslation(rooms.openCloseDevices)
    _componentUpdateTranslation(rooms.intercom)
  }

  /**
   * @private
   * @param {BasRoomsComponent} comp
   */
  function _componentUpdateTranslation (comp) {

    if (comp && comp.updateTranslation) {

      comp.updateTranslation()
    }
  }

  /**
   * Retrieve Zones from API and create info objects
   */
  function syncApi () {

    var _core, _roomsUpdatedType

    _roomsUpdatedType = ''

    if (CurrentBasCore.hasCore()) {

      _core = currentBasCoreState.core.core

      if (_core.supportsSystemProperties) {

        if (_core.system && !_core.system.propertiesDirty) {

          if (
            CurrentBasCore.hasAVPartialSupport() ||
            CurrentBasCore.hasAVFullSupport()
          ) {

            if (
              _core.roomsReceived &&
              _core.avSourcesReceived
            ) {

              syncAVGroups()
            }
          }

          if (CurrentBasCore.hasAVFullSupport()) {

            // Only AV Support, don't need to wait for music config

            if (_core.roomsReceived) {

              syncRooms()
              _roomsUpdatedType = BAS_ROOMS.P_ROOMS

            } else {

              // Wait for rooms
            }

          } else {

            // No AV Support or full av support: wait for both music config
            //  and rooms

            if (_core.musicConfigReceived && _core.roomsReceived) {

              syncMusicZones()
              syncRooms()
              _roomsUpdatedType = BAS_ROOMS.P_ROOMS

            } else {

              // Wait for both music config and rooms
            }
          }
        } else {

          // Wait for system properties
        }

      } else {

        if (_core.musicConfigReceived) {

          syncMusicZones()
          _roomsUpdatedType = BAS_ROOMS.P_ZONES
        }
      }
    }

    if (_roomsUpdatedType) {

      _postRoomSync()
      _generateSortedCollections()
      processRoomsInfo()

      $rootScope.$emit(BAS_ROOMS.EVT_ROOMS_UPDATED, _roomsUpdatedType)
    }
  }

  /**
   * Sync rooms
   */
  function syncRooms () {

    var _areas, keys, i, length, uuid, home, parseResult

    rooms.root = ''

    // -----
    // Rooms
    // -----

    _areas = currentBasCoreState.core.core.areas

    keys = Object.keys(_areas)
    length = keys.length
    for (i = 0; i < length; i++) {

      uuid = keys[i]

      parseResult = BasRoom.parseOrDestroy(
        rooms.rooms[uuid],
        {
          room: _areas[uuid]
        }
      )

      rooms.rooms[uuid] = parseResult.basRoom

      // Store home reference
      if (parseResult.basRoom.isHome()) rooms.root = uuid
    }

    // ----
    // Home
    // ----

    if (BasUtil.isNEString(rooms.root)) {

      home = rooms.rooms[rooms.root]
      if (home && home.uiRoom) home.uiRoom.sync()
    }

    updateAVGroups()
  }

  /**
   * Synchronises Music zones with the core
   */
  function syncMusicZones () {

    var zones, keys, i, length, uuid, parseResult

    // Don't parse legacy zones when we have asano av support
    if (!CurrentBasCore.hasAVFullSupport()) {

      // -----
      // Zones
      // -----

      zones = currentBasCoreState.core.core.zones

      if (BasUtil.isObject(zones)) {

        // Iterate zones
        keys = Object.keys(zones)
        length = keys.length
        for (i = 0; i < length; i++) {

          uuid = keys[i]

          parseResult = BasRoom.parseOrDestroy(
            rooms.rooms[uuid],
            {
              zone: zones[uuid]
            }
          )

          rooms.rooms[uuid] = parseResult.basRoom
        }
      }

      // ------
      // Groups
      // ------

      zones = currentBasCoreState.core.core.groups

      if (BasUtil.isObject(zones)) {

        // Iterate groups
        keys = Object.keys(zones)
        length = keys.length
        for (i = 0; i < length; i++) {

          uuid = keys[i]

          parseResult = BasRoom.parseOrDestroy(
            rooms.rooms[uuid],
            {
              zone: zones[uuid]
            }
          )

          rooms.rooms[uuid] = parseResult.basRoom
        }
      }
    }
  }

  function syncAVGroups () {

    var i, length, keys, uuid, keepUuids, parseResult

    keepUuids = []

    keys = Object.keys(BAS_SOURCES.SOURCES.audioSources)
    length = keys.length
    for (i = 0; i < length; i++) {

      uuid = keys[i]

      parseResult = BasRoom.parseOrDestroy(
        rooms.rooms[uuid],
        {
          sourceUuid: uuid
        }
      )

      rooms.rooms[uuid] = parseResult.basRoom

      keepUuids.push(uuid)
    }
  }

  /**
   * @private
   */
  function _postRoomSync () {

    var _core, _avSourcesReceived, _doCleanup

    _avSourcesReceived = true
    _doCleanup = false

    if (CurrentBasCore.hasCore()) {

      _core = currentBasCoreState.core.core

      if (_core.supportsSystemProperties) {

        if (
          _core.system &&
          !_core.system.propertiesDirty
        ) {

          if (
            CurrentBasCore.hasAVPartialSupport() ||
            CurrentBasCore.hasAVFullSupport()
          ) {

            _avSourcesReceived = _core.avSourcesReceived

            if (CurrentBasCore.hasAVFullSupport()) {

              if (_core.roomsReceived) {

                _doCleanup = true
              }
            } else {

              if (
                _core.roomsReceived &&
                _core.musicConfigReceived
              ) {
                _doCleanup = true
              }
            }

          } else {

            if (
              _core.musicConfigReceived &&
              _core.roomsReceived
            ) {
              // Done
              _doCleanup = true
            }
          }
        }

      } else {

        if (_core.musicConfigReceived) {

          // Done
          _doCleanup = true
        }
      }
    }

    if (_doCleanup) {

      _cleanUpRooms(
        _avSourcesReceived ? undefined : BAS_ROOM.VAR_AV_GROUP
      )
    }
  }

  /**
   * Cleans out unused BasRoom instances
   *
   * @private
   * @param {string} [excludeVariant]
   */
  function _cleanUpRooms (excludeVariant) {

    var _core, _uuidsToKeep, keys, length, i, uuid, room, _hasExclusion

    if (!CurrentBasCore.hasCore()) return

    _hasExclusion = BasUtil.isNEString(excludeVariant)

    _core = currentBasCoreState.core.core

    _uuidsToKeep = Object.keys(_core.areas)
    _uuidsToKeep = _uuidsToKeep.concat(Object.keys(_core.zones))
    _uuidsToKeep = _uuidsToKeep.concat(Object.keys(_core.groups))
    _uuidsToKeep = _uuidsToKeep.concat(Object.keys(_core.audioSources))

    keys = Object.keys(rooms.rooms)
    length = keys.length
    for (i = 0; i < length; i++) {

      uuid = keys[i]

      if (uuid) {

        room = rooms.rooms[uuid]

        if (room && room.destroy) {

          if (_hasExclusion) {

            if (room.variant === excludeVariant) {

              // Do nothing

            } else {

              if (_uuidsToKeep.indexOf(uuid) < 0) {

                room.destroy()
                rooms.rooms[uuid] = null
              }
            }

          } else {

            if (_uuidsToKeep.indexOf(uuid) < 0) {

              room.destroy()
              rooms.rooms[uuid] = null
            }
          }
        }
      }
    }
  }

  function updateAVGroups () {

    RoomsHelper.forEachRoom(_onRoomDoParseSource, BAS_ROOM.VAR_AV_GROUP)
  }

  /**
   * @private
   * @param {BasRoom} room
   */
  function _onRoomDoParseSource (room) {

    room.parseSource()
  }

  function resetRoomsInfo () {

    rooms.info = {}
    rooms.info.hasRoomMenu = false
    rooms.info.hasHomeRooms = false
    rooms.info.hasMusicRooms = false
    rooms.info.hasThermostatRooms = false
    rooms.info.hasIntercomRooms = false
    rooms.info.hasEnergyRooms = false
  }

  function syncRoomsInfo () {

    var checks = 0

    resetRoomsInfo()

    if (!CurrentBasCore.isAudioOnly()) {

      if (rooms.home &&
        rooms.home.uiAllCollection &&
        rooms.home.uiAllCollection.length > 0) {

        checks++
        rooms.info.hasHomeRooms = true
      }

      if (RoomsHelper.hasMusic()) {

        checks++
        rooms.info.hasMusicRooms = true
      }
      if (RoomsHelper.hasVideo()) {

        checks++
        rooms.info.hasVideoRooms = true
      }
      if (RoomsHelper.hasThermostat()) {

        checks++
        rooms.info.hasThermostatRooms = true
      }
      if (RoomsHelper.hasIntercomRooms(
        [currentProfile.currentProfile.receiveIntercomRoom]
      )) {
        rooms.info.hasIntercomRooms = true
        if (BasIntercomHelper.shouldShowIntercom()) checks++
      }
      if (RoomsHelper.hasEnergyRooms()) {
        // Not visible in room menu
        rooms.info.hasEnergyRooms = true
      }

      rooms.info.hasRoomMenu = checks > 1

    } else {

      rooms.info.hasMusicRooms = true
    }
  }

  function hasOpenCloseDevicesWithFeedback () {

    var keys, length, i, uuid, room

    keys = Object.keys(rooms.rooms)
    length = keys.length
    for (i = 0; i < length; i++) {

      uuid = keys[i]

      if (uuid) {

        room = rooms.rooms[uuid]

        if (
          room &&
          room.openCloseDevices &&
          room.openCloseDevices.hasOpenCloseDevicesWithFeedback &&
          room.openCloseDevices.hasOpenCloseDevicesWithFeedback()
        ) {

          return true
        }
      }
    }

    return false
  }

  /**
   * Checks whether there are multiple buildings for the BasRoomsComponents
   */
  function checkMultipleBuildings () {

    var i, uuid, room, buildingId
    var buildings, musicBuildings, videoBuildings, thermostatBuildings
    var openCloseBuildings, intercomBuildings

    // All rooms

    buildings = []
    musicBuildings = []
    videoBuildings = []
    thermostatBuildings = []
    openCloseBuildings = []
    intercomBuildings = []

    rooms.home.hasMultipleBuildings = false
    rooms.music.hasMultipleBuildings = false
    rooms.video.hasMultipleBuildings = false
    rooms.thermostats.hasMultipleBuildings = false
    rooms.openCloseDevices.hasMultipleBuildings = false
    rooms.intercom.hasMultipleBuildings = false

    for (i = 0; i < _sortedRoomsLength; i++) {

      uuid = _sortedRooms[i]
      room = rooms.rooms[uuid]

      if (room && room.isRoom) {

        buildingId = room.getBuildingId()

        if (BasUtil.isNEString(buildingId)) {

          if (
            BasRoom.checkUiFunction(room) &&
            buildings.indexOf(buildingId) === -1
          ) {

            buildings.push(buildingId)

            if (buildings.length >= 2) {

              rooms.home.hasMultipleBuildings = true
            }
          }

          if (BasRoom.checkMusic(room) &&
            musicBuildings.indexOf(buildingId) === -1) {

            musicBuildings.push(buildingId)

            if (musicBuildings.length >= 2) {

              rooms.music.hasMultipleBuildings = true

              if (thermostatBuildings.length >= 2 &&
                openCloseBuildings.length >= 2 &&
                intercomBuildings.length >= 2 &&
                videoBuildings.length >= 2) {

                break
              }
            }
          }

          if (BasRoom.checkVideo(room) &&
            videoBuildings.indexOf(buildingId) === -1) {

            videoBuildings.push(buildingId)

            if (videoBuildings.length >= 2) {

              rooms.video.hasMultipleBuildings = true

              if (thermostatBuildings.length >= 2 &&
                openCloseBuildings.length >= 2 &&
                intercomBuildings.length >= 2 &&
                musicBuildings.length >= 2) {

                break
              }
            }
          }

          if (BasRoom.checkThermostats(room) &&
            thermostatBuildings.indexOf(buildingId) === -1) {

            thermostatBuildings.push(buildingId)

            if (thermostatBuildings.length >= 2) {

              rooms.thermostats.hasMultipleBuildings = true

              if (musicBuildings.length >= 2 &&
                openCloseBuildings.length >= 2 &&
                intercomBuildings.length >= 2 &&
                videoBuildings.length >= 2) {

                break
              }
            }
          }

          if (RoomsHelper.roomHasFunctionOpenClose(room) &&
            openCloseBuildings.indexOf(buildingId) === -1) {

            openCloseBuildings.push(buildingId)

            if (openCloseBuildings.length >= 2) {

              rooms.openCloseDevices.hasMultipleBuildings = true

              if (musicBuildings.length >= 2 &&
                thermostatBuildings.length >= 2 &&
                intercomBuildings.length >= 2 &&
                videoBuildings.length >= 2) {

                break
              }
            }
          }

          if (
            BasRoom.checkIntercomFunction(room) &&
            intercomBuildings.indexOf(buildingId) === -1
          ) {

            intercomBuildings.push(buildingId)

            if (intercomBuildings.length >= 2) {

              rooms.intercom.hasMultipleBuildings = true

              if (musicBuildings.length >= 2 &&
                thermostatBuildings.length >= 2 &&
                openCloseBuildings.length >= 2 &&
                videoBuildings.length >= 2) {

                break
              }
            }
          }
        }
      }
    }
  }

  /**
   * @param {BasRoom} room
   * @param {string[]} tags
   */
  function addRoomTags (room, tags) {

    var i, length, tag, idx

    length = room.tags.length
    for (i = 0; i < length; i++) {

      tag = room.tags[i]
      idx = tags.indexOf(tag)

      if (idx === -1) tags.push(tag)
    }
  }

  function _generateSortedCollections () {

    var keys, length, i, uuid, room

    _resetSortedCollections()

    keys = Object.keys(rooms.rooms)
    length = keys.length
    for (i = 0; i < length; i++) {

      uuid = keys[i]
      room = rooms.rooms[uuid]

      if (room) {

        switch (room.variant) {
          case BAS_ROOM.VAR_AREA:
            // No need for a sorted areas collection
            break
          case BAS_ROOM.VAR_ROOM:
            _sortedRooms.push(uuid)
            break
          case BAS_ROOM.VAR_MUSIC_GROUP:
            _sortedRoomsMusicGroups.push(uuid)
            break
          case BAS_ROOM.VAR_AV_GROUP:
            _sortedRoomsAVGroups.push(uuid)
            break
        }
      }
    }

    _sortedRooms.sort(RoomsHelper.basRoomUuidCompare)
    _sortedRoomsMusicGroups.sort(RoomsHelper.basRoomUuidCompare)
    _sortedRoomsAVGroups.sort(RoomsHelper.basRoomUuidCompare)

    _sortedRoomsLength = _sortedRooms.length
    _sortedRoomsMusicGroupsLength = _sortedRoomsMusicGroups.length
    _sortedRoomsAVGroupsLength = _sortedRoomsAVGroups.length
  }

  /**
   * Process Room info for extracting the buildings (with floors) and tags
   */
  function processRoomsInfo () {

    // -------
    // Filters
    // -------

    checkMultipleBuildings()

    generateFilters(rooms.home)
    generateFilters(rooms.thermostats)
    generateFilters(rooms.music)
    generateFilters(rooms.video)
    generateFilters(rooms.openCloseDevices)
    generateFilters(rooms.intercom)

    translateFilters()

    // --
    // UI
    // --

    rooms.home.uiAllCollection =
      generateUiArray(rooms.home, RoomFilter.SRV_ALL)
    rooms.music.uiAllCollection =
      generateUiArray(rooms.music, RoomFilter.SRV_ALL)
    rooms.video.uiAllCollection =
      generateUiArray(rooms.video, RoomFilter.SRV_ALL)
    rooms.thermostats.uiAllCollection =
      generateUiArray(rooms.thermostats, RoomFilter.SRV_ALL)
    rooms.openCloseDevices.uiAllCollection =
      generateUiArray(rooms.openCloseDevices, RoomFilter.SRV_ALL)
    rooms.intercom.uiAllCollection =
      generateUiArray(rooms.intercom, RoomFilter.SRV_ALL)

    processCurrentFilter(rooms.home)
    processCurrentFilter(rooms.thermostats)
    processCurrentFilter(rooms.music)
    processCurrentFilter(rooms.video)
    processCurrentFilter(rooms.openCloseDevices)
    processCurrentFilter(rooms.intercom)

    syncRoomsInfo()
  }

  function processRoomsInfoMusic () {

    generateFilters(rooms.music)

    translateRoomFilters(rooms.music)

    rooms.music.uiAllCollection =
      generateUiArray(rooms.music, RoomFilter.SRV_ALL)

    processCurrentFilter(rooms.music)

    rooms.info.hasMusicRooms = RoomsHelper.hasMusic()

    if (!rooms.info.hasRoomMenu) {

      rooms.info.hasRoomMenu = rooms.info.hasMusicRooms
    }
  }

  function processRoomsInfoIntercom () {

    generateFilters(rooms.intercom)

    translateRoomFilters(rooms.intercom)

    rooms.intercom.uiAllCollection =
      generateUiArray(rooms.intercom, RoomFilter.SRV_ALL)

    processCurrentFilter(rooms.intercom)

    rooms.info.hasIntercomRooms = RoomsHelper.hasIntercomRooms(
      [currentProfile.currentProfile.receiveIntercomRoom]
    )

    if (!rooms.info.hasRoomMenu) {

      rooms.info.hasRoomMenu = BasIntercomHelper.shouldShowIntercom()
    }
  }

  /**
   * @private
   * @param {BasRoomBuilding} roomBuilding
   * @param {string} floorId
   * @param {number} order
   */
  function _checkFloor (
    roomBuilding,
    floorId,
    order
  ) {
    if (BasUtil.isNEString(floorId) && !roomBuilding.floors[floorId]) {

      roomBuilding.floors[floorId] = new BasRoomFloor(floorId, order)
    }
  }

  /**
   * Generate all possible filters for the specified BasRoomsComponent
   *
   * @param {BasRoomsComponent} comp
   */
  function generateFilters (comp) {

    var i, uuid, room, keysJ, j, lengthJ, tag
    var keysK, k, lengthK, roomFloor, floor
    var filter
    var uiBuildingFilters, uiFloorFilters
    var buildingId, floorId
    var buildings = {}
    var tags = []
    var currentFilter
    var tempUiFilters

    /**
     * @type {BasRoomBuilding}
     */
    var roomBuilding

    currentFilter = comp.filters[comp.uiCurrentFilter]

    // Clear BasRoomsComponents
    comp.clearRoomVariables()

    comp.uiCurrentFilterBuilding = ''

    for (i = 0; i < _sortedRoomsLength; i++) {

      uuid = _sortedRooms[i]
      room = rooms.rooms[uuid]

      if (room && room.isRoom) {

        buildingId = room.getBuildingId()
        floorId = room.getFloorId()

        if (BasUtil.isNEString(buildingId)) {

          switch (comp) {

            case rooms.home:
              if (BasRoom.checkUiFunction(room)) {

                if (!buildings[buildingId]) {

                  buildings[buildingId] = new BasRoomBuilding(room, i)
                }

                _checkFloor(buildings[buildingId], floorId, i)
              }
              break

            case rooms.thermostats:
              if (RoomsHelper.roomHasFunctionThermostat(room)) {

                if (!buildings[buildingId]) {

                  buildings[buildingId] = new BasRoomBuilding(room, i)
                }

                _checkFloor(buildings[buildingId], floorId, i)
              }
              break

            case rooms.music:
              if (BasRoom.checkMusic(room)) {

                if (!buildings[buildingId]) {

                  buildings[buildingId] = new BasRoomBuilding(room, i)
                }

                _checkFloor(buildings[buildingId], floorId, i)
              }
              break

            case rooms.video:
              if (BasRoom.checkVideo(room)) {

                if (!buildings[buildingId]) {

                  buildings[buildingId] = new BasRoomBuilding(room, i)
                }

                _checkFloor(buildings[buildingId], floorId, i)
              }
              break

            case rooms.openCloseDevices:
              if (RoomsHelper.roomHasFunctionOpenClose(room)) {

                if (!buildings[buildingId]) {

                  buildings[buildingId] = new BasRoomBuilding(room, i)
                }

                _checkFloor(buildings[buildingId], floorId, i)
              }
              break

            case rooms.intercom:
              if (BasRoom.checkIntercomFunction(room)) {
                if (!buildings[buildingId]) {

                  buildings[buildingId] = new BasRoomBuilding(room, i)
                }

                _checkFloor(buildings[buildingId], floorId, i)
              }
              break
          }
        }

        switch (comp) {

          case rooms.home:

            if (BasRoom.checkUiFunction(room)) {

              addRoomTags(room, tags)
            }

            break
          case rooms.thermostats:

            if (BasRoom.checkThermostats(room)) {

              addRoomTags(room, tags)
            }

            break
          case rooms.music:

            if (BasRoom.checkMusic(room)) {

              addRoomTags(room, tags)
            }

            break
          case rooms.video:

            if (BasRoom.checkVideo(room)) {

              addRoomTags(room, tags)
            }

            break
          case rooms.openCloseDevices:

            if (RoomsHelper.roomHasFunctionOpenClose(room)) {

              addRoomTags(room, tags)
            }

            break
          case rooms.intercom:

            if (BasRoom.checkIntercomFunction(room)) {

              addRoomTags(room, tags)
            }

            break
        }
      }
    }

    if (_sortedRoomsLength > 0) {

      tempUiFilters = []

      // Add default filters
      if (comp === rooms.music) {

        // All filter
        filter = new RoomFilter(
          RoomFilter.P_SERVICE,
          RoomFilter.SRV_ALL
        )
        filter.setOrder(0)
        filter.addFunction(BasRoom.checkMusic)

        tempUiFilters.push(RoomFilter.SRV_ALL)
        comp.filters[RoomFilter.SRV_ALL] = filter

        if (_sortedRoomsMusicGroupsLength > 0) {

          // Group filter
          filter = new RoomFilter(
            RoomFilter.P_SERVICE,
            RoomFilter.SRV_GRP
          )
          filter.setOrder(1)
          filter.addFunction(BasRoom.checkGroup)

          tempUiFilters.push(RoomFilter.SRV_GRP)
          comp.filters[RoomFilter.SRV_GRP] = filter
        }

        if (_sortedRoomsAVGroupsLength > 0) {

          // Source group filter
          filter = new RoomFilter(
            RoomFilter.P_SERVICE,
            RoomFilter.SRV_AV_GRP
          )
          filter.setOrder(2)
          filter.addFunction(BasRoom.checkSourceGroup)

          tempUiFilters.push(RoomFilter.SRV_AV_GRP)
          comp.filters[RoomFilter.SRV_AV_GRP] = filter
        }

        // Active filter
        filter = new RoomFilter(
          RoomFilter.P_SERVICE,
          RoomFilter.SRV_ACT
        )
        filter.setOrder(3)
        filter.addFunction(BasRoom.checkMusicActive)

        tempUiFilters.push(RoomFilter.SRV_ACT)
        comp.filters[RoomFilter.SRV_ACT] = filter

      } else if (comp === rooms.video) {

        // All filter
        filter = new RoomFilter(
          RoomFilter.P_SERVICE,
          RoomFilter.SRV_ALL
        )
        filter.setOrder(0)
        filter.addFunction(BasRoom.checkVideo)

        tempUiFilters.push(RoomFilter.SRV_ALL)
        comp.filters[RoomFilter.SRV_ALL] = filter

        // Active filter
        filter = new RoomFilter(
          RoomFilter.P_SERVICE,
          RoomFilter.SRV_ACT
        )
        filter.setOrder(3)
        filter.addFunction(BasRoom.checkVideoActive)

        tempUiFilters.push(RoomFilter.SRV_ACT)
        comp.filters[RoomFilter.SRV_ACT] = filter

      } else if (comp === rooms.thermostats) {

        filter = new RoomFilter(
          RoomFilter.P_SERVICE,
          RoomFilter.SRV_ALL
        )
        filter.setOrder(0)
        filter.addFunction(RoomsHelper.roomHasFunctionThermostat)

        tempUiFilters.push(RoomFilter.SRV_ALL)
        comp.filters[RoomFilter.SRV_ALL] = filter

      } else if (comp === rooms.openCloseDevices) {

        filter = new RoomFilter(
          RoomFilter.P_SERVICE,
          RoomFilter.SRV_ALL
        )
        filter.setOrder(0)
        filter.addFunction(BasRoom.checkOpenClose)

        tempUiFilters.push(RoomFilter.SRV_ALL)
        comp.filters[RoomFilter.SRV_ALL] = filter

        // Active filter
        if (hasOpenCloseDevicesWithFeedback()) {

          filter = new RoomFilter(
            RoomFilter.P_SERVICE,
            RoomFilter.SRV_ACT
          )
          filter.setOrder(1)
          filter.addFunction(BasRoom.checkOpenCloseWithFeedback)

          tempUiFilters.push(RoomFilter.SRV_ACT)
          comp.filters[RoomFilter.SRV_ACT] = filter
        }

      } else if (comp === rooms.intercom) {

        filter = new RoomFilter(
          RoomFilter.P_SERVICE,
          RoomFilter.SRV_ALL
        )
        filter.setOrder(0)
        filter.addFunction(BasRoom.checkIntercomFunction)

        tempUiFilters.push(RoomFilter.SRV_ALL)
        comp.filters[RoomFilter.SRV_ALL] = filter

      } else {

        filter = new RoomFilter(
          RoomFilter.P_SERVICE,
          RoomFilter.SRV_ALL
        )
        filter.setOrder(0)
        filter.addFunction(BasRoom.checkUiFunction)

        tempUiFilters.push(RoomFilter.SRV_ALL)
        comp.filters[RoomFilter.SRV_ALL] = filter

        // Active filter
        filter = new RoomFilter(
          RoomFilter.P_SERVICE,
          RoomFilter.SRV_ACT
        )
        filter.setOrder(2)
        filter.addFunction(BasRoom.checkActivities)

        tempUiFilters.push(RoomFilter.SRV_ACT)
        comp.filters[RoomFilter.SRV_ACT] = filter
      }

      comp.uiFilters.push(tempUiFilters)

      // Create building filters
      uiBuildingFilters = []
      uiFloorFilters = []
      keysJ = Object.keys(buildings)
      lengthJ = keysJ.length
      for (j = 0; j < lengthJ; j++) {

        buildingId = keysJ[j]

        // Get BasRoomBuilding reference
        roomBuilding = buildings[buildingId]

        // Create filter
        filter = new RoomFilter(
          RoomFilter.P_BUILDING,
          roomBuilding.id
        )
        filter.setOrder(FILTER_ORDER_BUILDINGS + roomBuilding.order)

        // Set specific filter options
        switch (comp) {
          case rooms.home:
            filter.addFunction(BasRoom.checkUiBuilding)
            break
          case rooms.thermostats:
            filter.addFunction(BasRoom.checkThermostatBuilding)
            break
          case rooms.music:
            filter.addFunction(BasRoom.checkMusicBuilding)
            break
          case rooms.video:
            filter.addFunction(BasRoom.checkVideoBuilding)
            break
          case rooms.openCloseDevices:
            filter.addFunction(BasRoom.checkOpenCloseBuilding)
            break
          case rooms.intercom:
            filter.addFunction(BasRoom.checkIntercomBuilding)
            break
        }

        // Set building filter
        comp.filters[filter.id] = filter

        keysK = Object.keys(roomBuilding.floors)

        if (comp.hasMultipleBuildings) {

          // Add "building" filter
          uiBuildingFilters.push(filter.id)

          // Only continue if current filter is this building or part of this
          //  building

          if (currentFilter) {

            if (
              currentFilter.property === RoomFilter.P_FLOOR &&
              keysK.indexOf(currentFilter.value) > -1
            ) {

              // Current filter is floor of this building, floor filters should
              //  be shown. Also save building id for visualisation in UI.
              comp.uiCurrentFilterBuilding = buildingId

            } else if (
              currentFilter.property === RoomFilter.P_BUILDING &&
              currentFilter.value === buildingId
            ) {

              // Current filter is this building, floor filters for this
              //  building should be shown.

            } else {

              // Do not show any floor filters for this building
              continue
            }
          } else {

            // No filter, don't show floor filters
            continue
          }
        }

        // Create floor filters
        lengthK = keysK.length
        for (k = 0; k < lengthK; k++) {

          // Get BasRoomFloor instance
          roomFloor = roomBuilding.floors[keysK[k]]

          // Get Floor instance
          // This could be undefined for < 2.0.0 servers
          floor = rooms.rooms[keysK[k]]

          // Create floor filter
          filter = new RoomFilter(
            RoomFilter.P_FLOOR,
            roomFloor.id
          )
          filter.nameId = floor ? floor.nameId : roomFloor.id
          filter.setOrder(FILTER_ORDER_FLOORS + roomFloor.order)

          // Set specific filter options
          switch (comp) {
            case rooms.home:
              filter.addFunction(BasRoom.checkUiFunction)
              filter.addFunction(RoomsHelper.checkFloors)
              break
            case rooms.thermostats:
              filter.addFunction(RoomsHelper.roomHasFunctionThermostat)
              filter.addFunction(RoomsHelper.checkFloors)
              break
            case rooms.music:
              filter.addFunction(BasRoom.checkMusic)
              filter.addFunction(RoomsHelper.checkFloors)
              break
            case rooms.video:
              filter.addFunction(BasRoom.checkVideo)
              filter.addFunction(RoomsHelper.checkFloors)
              break
            case rooms.openCloseDevices:
              filter.addFunction(RoomsHelper.roomHasFunctionOpenClose)
              filter.addFunction(RoomsHelper.checkFloors)
              break
            case rooms.intercom:
              filter.addFunction(BasRoom.checkIntercomFunction)
              filter.addFunction(RoomsHelper.checkFloors)
              break
          }

          // Set floor filter
          comp.filters[filter.id] = filter

          // Check for duplicate floor names
          if (indexOfUiFilter(comp, uiFloorFilters, filter.nameId) === -1) {

            // Add "floor" filter
            uiFloorFilters.push(filter.id)
          }
        }
      }

      // Add filters to component
      if (uiBuildingFilters.length) comp.uiFilters.push(uiBuildingFilters)
      if (uiFloorFilters.length) comp.uiFilters.push(uiFloorFilters)

      // Create tag filters
      tempUiFilters = []
      keysJ = Object.keys(tags)
      lengthJ = keysJ.length
      for (j = 0; j < lengthJ; j++) {

        // Get tag name
        tag = tags[keysJ[j]]

        // Create tag filter
        filter = new RoomFilter(
          RoomFilter.P_TAG,
          tag
        )
        filter.setOrder(FILTER_ORDER_TAGS + j)

        // Set specific filter options
        switch (comp) {
          case rooms.home:
            filter.addFunction(BasRoom.checkUiTag)
            break
          case rooms.thermostats:
            filter.addFunction(BasRoom.checkThermostatTag)
            break
          case rooms.music:
            filter.addFunction(BasRoom.checkMusicTag)
            break
          case rooms.video:
            filter.addFunction(BasRoom.checkVideoTag)
            break
          case rooms.openCloseDevices:
            filter.addFunction(BasRoom.checkOpenCloseTag)
            break
          case rooms.intercom:
            filter.addFunction(BasRoom.checkIntercomTag)
            break
        }

        // Set tag filter
        comp.filters[filter.id] = filter
        tempUiFilters.push(filter.id)
      }

      if (tempUiFilters.length) comp.uiFilters.push(tempUiFilters)

      // Sort the filters (by order)
      sortRoomFilters(comp)
    }
  }

  /**
   * Sets the uiCollections based on the current filter
   *
   * @param {BasRoomsComponent} comp
   */
  function processCurrentFilter (comp) {

    var i, length

    // Check if current filter still exists
    // After an event it could happen that
    // the current filter isn't valid anymore
    length = comp.uiFilters.length
    for (i = 0; i < length; i++) {

      if (comp.uiFilters[i].indexOf(comp.uiCurrentFilter) !== -1) {

        comp.setUiCurrentCollections(
          generateUiArray(comp, comp.uiCurrentFilter)
        )
        return
      }
    }

    // Reset filter to all
    comp.uiCurrentFilter = RoomFilter.SRV_ALL
    comp.setUiCurrentCollections(comp.uiAllCollection)
  }

  /**
   * Sets a new filter as current filter
   *
   * @param {string} compKey
   * @param {string} filterId
   */
  function selectCurrentFilter (compKey, filterId) {

    var comp = rooms[compKey]

    if (comp instanceof BasRoomsComponent && comp.filters[filterId]) {

      comp.uiCurrentFilter = filterId

      if (comp.hasMultipleBuildings) {
        generateFilters(comp)
        translateFilters(rooms.music)
      }
      processCurrentFilter(comp)
    }
  }

  /**
   * Event listener for rooms
   *
   * @param _event
   * @param {string} _id Room ID
   */
  function onRoomSourceChanged (_event, _id) {

    updateUiActive(rooms.music)
    updateAVGroups()

    SourcesHelper.forEachSource(_roomOnSourceChanged)

    // Process music filter
    generateFilters(rooms.music)
    translateFilters(rooms.music)
    rooms.music.uiAllCollection =
      generateUiArray(rooms.music, RoomFilter.SRV_ALL)
    processCurrentFilter(rooms.music)

    /**
     * @private
     * @param {BasSource} basSource
     */
    function _roomOnSourceChanged (basSource) {

      if (basSource) basSource.onRoomSourceChanged(_id)
    }
  }

  /**
   * Event listener for rooms
   *
   * @param _event
   * @param {string} _id Room ID
   */
  function onRoomOpenCloseChanged (_event, _id) {

    generateFilters(rooms.openCloseDevices)
    updateUiActive(rooms.openCloseDevices)
    translateFilters()
    processCurrentFilter(rooms.openCloseDevices)
  }

  /**
   * Event listener for room activity
   *
   * @param _event
   * @param {string} _id Room ID
   */
  function onRoomActivityChanged (_event, _id) {

    updateUiActive(rooms.home)
  }

  /**
   * Event listener for room images
   *
   * @param _event
   * @param {string} _id Room ID
   */
  function onRoomImageUpdated (_event, _id) {

    $rootScope.$applyAsync()
  }

  /**
   * @param {Object} _event
   * @param {BasRoom} basRoom
   */
  function onRoomScenesUpdated (_event, basRoom) {

    var home

    home = RoomsHelper.getHome()

    if (
      home &&
      basRoom &&
      home.id === basRoom.id &&
      home.scheduler &&
      home.scheduler.handleScenesUpdated
    ) {

      home.scheduler.handleScenesUpdated()
    }
  }

  function onGenericDevicesInitialized () {

    // Generic devices in SOME room updated, let home scenes know
    Object.values(
      BAS_ROOMS.ROOMS.rooms[BAS_ROOMS.ROOMS.root]
        ?.scenes?.scenes ?? {}
    )
      .filter(scene => scene.syncSteps())
  }

  /**
   * Event listener for time format
   *
   * @param _event
   */
  function onTimeFormatChanged (_event) {

    RoomsHelper.forEachRoom(_onRoomDoTimeFormatChanged)
  }

  /**
   * @private
   * @param {BasRoom} room
   */
  function _onRoomDoTimeFormatChanged (room) {

    room.onTimeFormatChanged()
  }

  /**
   * @param {BasRoomsComponent} comp
   */
  function updateUiActive (comp) {

    if (comp.uiCurrentFilter === RoomFilter.SRV_ACT) {

      comp.setUiCurrentCollections(
        generateUiArray(comp, RoomFilter.SRV_ACT)
      )
    }
  }

  /**
   * Create UI array based on the given filter (otherwise "All")
   *
   * @param {BasRoomsComponent} comp
   * @param {(RoomFilter|string)} filter
   * @returns {BasCollection[]}
   */
  function generateUiArray (
    comp,
    filter
  ) {
    var result, _filter, floors

    result = []

    if (_sortedRoomsLength <= 0) return result

    if (filter) {

      _filter = filter instanceof RoomFilter
        ? filter
        : comp.filters[filter]
    }

    // Check filter
    if (BasUtil.isObject(_filter)) {

      switch (_filter.property) {
        case RoomFilter.P_FLOOR:

          floors = getFloorIds(comp, _filter)

          createCustomUiArray(
            _filter.checkFunction.bind(_filter),
            floors
          )

          break

        case RoomFilter.P_SERVICE:

          if (_filter.value === RoomFilter.SRV_GRP) {

            createGroupUiArray(
              _filter.checkFunction.bind(_filter)
            )

          } else if (_filter.value === RoomFilter.SRV_AV_GRP) {

            createSourceGroupUiArray(
              _filter.checkFunction.bind(_filter)
            )

          } else {

            createCustomUiArray(
              _filter.checkFunction.bind(_filter)
            )
          }

          break

        default:

          createCustomUiArray(
            _filter.checkFunction.bind(_filter),
            _filter.value
          )

          break
      }
    }

    return result

    /**
     * @param {Function} checkFunction
     * @param [param]
     */
    function createCustomUiArray (checkFunction, param) {

      forEachSortedRoom(_onRoom)

      /**
       * @private
       * @param {BasRoom} room
       */
      function _onRoom (room) {

        var collection

        if (checkFunction(room, param)) {

          collection = getResultCollection(
            RoomsHelper.getFloorBuildingId(room),
            getCollectionKey(comp, room)
          )

          // Add room to collection
          collection.items.push(room.id)
        }
      }
    }

    /**
     * Fill result with all the groups
     *
     * @param {Function} checkFunction
     */
    function createGroupUiArray (checkFunction) {

      var collection

      collection = new BasCollection()
      collection.setId('groups')
      collection.setTitleTranslationId('groups')

      forEachSortedMusicGroup(_onRoomMusicGroup)

      if (indexOfCollection(result, collection) === -1 &&
        isValidCollection(collection)) {

        // Add groups collection
        result.push(collection)
      }

      /**
       * @private
       * @param {BasRoom} room
       */
      function _onRoomMusicGroup (room) {

        // Only groups
        if (checkFunction(room)) {

          // Add room to collection
          collection.items.push(room.id)
        }
      }
    }

    /**
     * Fill result with all the AV groups
     *
     * @param {Function} checkFunction
     */
    function createSourceGroupUiArray (checkFunction) {

      var collection

      collection = new BasCollection()
      collection.setId('groups')
      collection.setTitleTranslationId('groups')

      forEachSortedAVGroup(_onRoomAVGroup)

      if (indexOfCollection(result, collection) === -1 &&
        isValidCollection(collection)) {

        // Add groups collection
        result.push(collection)
      }

      /**
       * @private
       * @param {BasRoom} room
       */
      function _onRoomAVGroup (room) {

        // Only groups
        if (checkFunction(room)) {

          // Add room to collection
          collection.items.push(room.id)
        }
      }
    }

    /**
     * Will return a new or existing collection on which to append rooms.
     * The collection will be part of the result.
     *
     * @param {string} collectionId
     * @param {string} collectionName
     * @returns {BasCollection}
     */
    function getResultCollection (collectionId, collectionName) {

      var idx, collection

      idx = indexOfCollection(result, collectionId)

      if (idx === -1) {

        collection = new BasCollection()
        collection.setId(collectionId)
        collection.setTitleTranslationId(collectionName)

        result.push(collection)

        return collection
      }

      return result[idx]
    }
  }

  /**
   * Creates a collection title based on Room (floor and building)
   * and hasMultipleBuildings
   *
   * @param {BasRoomsComponent} comp
   * @param {BasRoom} room
   * @returns {string}
   */
  function getCollectionKey (comp, room) {

    var building, floor

    building = RoomsHelper.getRoomBuildingPartKey(room)
    floor = RoomsHelper.getRoomFloorPartKey(room)

    if (comp.hasMultipleBuildings) {

      if (building && floor) {

        return '$$' + building + BAS_ROOM.UI_SEPARATOR + floor

      } else if (floor) {

        return '$$' + floor

      } else if (building) {

        return '$$' + building

      } else {

        return 'zones'
      }
    }

    if (floor) return '$$' + floor

    return 'zones'
  }

  /**
   * Checks whether the collection has a valid name and at least 1 room.
   *
   * @param {BasCollection} collection
   * @returns {boolean}
   */
  function isValidCollection (collection) {
    return (
      collection &&
      collection.basTitle &&
      BasUtil.isNEString(collection.basTitle.value) &&
      Array.isArray(collection.items) &&
      collection.items.length > 0
    )
  }

  /**
   * Checks whether the given collection or collection name
   * is part of collections.
   * This checks only on name.
   *
   * @param {BasCollection[]} collections
   * @param {(BasCollection|string)} collection
   * @returns {number}
   */
  function indexOfCollection (
    collections,
    collection
  ) {
    var collectionId, length, i, col

    if (!Array.isArray(collections)) return -1

    if (BasUtil.isString(collection)) {

      collectionId = collection

    } else if (BasUtil.isObject(collection)) {

      collectionId = collection.id
    }

    if (!BasUtil.isNEString(collectionId)) return -1

    length = collections.length
    for (i = 0; i < length; i++) {

      col = collections[i]
      if (col && col.id === collectionId) return i
    }

    return -1
  }

  /**
   * Searches for a filter with the same nameId
   *
   * @param {BasRoomsComponent} comp
   * @param {string[]} filters
   * @param {string} nameId
   * @returns {number}
   */
  function indexOfUiFilter (
    comp,
    filters,
    nameId
  ) {
    var i, length, filter

    if (
      comp &&
      Array.isArray(comp.filters) &&
      Array.isArray(filters) &&
      BasUtil.isNEString(nameId)
    ) {
      length = filters.length
      for (i = 0; i < length; i++) {

        filter = comp.filters[filters[i]]
        if (filter && filter.nameId === nameId) return i
      }
    }

    return -1
  }

  /**
   * Get all floors with specified nameId
   *
   * @param {BasRoomsComponent} comp
   * @param {RoomFilter} filter
   * @returns {string[]}
   */
  function getFloorIds (comp, filter) {

    var keys, i, length, _filter, floorIds

    floorIds = []

    keys = Object.keys(comp.filters)
    length = keys.length
    for (i = 0; i < length; i++) {

      _filter = comp.filters[keys[i]]

      if (_filter) {

        if (_filter.nameId === filter.nameId) {

          floorIds.push(_filter.value)
        }
      }
    }

    return floorIds
  }

  function sortRoomFilters (comp) {

    var i, length

    length = comp.uiFilters.length
    for (i = 0; i < length; i++) {

      comp.uiFilters[i].sort(compareRoomFilterUuids)
    }

    /**
     * @param {string} a
     * @param {string} b
     * @returns {number}
     */
    function compareRoomFilterUuids (a, b) {

      var aRFilter = comp.filters[a]
      var bRFilter = comp.filters[b]

      if (aRFilter && bRFilter) return aRFilter.order - bRFilter.order

      return 0
    }
  }

  /**
   * @param {CBasForEachRoom} callback
   */
  function forEachSortedRoom (callback) {

    _forEachRoomByUuid(_sortedRooms, _sortedRoomsLength, callback)
  }

  /**
   * @param {CBasForEachRoom} callback
   */
  function forEachSortedMusicGroup (callback) {

    _forEachRoomByUuid(
      _sortedRoomsMusicGroups,
      _sortedRoomsMusicGroupsLength,
      callback
    )
  }

  /**
   * @param {CBasForEachRoom} callback
   */
  function forEachSortedAVGroup (callback) {

    _forEachRoomByUuid(
      _sortedRoomsAVGroups,
      _sortedRoomsAVGroupsLength,
      callback
    )
  }

  /**
   * @param {string[]} collection
   * @param {number} length
   * @param {CBasForEachRoom} callback
   */
  function _forEachRoomByUuid (
    collection,
    length,
    callback
  ) {
    var i, uuid, room

    for (i = 0; i < length; i++) {

      uuid = collection[i]

      if (uuid) {

        room = rooms.rooms[uuid]

        if (room && room.isRoom) callback(room, i, collection)
      }
    }
  }

  function _resetSortedCollections () {
    _sortedRooms = []
    _sortedRoomsLength = 0
    _sortedRoomsMusicGroups = []
    _sortedRoomsMusicGroupsLength = 0
    _sortedRoomsAVGroups = []
    _sortedRoomsAVGroupsLength = 0
  }

  /**
   * Suspends all Room instance
   */
  function suspendRooms () {

    RoomsHelper.forEachRoom(_onRoomDoSuspend)
  }

  /**
   * @private
   * @param {BasRoom} room
   */
  function _onRoomDoSuspend (room) {

    room.suspend()
  }

  /**
   * Clear room info objects
   */
  function clearRooms () {

    RoomsHelper.forEachRoom(_onRoomDoDestroy)

    // Clear rooms
    rooms.rooms = {}
    rooms.root = ''

    rooms.home.clearRoomVariables()
    rooms.music.clearRoomVariables()
    rooms.video.clearRoomVariables()
    rooms.thermostats.clearRoomVariables()
    rooms.openCloseDevices.clearRoomVariables()
    rooms.intercom.clearRoomVariables()
    rooms.openCloseDevices.clearRoomVariables()

    $rootScope.$emit(BAS_ROOMS.EVT_ROOMS_CLEARED)
  }

  function _onRoomDoDestroy (room) {

    room.destroy()
  }
}
