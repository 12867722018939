'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('loginLiveCtrl', [
    '$scope',
    'STATES',
    'BAS_LIVE_ACCOUNT',
    'BAS_MODAL',
    'BasState',
    'BasLiveAccount',
    'BasLiveHelper',
    'BasModal',
    loginLiveCtrl
  ])

/**
 * @param $scope
 * @param {STATES} STATES
 * @param {BAS_LIVE_ACCOUNT} BAS_LIVE_ACCOUNT
 * @param {BAS_MODAL} BAS_MODAL
 * @param {BasState} BasState
 * @param {BasLiveAccount} BasLiveAccount
 * @param {BasLiveTokenRevoked} BasLiveHelper
 * @param {BasModal} BasModal
 */
function loginLiveCtrl (
  $scope,
  STATES,
  BAS_LIVE_ACCOUNT,
  BAS_MODAL,
  BasState,
  BasLiveAccount,
  BasLiveHelper,
  BasModal
) {
  var login = this

  /**
   * @type {TBasLiveAccountState}
   */
  login.basLiveAccountState = BasLiveAccount.get()

  /**
   * @type {string}
   */
  login.email = ''

  /**
   * @type {string}
   */
  login.password = ''

  login.performLogin = performLogin
  login.forgotPassword = forgotPassword

  function performLogin (form) {

    var email, password

    if (form.$valid) {

      email = _getEmail()
      password = _getPassword()

      BasLiveAccount.login(
        email,
        password,
        _onLogin
      )

    } else {

      BasModal.show(BAS_MODAL.T_ERROR_INVALID_USER)
    }

    function _onLogin (error) {

      if (error) {

        if (error.code === BAS_LIVE_ACCOUNT.A_ERR_USER_NOT_CONFIRMED) {

          BasModal.show(BAS_MODAL.T_ERROR_USER_NOT_CONFIRMED)
            .then(_onModalUserNotConfirmed)

        } else {

          BasModal.show(BAS_MODAL.T_WRONG_PASSWORD)
        }

      } else {

        BasLiveHelper.onLiveLoginSuccess()
      }
    }

    function _onModalUserNotConfirmed (modal) {

      modal.closed.then(_onModalUserNotConfirmedClosed)
    }

    function _onModalUserNotConfirmedClosed (result) {

      if (result === BAS_MODAL.C_YES) {

        if ($scope.live) {

          $scope.live.toVerify(email, password)
        }

        BasState.go(STATES.CONNECT_LIVE_VERIFY)
      }
    }
  }

  function forgotPassword () {

    var _email, _cognitoUser

    _email = _getEmail()

    if (_email) {

      _cognitoUser = BasLiveAccount.getCognitoUser(_email)
    }

    if (_cognitoUser) {

      BasLiveAccount.forgotPassword(_cognitoUser, _onForgotPassword)

    } else {

      BasModal.show(BAS_MODAL.T_ERROR_INVALID_USER)
    }

    function _onForgotPassword (error) {

      if (error) {

        if (error.code === BAS_LIVE_ACCOUNT.A_ERR_USER_NOT_FOUND) {

          BasModal.show(BAS_MODAL.T_ERROR_UNKNOWN_USER)

        } else {

          BasModal.show(BAS_MODAL.T_ERROR)
        }

      } else {

        if ($scope.live) {

          $scope.live.toVerify(_cognitoUser)
        }

        BasState.go(STATES.CONNECT_LIVE_FORGOT)
      }
    }
  }

  /**
   * @private
   * @returns {string}
   */
  function _getEmail () {

    return BasUtil.isNEString(login.email)
      ? login.email.toLowerCase()
      : ''
  }

  /**
   * @private
   * @returns {string}
   */
  function _getPassword () {

    return BasUtil.isNEString(login.password)
      ? login.password
      : ''
  }
}
