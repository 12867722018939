{
  "12_hr": "12-uurs klok",
  "24_hr": "24-uurs klok",
  "24_hr_time": "24-uurs klok",
  "about": "Over",
  "accepted": "Geaccepteerd",
  "account": "Account",
  "account_exists": "Account bestaat",
  "account_exists_l1": "Deze account bestaat al.",
  "account_exists_l2": "Gelieve een ander e-mailadres te kiezen of login met dit e-mailadres.",
  "account_not_confirmed": "Account niet bevestigd",
  "account_not_confirmed_l1": "Deze account is nog niet bevestigd.",
  "account_not_confirmed_l2": "Gelieve uw email te controleren voor een bevestigingscode.",
  "account_not_confirmed_l3": "Deze account bevestigen?",
  "act_in": "In",
  "activate": "Activeer",
  "active": "Actief",
  "act_out": "Uit",
  "act_push": "DUW",
  "adaptive_brightness": "Adaptieve helderheid",
  "add": "Toevoegen",
  "add_favourite": "Favoriet toevoegen",
  "add_group": "Groep toevoegen",
  "add_home": "Nieuw huis",
  "address": "Adres",
  "add_to_fav": "Toevoegen aan favorieten",
  "add_to_playlist": "Toevoegen aan afspeellijst",
  "add_to_queue": "Toevoegen aan wachtrij",
  "air_conditioning": "Airconditioning",
  "air_pressure": "Luchtdruk",
  "alarm_at": "Wekker op",
  "alarm_missing_txt": "Enkele verplichte velden ontbreken",
  "alarm_not_selected_l1": "Er is geen wekker geselecteerd.",
  "alarm_not_selected_l2": "Kies een wekker.",
  "alarms": "Wekkers",
  "album": "Album",
  "albums": "Albums",
  "alert": "Waarschuwing",
  "all": "Alles",
  "all_off": "Alles Uit",
  "all_off_txt_l1": "Alle kamers worden uitgeschakeld.",
  "all_off_txt_l2": "Bent u zeker dat u wil doorgaan?",
  "all_on": "Alles Aan",
  "almost_done": "Bijna klaar",
  "almost_done_txt_l1": "Er zijn nog geen favoriete afspeellijsten of radiostations toegevoegd.",
  "almost_done_txt_l2": "Voeg eerst enkele favorieten toe.",
  "already_linked": "Gekoppeld met Basalte account",
  "already_linked_to_me": "Gekoppeld aan uw Basalte account",
  "already_verified": "Reeds bevestigd",
  "alt_power": "Aan/Uit",
  "alt_user": "Gebruiker",
  "application": "Applicatie",
  "application_version": "Applicatie versie",
  "app_update_required": "App update nodig",
  "app_update_required_android_l2": "gelieve de app te updaten in de Play Store.",
  "app_update_required_ios_l2": "gelieve de app te updaten in de App Store.",
  "app_update_required_l2": "gelieve de app te updaten in de store.",
  "artist": "Artiest",
  "artists": "Artiesten",
  "attention": "Opgelet!",
  "audio_unavailable": "Audio systeem is niet beschikbaar",
  "audio_zones": "Audiozones",
  "author": "Auteur",
  "back": "terug",
  "basalte_account": "Basalte account",
  "basalte_info_l1": "Met een Basalte account kan u uw huis koppelen en bedienen buiten het thuisnetwerk.",
  "basalte_link_server_l1": "Koppel dit huis aan uw Basalte account?",
  "basalte_link_server_l2": "Dit maakt het mogelijk om uw huis buiten uw thuisnetwerk te bedienen.",
  "basalte_unlink_server_l1": "Dit huis loskoppelen van uw Basalte account?",
  "basalte_unlink_server_l2": "U zal niet langer dit huis kunnen bedienen buiten uw thuisnetwerk.",
  "bass": "Bass",
  "BE": "België",
  "beta": "beta",
  "bluetooth": "Bluetooth",
  "bluetooth_pairing_txt": "Het is nu mogelijk om je apparaat te verbinden via bluetooth.",
  "brightness": "Helderheid",
  "browse": "Bladeren",
  "browse_deezer": "Deezer favorieten",
  "browse_library": "Muziekbibliotheek",
  "browser_app_update_required_l2": "gelieve de pagina opnieuw te laden en/of de server te updaten.",
  "browse_stations": "Radiozenders",
  "buffering": "bufferen",
  "building_building": "Gebouw",
  "building_garage": "Garage",
  "building_main_house": "Hoofdwoning",
  "building_pool_house": "Poolhouse",
  "building_stables": "Stallen",
  "call_accepted": "Oproep geaccepteerd",
  "call_declined": "Oproep geweigerd",
  "call_history": "Oproepgeschiedenis",
  "camera_loading_error": "Onmogelijk om de camera te laden",
  "cameras": "Cameras",
  "cancel": "Annuleren",
  "categories": "Categorieën",
  "category": "Categorie",
  "celsius": "Celsius",
  "change_account": "Account wijzigen",
  "change_home": "Kies huis",
  "change_image": "Wijzig afbeelding",
  "change_password": "Wachtwoord veranderen",
  "change_password_l1": "U staat op het punt uw wachtwoord te veranderen.",
  "change_user": "Kies profiel",
  "channel_number": "Kanaal nummer",
  "charts": "Hitlijsten",
  "check_for_updates": "Zoek naar updates",
  "checking": "controleren",
  "checking_for_updates": "Zoeken naar updates...",
  "choose_favourite": "Wil je een favoriet kiezen?",
  "choose_home": "Kies huis",
  "choose_music": "Wil je muziek kiezen?",
  "choose_preset": "Kies een sfeertype",
  "choose_source": "Wil je een bron kiezen?",
  "clear": "Wissen",
  "cleared": "Gewist",
  "clear_queue_txt_l1": "De wachtrij zal gewist worden.",
  "clear_queue_txt_l2": "Bent u zeker dat u wil doorgaan?",
  "clear_schedules": "Bent u zeker dat u uw planner wilt wissen?",
  "clear_selection": "Selectie wissen",
  "clear_selection_txt_l1": "De selectie wordt gewist.",
  "clear_selection_txt_l2": "Bent u zeker dat u wil doorgaan?",
  "close": "Sluiten",
  "closed": "Gesloten",
  "cloud_environment": "Cloud omgeving",
  "code": "Code",
  "collapse": "Dichtklappen",
  "color": "Kleur",
  "color_temperature": "Kleurtemperatuur",
  "color_theme": "Kleurthema",
  "color_white": "Wit",
  "comfort": "Comfort",
  "configure": "Configureren",
  "confirmation_code_sent": "Bevestigingscode verstuurd",
  "confirmation_code_sent_l1": "Een bevestigingscode is verstuurd naar het volgende adres:",
  "confirm_continue": "Bent u zeker dat u wil doorgaan?",
  "confirm_overwrite": "Bent u zeker dat u de bestaande gegevens wilt overschrijven?",
  "connect": "Verbinden",
  "connected": "Verbonden",
  "connecting": "Verbinden",
  "connecting_no_active_servers": "Server is niet online.",
  "connecting_no_integrator_access": "Configureren vanop afstand is uitgeschakeld op deze server.",
  "connecting_not_authorized": "Niet geautoriseerd om verbinding te maken met deze server.",
  "connecting_small": "verbinden",
  "connecting_stop_and_retry_text": "Verbinding met server mislukt. Een nieuwe poging start over 10 seconden.",
  "connecting_stop_text": "Verbinding met server mislukt.",
  "connecting_text": "Verbinden...",
  "connecting_text_small": "verbinden...",
  "connection_lost": "Geen verbinding",
  "connection_lost_text": "Opnieuw met de server verbinden",
  "connection_unable": "Onmogelijk om te verbinden met deze server.",
  "connect_offline_server_l1": "Dit huis is gekoppeld, maar lijkt offline te zijn.",
  "connect_offline_server_l2": "Toch proberen te verbinden?",
  "connect_server": "Verbind met server",
  "consumption": "Verbruik",
  "contact_support": "Contacteer support",
  "continue_anyway": "Toch doorgaan",
  "continue_without_basalte_account": "Ga verder zonder Basalte account",
  "cover_art_background": "Album achtergrond",
  "current_password": "Huidig wachtwoord",
  "current_temperature": "Huidig",
  "custom": "Aangepast",
  "date": "Datum",
  "day": "Dag",
  "day_friday": "Vrijdag",
  "day_monday": "Maandag",
  "day_saturday": "Zaterdag",
  "day_short2_friday": "Vr",
  "day_short2_monday": "Ma",
  "day_short2_saturday": "Za",
  "day_short2_sunday": "Zo",
  "day_short2_thursday": "Do",
  "day_short2_tuesday": "Di",
  "day_short2_wednesday": "Woe",
  "day_sunday": "Zondag",
  "day_thursday": "Donderdag",
  "day_tuesday": "Dinsdag",
  "day_wednesday": "Woensdag",
  "debug": "Debug",
  "debug_code": "Code",
  "debug_remote": "Op afstand debuggen",
  "debug_remote_enable": "Debuggen op afstand inschakelen",
  "debug_remote_exit_info": "Om debuggen op afstand uit te schakelen, start u de app volledig opnieuw op.",
  "declined": "Geweigerd",
  "deezer": "Deezer",
  "deezer_disconnect_txt": "Deze Deezer account wordt afgemeld voor deze speler.",
  "deezer_favourite": "Deezer favoriet",
  "deezer_flow": "Flow",
  "deezer_flow_description": "Uw persoonlijke afspeellijst",
  "deezer_flow_full": "Deezer Flow",
  "deezer_no_link": "Er is nog geen Deezer account gekoppeld",
  "deezer_oauth_error": "Deezer verificatiefout",
  "default_rooms": "Standaardkamers",
  "default_rooms_hint": "Kies minstens één kamer als standaardkamer voor een goede werking van in-home streaming.",
  "default_rooms_info_l1": "Opstartkamers voor audio streams van derde partijen (bv. Spotify Connect).",
  "default_rooms_info_l2": "Wanneer er geen kamers gekoppeld zijn aan de stream, worden de geselecteerde kamers actief.",
  "default_rooms_spotify_hint": "Kies minstens één kamer als standaardkamer voor een goede werking van Spotify Connect.",
  "default_rooms_spotify_warning_l1": "Geef alle streams tenminste één standaardkamer",
  "default_rooms_spotify_warning_l2": "om de configuratie te voltooien.",
  "default_rooms_spotify_warning_title": "Spotify Connect wordt nu ondersteund",
  "default_view": "Standaard pagina",
  "delay": "Vertraging",
  "delay_seconds_b": "Vertraging (seconden)",
  "delete": "Wissen",
  "delete_account": "Account verwijderen",
  "delete_account_l1": "U staat op het punt om uw account permanent te verwijderen.",
  "demo_enter": "Demo mode",
  "demo_functionality_warning_txt": "Deze functionaliteit wordt niet ondersteund in demo mode.",
  "demo_location": "Ghent",
  "demo_server_name": "Demo Huis",
  "demo_title": "Demo",
  "demo_warning_txt": "Demo mode wordt gestart.",
  "detail": "detail",
  "device": "Apparaat",
  "devices": "Apparaten",
  "device_settings": "Apparaat instellingen",
  "device_volume": "Apparaatvolume",
  "dhcp": "DHCP",
  "direction_east": "Oosten",
  "direction_east-northeast": "Oost-Noordoost",
  "direction_east-southeast": "Oost-Zuidoost",
  "direction_north": "Noorden",
  "direction_northeast": "Noordoost",
  "direction_north-northeast": "Noord-noordoost",
  "direction_north-northwest": "Noord-Noordwesten",
  "direction_northwest": "Noordwesten",
  "direction_short_east": "O",
  "direction_short_east-northeast": "ONO",
  "direction_short_east-southeast": "OZO",
  "direction_short_north": "N",
  "direction_short_northeast": "NO",
  "direction_short_north-northeast": "NNO",
  "direction_short_north-northwest": "NW",
  "direction_short_northwest": "NO",
  "direction_short_south": "Z",
  "direction_short_southeast": "ZO",
  "direction_short_south-southeast": "ZZO",
  "direction_short_south-southwest": "ZZW",
  "direction_short_southwest": "ZW",
  "direction_short_west": "W",
  "direction_short_west-northwest": "WNW",
  "direction_short_west-southwest": "WZW",
  "direction_south": "Z",
  "direction_southeast": "ZO",
  "direction_south-southeast": "ZZO",
  "direction_south-southwest": "ZZW",
  "direction_southwest": "ZW",
  "direction_west": "W",
  "direction_west-northwest": "WNW",
  "direction_west-southwest": "WZW",
  "disband_group": "Groep opbreken",
  "dns": "DNS",
  "domain": "Domein",
  "done": "Klaar",
  "do_not_disturb": "Niet storen",
  "do_not_disturb_off_l1": "Alle geluidsmeldingen zijn momenteel ingeschakeld.",
  "do_not_disturb_on_l1": "Alle geluidsmeldingen zijn momenteel uitgeschakeld.",
  "do_not_show_again": "Toon niet meer",
  "door_phone": "Deurtelefoon",
  "doors": "Deuren",
  "down": "Omlaag",
  "downloading": "downloaden",
  "downloading_updates": "Updates downloaden",
  "do_you_want_to_continue": "Wilt u doorgaan?",
  "drag_and_drop_images": "Selecteer of sleep een afbeelding",
  "drag_and_drop_lights": "Sleep de lichten hierheen",
  "drag_to_reorder": "Sleep om te herordenen",
  "dsp": "DSP",
  "dsp_configuration": "DSP-configuratie",
  "dsp_profile_club": "Club",
  "dsp_profile_custom": "Aangepast",
  "dsp_profile_flat": "Plat",
  "dsp_profile_jazz": "Jazz",
  "dsp_profile_live": "Live",
  "dsp_profile_pop": "Pop",
  "dsp_profile_rock": "Rock",
  "dsp_shortcut_l1": "U staat op het punt de dsp pagina te openen.",
  "echo_cancellation": "Echo-annulering",
  "echo_limit": "Echo limiet",
  "edit": "Wijzig",
  "edit_dns": "DNS aanpassen",
  "edit_gateway": "Gateway aanpassen",
  "edit_ip_address": "IP adres aanpassen",
  "edit_subnet_mask": "Subnet mask aanpassen",
  "ellie_room": "Ellie kamer",
  "ellie_system_update": "Ellie systeemupdate",
  "ellie_system_update_l1": "Ellie moet opnieuw opgestart worden om de systeemupdate te installeren.",
  "ellie_update": "Ellie update",
  "email": "E-mailadres",
  "empty": "leeg",
  "empty_queue": "Wachtrij is leeg",
  "empty_queue_txt_l1": "Er zitten geen nummers in de wachtrij.",
  "empty_queue_txt_l2": "Voeg er eerst enkele toe.",
  "empty_song": "Speler is gestopt",
  "empty_song_txt_l1": "Dit is het einde van de wachtrij.",
  "empty_song_txt_l2": "Klik op \"play\" om opnieuw te starten.",
  "empty_src": "Er is geen bron geselecteerd",
  "empty_src_txt_l1": "Er is geen bron geselecteerd.",
  "empty_src_txt_l2": "Kies eerst een bron.",
  "empty_zone": "Geen kamer geselecteerd",
  "empty_zone_txt_l1": "Er werd geen kamer geselecteerd.",
  "empty_zone_txt_l2": "Kies eerst een kamer.",
  "energy": "Energie",
  "energy_meter": "Energiemeter",
  "eq": "EQ",
  "error": "Fout",
  "external": "Extern",
  "extra_permissions_required": "Sommige acties vereisen extra permissies.",
  "ext_src": "Externe bron",
  "fahrenheit": "Fahrenheit",
  "failed": "Gefaald",
  "fan": "Ventilator",
  "fan_auto": "Auto",
  "fan_high": "Hoog",
  "fan_low": "Laag",
  "fan_medium": "Midden",
  "fan_off": "Uit",
  "favourite": "Favouriet",
  "favourites": "Favorieten",
  "favourite_scenes_full": "Favoriete scenes vol",
  "favourite_scenes_full_sub": "Je kan maximum 4 scenes als favoriet hebben in een kamer.",
  "favourite_songs": "Favoriete liedjes",
  "featured_playlists": "Aanbevolen afspeellijsten",
  "floor_attic": "Zolder",
  "floor_basement": "Kelder",
  "floor_floor": "Verdieping",
  "floor_garden": "Tuin",
  "floor_heating": "Vloerverwarming",
  "floor_loft": "Loft",
  "floor_lower": "Onderverdiep",
  "floor_lvl_0": "Gelijkvloers",
  "floor_lvl_1": "Eerste verdiep",
  "floor_lvl_2": "Tweede verdiep",
  "floor_lvl_3": "Derde verdiep",
  "floor_lvl_4": "Vierde verdiep",
  "floor_lvl_5": "Vijfde verdiep",
  "floor_lvl_6": "Zesde verdiep",
  "floor_lvl_7": "Zevende verdiep",
  "floor_lvl_8": "Achtste verdiep",
  "floor_lvl_9": "Negende verdiep",
  "floor_lvl_10": "Tiende verdiep",
  "floor_lvl_11": "Elfde verdiep",
  "floor_lvl_12": "Twaalde verdiep",
  "floor_lvl_13": "Dertiende verdiep",
  "floor_lvl_14": "Veertiende verdiep",
  "floor_lvl_15": "Vijftiende verdiep",
  "floor_lvl_16": "Zestiende verdiep",
  "floor_lvl_17": "Zeventiende verdiep",
  "floor_lvl_18": "Achttiende verdiep",
  "floor_lvl_19": "Negentiende verdiep",
  "floor_lvl_20": "Twintigste verdiep",
  "floor_upper": "Bovenverdiep",
  "forgot_password": "Wachtwoord vergeten",
  "frequency": "Frequentie",
  "full_screen": "Volledig scherm",
  "functions": "Functies",
  "gas": "Gas",
  "gateway": "Gateway",
  "general": "Algemeen",
  "generic_devices": "Apparaten",
  "generic_devices_fire": "Vuur",
  "generic_devices_garden": "Tuin",
  "generic_devices_socket": "Stopcontact",
  "genre": "Genre",
  "genre_moods": "Genre en stemmingen",
  "genres": "Genres",
  "go_to_login_l1": "Log in of registreer met Basalte account.",
  "go_to_manage_l1": "Beheer uw Basalte account.",
  "group": "Groep",
  "groups": "Groepen",
  "home": "Home",
  "humidity": "Vochtigheid",
  "hz": "Hz",
  "images": "Afbeeldingen",
  "image_smaller_than_5mb": "Afbeeldingen moeten kleiner zijn dan 5MB",
  "in": "In",
  "incorrect_album": "Het album werd niet gevonden",
  "incorrect_artist": "Deze artiest werd niet gevonden",
  "install": "Installeer",
  "installing": "installeren",
  "install_update": "Installeer update",
  "install_updates": "Installeer updates",
  "integrator": "Integrator",
  "integrator_access": "Configuratie op afstand",
  "integrator_access_allow": "Configureren vanop afstand toestaan",
  "integrator_access_ask": "Vraag aan de eigenaar om configureren vanop afstand in te schakelen.",
  "integrator_access_disabled": "Configuratie vanop afstand is uitgeschakeld",
  "integrator_access_explanation": "Dit laat uw domotica installateur toe om op afstand een (volledig geëncrypteerde en veilige) verbinding met uw systeem te maken, om:",
  "integrator_access_o1_program_basalte": "uw Basalte systeem te kunnen programmeren en bedienen",
  "integrator_access_o2_program_knx": "uw KNX toestellen te kunnen programmeren",
  "integrator_access_o3_access_network": "uw volledig IP netwerk te kunnen benaderen",
  "integrator_access_privacy": "Voor uw privacy raden we aan dit enkel tijdelijk toe te staan.",
  "intercom": "Intercom",
  "internal": "Intern",
  "invalid_address": "Ongeldig adres",
  "invalid_code": "Onjuiste code",
  "invalid_code_l1": "De bevestingscode is niet juist.",
  "invalid_code_l2": "Zorg ervoor dat de code correct is of vraag een nieuwe aan.",
  "invalid_input": "Ongeldige invoer",
  "invalid_user": "Verkeerde gebruikersnaam",
  "invalid_user_l1": "Zorg ervoor dat het e-mailadres correct is.",
  "ios_plist_string_NSCameraUsageDescription": "Camera kan gebruikt worden om foto's te maken van kamers",
  "ios_plist_string_NSLocalNetworkUsageDescription": "Lokaal netwerk kan worden gebruikt om servers, IP-camera's, ... te vinden.",
  "ios_plist_string_NSPhotoLibraryAddUsageDescription": "Fotobibliotheek kan worden gebruikt om foto's van kamers op te slaan",
  "ios_plist_string_NSPhotoLibraryUsageDescription": "Fotobibliotheek kan worden gebruikt om foto's van kamers te selecteren",
  "ip": "IP",
  "ip_address": "IP adres",
  "join": "+ Toevoegen",
  "join_alt": "+ Toevoegen",
  "keep_awake": "iPad ingeschakeld houden",
  "keep_awake_device": "Toestel ingeschakeld houden",
  "kelvin": "Kelvin",
  "Key": "nl_NL",
  "knx_presets": "KNX presets",
  "knx_presets_info": "Pas bestaande KNX presets aan om iets anders af te spelen.",
  "language": "Taal",
  "language_system": "Systeemtaal",
  "last-7-days": "Laatste 7 dagen",
  "last_hour": "Voorbije uur",
  "last_room": "Laatste kamer",
  "last_view": "Laatste pagina",
  "learn_more": "Meer informatie",
  "leave_group": "Groep verlaten",
  "left": "Links",
  "lena_system_update": "Lena systeemupdate",
  "lena_system_update_l1": "Lena moet opnieuw opgestart worden om de systeemupdate te installeren.",
  "library": "Bibliotheek",
  "library_empty": "Lege bibliotheek",
  "library_scanning": "Bibliotheek scannen",
  "light_accent_light": "Accentlicht",
  "light_art_light": "Kunstlicht",
  "light_cabinet": "Kast",
  "light_ceiling_mounted_fixture": "Plafondlamp",
  "light_chandelier": "Luchter",
  "light_desk": "Bureau",
  "light_down": "Downlight",
  "light_floor_lamp": "Vloerlamp",
  "light_lampshade": "Lampekap",
  "light_led": "LED lamp",
  "light_led_strip": "LED strip",
  "light_mirror": "Spiegel",
  "light_mood_light": "Sfeerlicht",
  "light_pendant": "Hanglamp",
  "light_reading_light": "Leeslamp",
  "lights": "Lichten",
  "lights_off_all": "Schakel alle lichten uit",
  "light_spot": "Spot",
  "light_table_lamp": "Tafellamp",
  "light_track": "Track",
  "light_via": "Via",
  "light_wall_mounted_fixture": "Wandlamp",
  "link": "Koppel",
  "link_deezer_info_l1": "Koppel een Deezer account aan een stream.",
  "link_deezer_info_l2": "Dit laat toe om de Deezer-bibliotheek te doorbladeren en af te spelen.",
  "linked_homes": "Gekoppelde huizen",
  "linked_projects": "Gelinkte projecten",
  "linked_with": "Gekoppeld met",
  "link_home": "Open de Basalte app op een lokaal apparaat en koppel uw huis in de settings.",
  "link_server": "Koppel met Basalte account",
  "link_server_full_sub": "Koppel dit project met volgende Basalte account?",
  "link_spotify_info_l1": "Koppel een Spotify account aan een stream.",
  "link_spotify_info_l2": "Dit laat toe om de Spotify-bibliotheek te doorbladeren en af te spelen.",
  "link_tidal_info_l1": "Koppel een TIDAL account aan een stream.",
  "link_tidal_info_l2": "Dit laat toe om de TIDAL-bibliotheek te doorbladeren en af te spelen.",
  "lisa_order_hint": "Configureer pagina's en snelkoppelingen.",
  "lisa_system_update": "Lisa systeemupdate",
  "lisa_system_update_l1": "Lisa moet opnieuw opgestart worden om de systeemupdate te installeren.",
  "listen_in": "Meeluisteren",
  "liters": "Liter",
  "live_adjustments": "Live aanpassingen",
  "loading": "Laden…",
  "local": "lokaal",
  "location": "Locatie",
  "location_back": "Achter",
  "location_bottom": "Onderaan",
  "location_center": "Midden",
  "location_chair": "Stoel",
  "location_corner": "Hoek",
  "location_desk": "Bureau",
  "location_east": "Oost",
  "location_front": "Voorkant",
  "location_high": "Hoog",
  "location_island": "Eiland",
  "location_left": "Links",
  "location_low": "Laag",
  "location_mid": "Midden",
  "location_niche": "Nis",
  "location_north": "Noord",
  "location_right": "Rechts",
  "location_shelves": "Schappen",
  "location_south": "Zuid",
  "location_stove": "Fornuis",
  "location_table": "Tafel",
  "location_top": "Bovenaan",
  "location_west": "West",
  "logged_in_as": "Ingelogd als",
  "logged_out": "Uitgelogd",
  "logged_out_description": "Je bent uitgelogd uit je basalte account",
  "log_out": "Uitloggen",
  "log_out_everywhere": "Overal uitloggen",
  "log_out_everywhere_l1": "Je staat op het punt om uit te loggen op alle apps en apparaten.",
  "long-press-save-scene": "Houd een sfeer ingedrukt om deze bij te werken",
  "louver_30": "30 graden",
  "louver_45": "45 graden",
  "louver_60": "60 graden",
  "louver_horizontal": "Horizontaal",
  "louver_mode": "Louvre mode",
  "louver_off": "Uit",
  "louver_swing": "Kantelen",
  "louver_vertical": "Verticaal",
  "mac_address": "MAC adres",
  "manage": "Beheer",
  "manage_account": "Account beheren",
  "manual_conn": "Manuele verbinding",
  "manual_text": "Geef het adres van de gewenste server in.",
  "max": "Max",
  "menu": "Menu",
  "mf_device": "{count, plural, =0 {Apparaat} =1 {Apparaat} other {Apparaten}}",
  "mf_feels_like": "Voelt aan als {value}",
  "mf_link_streaming_service": "Koppel uw {streamingService} account aan deze stream door op de 'Link' hieronder te klikken.",
  "mf_minute": "{count} {count, plural, =1 {minuut} other {minuten}}",
  "mf_powered_by": "Powered by {value}",
  "mf_second": "{count} {count, plural, =1 {seconde} other {seconden}}",
  "mf_server": "{count, plural, =0 {Server} =1 {Server} other {Servers}}",
  "mf_song": "{count} {count, plural, =1 {nummer} other {nummers}}",
  "mf_source": "Bron: {value}",
  "microphone_gain": "Microfoonversterking",
  "min": "Min",
  "mix": "Mix",
  "mixed_sources": "Meerdere bronnen",
  "mixed_sources_txt_l1": "Deze groep bevat kamers met meerdere bronnen.",
  "mixed_sources_txt_l2": "Kies een bron.",
  "mixes": "Mixes",
  "mixes_small": "mixes",
  "mode": "Mode",
  "mode_auto": "Auto",
  "mode_cooling": "Afkoelen",
  "mode_drying": "Drogen",
  "mode_fan_only": "Ventileren",
  "mode_heating": "Opwarmen",
  "mode_off": "Uit",
  "month": "Maand",
  "month_april": "April",
  "month_august": "Augustus",
  "month_december": "December",
  "month_february": "Februari",
  "month_january": "Januari",
  "month_july": "Juli",
  "month_june": "Juni",
  "month_march": "Maart",
  "month_may": "Mei",
  "month_november": "November",
  "month_october": "Oktober",
  "month_september": "September",
  "month_short_april": "Apr",
  "month_short_august": "Aug",
  "month_short_december": "Dec",
  "month_short_february": "Feb",
  "month_short_january": "Jan",
  "month_short_july": "Jul",
  "month_short_june": "Jun",
  "month_short_march": "Mrt",
  "month_short_may": "Mei",
  "month_short_november": "Nov",
  "month_short_october": "Okt",
  "month_short_september": "Sep",
  "mood": "Stemming",
  "moods": "Stemmingen",
  "music": "Muziek",
  "music_choice": "Muziekkeuze",
  "music_go_to": "Ga naar muziek",
  "music_is_active": "Muziek is actief",
  "music_is_playing": "Muziek is aan het spelen",
  "music_off": "muziek uit",
  "music_off_all": "Schakel alle kamers uit",
  "music_off_all_rooms": "Muziek uit, alle kamers",
  "music_off_current": "Schakel de huidige kamer uit",
  "music_off_current_room": "Muziek uit, deze kamer",
  "music_off_txt_l1": "Eén of alle kamers zullen nu worden uitgeschakeld.",
  "music_off_txt_l2": "Bent u zeker dat u wil doorgaan?",
  "music_on_current": "Zet de huidige kamer aan",
  "music_server": "Muziekserver",
  "mute_area_on_call": "Muziek dempen tijdens oproep",
  "my_favorites": "Mijn favorieten",
  "my_music": "Mijn muziek",
  "name": "Naam",
  "nds": "Server zoeken",
  "nds_music": "Muziekserver zoeken",
  "nds_none": "Geen servers gevonden!",
  "nds_none_music": "Geen muziekservers gevonden!",
  "nds_text_l1": "Dit kan enkele ogenblikken duren...",
  "nds_text_l2": "Zorg ervoor dat het apparaat verbonden is met uw thuisnetwerk.",
  "network": "Netwerk",
  "network_input_invalid": "Alle invoervelden moeten correct ingevuld worden",
  "never": "Nooit",
  "new": "Nieuw",
  "new_alarm": "Nieuwe wekker",
  "new_image": "Nieuwe afbeelding",
  "new_password": "Nieuw wachtwoord",
  "new_password_confirmation": "Bevestig nieuw wachtwoord",
  "new_playlist": "Nieuwe afspeellijst…",
  "new_releases": "Nieuwe releases",
  "new_releases_small": "nieuwe releases",
  "new_scene": "Nieuwe sfeer",
  "new_schedule": "Nieuwe timer",
  "new_step": "Nieuwe stap",
  "next": "Volgende",
  "next_song": "volgend nummer",
  "no": "Neen",
  "no_active_rooms": "Geen actieve kamers",
  "no_all_off": "Neen, niet uitzetten",
  "no_av": "Geen AV input",
  "no_call_entries": "Geen oproepgegevens beschikbaar",
  "no_configuration": "Geen configuratie beschikbaar",
  "no_content_available": "Geen content beschikbaar",
  "no_data": "Geen data",
  "no_deezer_dis": "Neen, Deezer account niet afmelden",
  "no_devices_capabilities": "Geen aanpasbare apparaten",
  "no_doors_available": "Geen deuren beschikbaar",
  "no_favourites": "Geen favorieten",
  "no_home_permission": "Je hebt geen toegang tot dit huis",
  "no_keep_it": "Neen, hou dit",
  "no_lights_capabilities": "Geen aanpasbare lichten",
  "no_linked_homes": "Geen gekoppelde huizen gevonden.",
  "none": "Geen",
  "no_network": "Geen netwerkverbinding",
  "no_preview_available": "Geen voorvertoning mogelijk",
  "no_projects": "Geen projecten gevonden.",
  "no_rooms": "Geen kamers",
  "no_rooms_available": "Geen kamers beschikbaar",
  "no_room_selected": "geen ruimte geselecteerd",
  "no_rooms_listening": "Geen enkele kamer luistert naar deze bron",
  "no_scene": "Geen sfeer",
  "no_scenes_favourites": "Geen favoriete scenes",
  "no_scenes_txt": "Selecteer scenes om hier te tonen.",
  "no_search_results": "Geen zoekresultaten",
  "no_servers": "Er zijn geen servers gevonden",
  "no_shades_capabilities": "Geen aanpasbare zonnewering",
  "no_source": "- uit -",
  "no_sources_available": "Geen audiobronnen beschikbaar",
  "no_steps": "Deze sfeer heeft nog geen stappen",
  "not_allowed": "Niet toegelaten",
  "not_allowed_l1": "U heeft geen permissie om dit te doen.",
  "notification": "Melding",
  "notification_permission_missing": "Basalte Home heeft geen toestemming om notificaties te tonen. Je kan notificaties aanzetten in de systeeminstellingen van uw apparaat.",
  "notifications": "Meldingen",
  "not_linked": "Niet gekoppeld",
  "not_now": "Niet nu",
  "not_set": "Niet ingesteld",
  "no_users_l1": "Er zijn geen gebruikers aangetroffen.",
  "no_users_l2": "Selecteer een server met gebruikers.",
  "now_playing": "Speelt nu af",
  "number_of_songs": "nummer(s)",
  "off": "Uit",
  "offline_server": "Offline huis",
  "offset_minutes": "Offset (minuten)",
  "ok": "OK",
  "on": "aan",
  "onix_black": "Zwart",
  "online": "online",
  "only_png_jpeg_supported": "Enkel PNG and JPEG afbeeldingen worden ondersteund.",
  "open": "Open",
  "open_close_door": "Deur",
  "open_close_gate": "Poort",
  "open_close_window": "Venster",
  "open_settings": "Instellingen openen",
  "open_spotify": "Open Spotify",
  "open_spotify_l1": "U staat op het punt de Spotify app te openen.",
  "open_state": "Open",
  "or": "Of",
  "order_cameras_by_holding_and_draging": "Sleep de camera's in de gewenste volgorde.",
  "other": "Andere",
  "out": "Uit",
  "page_order": "Pagina/tegel volgorde",
  "pair": "Koppelen",
  "password": "Wachtwoord",
  "password_changed": "Wachtwoord gewijzigd",
  "password_confirmation": "Wachtwoord bevestiging",
  "password_requirements": "Uw wachtwoord moet minstens 8 karakters bevatten en tenminste één hoofdletter, één kleine letter en één nummer.",
  "permission_denied": "Toestemming geweigerd",
  "permission_denied_go_to_settings_l1": "Deze functie heeft toestemming(en) nodig om correct te werken.",
  "permission_denied_go_to_settings_l2": "Geef toestemming(en) voor deze app in de instellingen van uw apparaat om alle functies in te schakelen.",
  "photo_from_camera": "Maak een foto",
  "photo_from_library": "Selecteer afbeelding uit bibliotheek",
  "pick_room_function": "Kies een kamer en functie",
  "play": "Play",
  "playback_gain": "Afspeelversterking",
  "playing_in": "speelt momenteel in",
  "playlist": "Afspeellijst",
  "playlists": "Afspeellijsten",
  "playlists_itunes": "iTunes afspeellijsten",
  "playlists_l": "afspeellijsten",
  "playlists_local": "Lokale afspeellijsten",
  "playlists_shared": "Gedeelde afspeellijsten",
  "play_next": "Als volgende afspelen",
  "play_now": "Nu afspelen",
  "please_login_again": "Gelieve opnieuw in te loggen",
  "please_try_a_different_image": "Gelieve een andere afbeelding te proberen",
  "please_try_again": "Probeer het opnieuw",
  "popular": "Populair",
  "position": "Positie",
  "precipitation": "Neerslag",
  "previous": "Vorige",
  "problems_continue_integrator": "Als u problemen blijft ondervinden, contacteer dan uw installateur.",
  "project": "Project",
  "project_image": "Projectbeeld",
  "projects_network_error": "Kan projecten niet laden vanwege een netwerkfout.",
  "proximity_activation": "Nabijheidssensor",
  "proximity_far": "Ver",
  "proximity_medium": "Midden",
  "proximity_near": "Dichtbij",
  "queue": "Wachtrij",
  "radio": "Radio",
  "radio_error": "Fout tijdens ophalen van radiostations",
  "radio_stations": "Radiozenders",
  "radio_stations_other": "Andere Radiozender",
  "recently_played": "Onlangs afgespeeld",
  "related_artists": "Vergelijkbare artiesten",
  "reload": "Opnieuw laden",
  "remember_me": "Onthou mij",
  "remote": "Remote",
  "remote_ellie_intercom_outdated": "Verouderde Ellie",
  "remote_ellie_intercom_outdated_l1": "De Ellie die u probeert te bellen heeft een software update nodig.",
  "remote_ellie_intercom_outdated_l2": "Probeer nogmaals na de update.",
  "remove": "Verwijderen",
  "remove_alarm": "Verwijder wekker",
  "remove_basalte_favourite": "Verwijder Basalte favoriet",
  "remove_deezer_favourite": "Verwijder Deezer favoriet",
  "remove_from_fav": "uit favorieten verwijderen",
  "remove_schedule": "Timer verwijderen",
  "remove_spotify_favourite": "Verwijder Spotify favoriet",
  "remove_spotify_preset": "Verwijder Spotify preset",
  "remove_tidal_favourite": "Verwijder TIDAL favoriet",
  "remove_txt_l1": "Dit item wordt verwijderd.",
  "remove_txt_l2": "Bent u zeker dat u wil doorgaan?",
  "rename": "naam wijzigen",
  "repeat": "Herhaal",
  "replace_queue": "Wachtrij vervangen",
  "resend_code": "Code opnieuw versturen",
  "resend_confirmation_code": "Bevestigingscode opnieuw versturen",
  "reset_image": "Reset afbeelding",
  "reset_password": "Wachtwoord resetten",
  "reset_password_l1": "Bent u zeker dat u uw wachtwoord wilt resetten?",
  "restart": "Herstart",
  "restart_app": "App herstarten",
  "restart_app_q": "App opnieuw starten?",
  "restarting_server": "Server herstarten",
  "restart_music_server": "Herstart de muziekserver",
  "restart_music_server_txt_l1": "U staat op het punt de muziekserver te herstarten.",
  "restart_server": "Herstart de server",
  "restart_server_txt_l1": "U staat op het punt de server te herstarten.",
  "restart_server_txt_l2": "De applicatie zal automatisch opnieuw verbinden.",
  "retrieving": "Resultaten ophalen…",
  "retrieving_users_stop_text": "Gebruikers ophalen mislukt, juiste server?",
  "retrieving_users_text": "Gebruikers ophalen...",
  "right": "Rechts",
  "room": "Kamer",
  "room_attic": "Zolder",
  "room_ball_room": "Balzaal",
  "room_bar": "Bar",
  "room_basement": "Kelder",
  "room_bathroom": "Badkamer",
  "room_bedroom": "Slaapkamer",
  "room_corridor": "Gang",
  "room_dining_room": "Eetkamer",
  "room_dressing_room": "Kleedkamer",
  "room_driveway": "Oprit",
  "room_entry": "Ingang",
  "room_family_room": "Gezinskamer",
  "room_foyer": "Foyer",
  "room_garage": "Garage",
  "room_garden": "Tuin",
  "room_guest_room": "Gastenkamer",
  "room_gym": "Fitnessruimte",
  "room_hallway": "Hal",
  "room_hobby_room": "Hobbykamer",
  "room_home_cinema": "Home cinema",
  "room_home_office": "Bureau",
  "room_images": "Kamer afbeeldingen",
  "room_intercom_lost": "Verbinding verbroken",
  "room_intercom_lost_sub": "De verbinding is verbroken, probeer opnieuw",
  "room_intercom_unavailable": "Kamer niet beschikbaar",
  "room_intercom_unavailable_sub": "Deze kamer is momenteel niet beschikbaar",
  "room_intercom_unreachable": "Kamer niet bereikbaar",
  "room_intercom_unreachable_sub": "Deze kamer is momenteel niet bereikbaar",
  "room_kitchen": "Keuken",
  "room_laundry_room": "Wasruimte",
  "room_library": "Bibliotheek",
  "room_living_room": "Woonkamer",
  "room_master_bedroom": "Hoofdslaapkamer",
  "room_media_room": "Mediaruimte",
  "room_meeting_room": "Vergaderzaal",
  "room_nook": "Hoek",
  "room_nursery": "Kinderkamer",
  "room_pantry": "Voorraadruimte",
  "room_parking_place": "Parkeerplaats",
  "room_patio": "Patio",
  "room_pool": "Zwembad",
  "room_pool_house": "Poolhouse",
  "room_rack": "Rack",
  "room_room": "Kamer",
  "rooms": "Kamers",
  "room_scene_no_functions": "Deze kamer heeft geen functies",
  "room_settings": "Kamerinstellingen",
  "room_spa": "Spa",
  "room_stable": "Stal",
  "room_staircase": "Trap",
  "room_terrace": "Terras",
  "room_toilet": "Toilet",
  "room_utility_room": "Bijkeuken",
  "room_wine_cellar": "Wijnkelder",
  "room_workshop": "Workshop",
  "rotation": "Rotatie",
  "save": "Opslaan",
  "save_as": "opslaan als",
  "save_basalte_favourite": "Basalte favoriet opslaan",
  "save_changes": "Wijzigingen opslaan",
  "save_changes_l1": "Niet alle wijzigingen zijn opgeslagen",
  "save_changes_l2": "Wilt u ze nu opslaan?",
  "save_deezer_favourite": "Deezer favoriet opslaan",
  "save_spotify_favourite": "Spotify favoriet opslaan",
  "save_spotify_preset": "Spotify preset opslaan",
  "save_tidal_favourite": "TIDAL favoriet opslaan",
  "scene": "Sfeer",
  "scene_activated": "Scène geactiveerd",
  "scene_away": "Afwezig",
  "scene_cooking": "Koken",
  "scene_dinner": "Diner",
  "scene_error": "Scènefout",
  "scene_evening": "Avond",
  "scene_good_morning": "Goedemorgen",
  "scene_good_night": "Goedenacht",
  "scene_guests": "Gasten",
  "scene_home": "Thuis",
  "scene_image_error": "Scène afbeelding error",
  "scene_movie": "Film",
  "scene_not_available": "Er zijn geen sferen beschikbaar.",
  "scene_not_selected_l1": "Er is geen sfeer geselecteerd.",
  "scene_not_selected_l2": "Kies een sfeer.",
  "scene_party": "Feest",
  "scene_relax": "Relax",
  "scenes": "Sferen",
  "scene_select_or_new": "Selecteer een sfeer of maak een nieuwe.",
  "scene_vacation": "Vakantie",
  "scene_welcome": "Welkom",
  "scene_workout": "Trainingssessie",
  "schedule": "Timer",
  "schedule_at": "Timen op",
  "schedule_not_selected_l1": "Geen timer geselecteerd.",
  "schedule_not_selected_l2": "Gelieve een timer te selecteren.",
  "scheduler": "Planner",
  "schedules": "Planner",
  "schedule_select_or_new": "Selecteer een timer of maak een nieuwe.",
  "screen_orientation": "Schermoriëntatie",
  "search": "Zoeken",
  "search_again": "Opnieuw zoeken",
  "search_ph": "zoek artiest, nummer of album...",
  "search_result": "Resultaten zoeken voor",
  "security": "Beveiliging",
  "select_admin": "Selecteer een admin profiel om verder te gaan met het update proces",
  "select_home": "Selecteer uw huis",
  "selection": "Selectie",
  "selection_all": "alles selecteren",
  "select_photo": "Selecteer afbeelding",
  "select_server": "Selecteer een server",
  "select_user": "Selecteer een profiel om in te loggen",
  "serial_number": "Serienummer",
  "server": "Server",
  "server_address": "Server adres",
  "server_basalte_cloud_no_connection": "Server kan geen verbinding maken met de Basalte Cloud",
  "server_exists_already": "Server bestaat al!",
  "server_mac": "Server MAC adres",
  "servers": "Servers",
  "server_status": "Server status",
  "server_update_info_l2": "gelieve de Basalte server te updaten.",
  "server_update_required": "Server update nodig",
  "server_version": "Server versie",
  "set": "Instellen",
  "set_new_password": "Nieuw wachtwoord instellen",
  "setpoint": "Instelpunt",
  "settings": "Instellingen",
  "shade_blind": "Gordijn",
  "shade_center": "Centrum",
  "shade_curtain": "Gordijn",
  "shade_left": "Links",
  "shade_pool_shutter": "Zwembadafdekking",
  "shade_right": "Rechts",
  "shade_roman_blind": "Vouwgordijn",
  "shades": "Zonwering",
  "shade_shade": "Zonwering",
  "shade_shutter": "Rolluik",
  "shade_solar_protection": "Zonnewering",
  "shade_venetian_blind": "Jaloezie",
  "shade_vertical_blind": "Verticale zonnewering",
  "share": "delen",
  "shared_playlist": "Gedeelde afspeellijst",
  "show_album": "Album tonen",
  "show_artist": "Artiest tonen",
  "show_background_art": "Toon achtergrond",
  "show_more": "Meer tonen…",
  "show_status_bar": "Toon de iPad statusbalk",
  "show_status_bar_device": "Toon de statusbalk",
  "sign_in": "Inloggen",
  "sign_in_info_tidal_l1": "Gelieve uw TIDAL-inloggegevens in te geven",
  "sign_up": "Registreer",
  "single_room": "Kamer",
  "sleep": "Slaapstand",
  "software_update": "Software-update",
  "something_went_wrong": "Er is iets fout gegaan",
  "songs": "Nummers",
  "sonos": "Sonos",
  "sonos_unreachable": "Kan Sonos niet vinden",
  "sonos_unreachable_txt_l1": "Verifieer dat uw apparaat aangesloten en verbonden is.",
  "sound_settings": "Geluidsinstellingen",
  "source": "Bron",
  "sources": "Bronnen",
  "spotify": "Spotify",
  "spotify_beta": "Spotify (beta)",
  "spotify_connect": "Spotify Connect",
  "spotify_connect_instructions": "Spotify Connect",
  "spotify_connect_instructions_1": "Zorg ervoor dat de Core server en het toestel met de Spotify app zich op hetzelfde netwerk bevinden.",
  "spotify_connect_instructions_2": "Selecteer de Core server stream vanuit de Spotify app in de lijst met beschikbare apparaten.",
  "spotify_connect_instructions_body": "Luister op je speakers of tv met de Spotify-app als afstandsbediening.",
  "spotify_connect_instructions_title": "Klaar voor muziek?",
  "spotify_disconnect_txt": "De Spotify account wordt afgemeld voor deze speler.",
  "spotify_free_user_txt": "Enkel voor Spotify Premium abbonnees",
  "spotify_no_link": "Er is nog geen Spotify account gekoppeld",
  "spotify_oauth_error": "Spotify verificatiefout",
  "spotify_preset_error": "Spotify fout",
  "spotify_preset_error_l1": "Spotify preset kon niet gestart worden.",
  "spotify_preset_error_l2": "Zorg ervoor dat een geldige Spotify account reeds verbonden geweest is met deze stream.",
  "spotify_presets": "Spotify Presets",
  "start": "Start",
  "start_page": "Startpagina",
  "start_page_customisation": "Start pagina voorkeuren",
  "start_time_date": "Tijd en datum",
  "startup_view": "Startpagina",
  "startup_volume": "Opstartvolume",
  "start_weather": "Weersinformatie",
  "state_active": "Actief",
  "state_armed": "Ingeschakeld",
  "state_closed": "Gesloten",
  "state_disabled": "Uitgeschakeld",
  "state_disarmed": "Uitgeschakeld",
  "state_down": "Omlaag",
  "state_enabled": "Ingeschakeld",
  "state_false": "Onwaar",
  "state_free": "Vrij",
  "state_high": "Hoog",
  "state_in": "In",
  "state_inactive": "Inactief",
  "state_left": "Links",
  "state_low": "Laag",
  "state_occupied": "Bezet",
  "state_off": "Uit",
  "state_on": "Aan",
  "state_opened": "Open",
  "state_out": "Uit",
  "state_right": "Rechts",
  "state_started": "Gestart",
  "state_stopped": "Gestopt",
  "state_true": "Waar",
  "state_up": "Omhoog",
  "static": "Statisch",
  "stations": "Zenders",
  "status": "Status",
  "stereo_widening": "Stereo verbreding",
  "stop": "Stop",
  "stream": "Stream",
  "streaming": "Streaming",
  "streaming_services": "Streaming services",
  "streams": "Streams",
  "streams_beta": "Streams (beta)",
  "subnet_mask": "Subnet mask",
  "sun": "Zon",
  "sunrise": "Zonsopgang",
  "sunset": "Zonsondergang",
  "swipe_to_pan": "Veeg om te scrollen",
  "switch_server": "Veranderen van server",
  "synchronise": "Nu scannen",
  "system": "Systeem",
  "system_setting": "Systeeminstelling",
  "temperature": "Temperatuur",
  "temperature_unit": "temperatuureenheid",
  "theme": "Thema",
  "thermostat": "Thermostaat",
  "this_cannot_be_undone": "Dit kan niet ongedaan worden gemaakt!",
  "this_week": "Deze week",
  "tidal": "TIDAL",
  "tidal_disconnect_txt": "Deze TIDAL account wordt afgemeld voor deze speler.",
  "tidal_legacy_auth": "TIDAL login vereist",
  "tidal_legacy_auth_l1": "Om TIDAL te kunnen blijven gebruiken, gelieve opnieuw in te loggen.",
  "tidal_no_link": "Er is nog geen TIDAL account gekoppeld",
  "tidal_oauth_error": "TIDAL verificatiefout",
  "tidal_rising": "TIDAL Rising",
  "time": "Time",
  "time_format": "Tijdsformaat",
  "timer": "Timer",
  "timers": "Timers",
  "title": "Titel",
  "today": "Vandaag",
  "tomorrow": "Morgen",
  "top_tracks": "Favoriete nummers",
  "treble": "Treble",
  "try_again": "Probeer opnieuw",
  "try_again_later": "Probeer het later opnieuw",
  "try_again_later_or_contact_support": "Probeer het later opnieuw of contacteer support",
  "turned_off": "Uitgeschakeld",
  "turned_on": "Ingeschakeld",
  "turn_off": "Uitschakelen",
  "turn_on": "Inschakelen",
  "turn_on_default_rooms": "Standaardkamers van deze bron aanzetten?",
  "turn_on_music": "Zet muziek aan",
  "turn_on_tv": "Zet TV aan",
  "tv": "TV",
  "tv_off": "TV uit",
  "tv_off_current_room": "TV uit, deze kamer",
  "unavailable": "Niet beschikbaar",
  "unavailable_src": "Bron niet beschikbaar",
  "unavailable_src_txt": "De huidige bron is niet beschikbaar.",
  "uncollapse": "Openklappen",
  "ungroup": "Degroeperen",
  "unknown": "Onbekend",
  "unknown_account": "Account is niet gevonden",
  "unknown_account_l1": "Dit e-mailadres is geen Basalte account.",
  "unknown_av": "Onbekende AV input",
  "unknown_src": "Onbekende bron",
  "unknown_src_txt": "Onbekende bron voor deze server.",
  "unlink": "Ontkoppel",
  "unlink_server": "Ontkoppel van Basalte account",
  "unlink_server_full_sub": "Dit project loskoppelen van Basalte account? Het zal niet langer beschikbaar zijn vanop afstand.",
  "unreachable": "Onbereibaar",
  "unshare": "niet langer delen",
  "unsupported_browser": "Browser wordt niet ondersteund",
  "unsupported_browser_l1": "Er kan onverwacht gedrag optreden.",
  "unsupported_browser_l2": "Installeer Chrome of Safari voor een betere gebruikerservaring.",
  "unsupported_browser_live": "Deze browser ondersteunt geen Basalte account. Probeer een nieuwe versie van Chrome of Safari.",
  "up": "Omhoog",
  "update": "Update",
  "update_available": "Update",
  "update_available_full": "Update beschikbaar",
  "update_info_l1": "Om de beste gebruikerservaring te garanderen,",
  "update_library": "Muziekbibliotheek opnieuw scannen",
  "update_music_server": "Update de muziekserver",
  "update_scene": "Sfeer bijwerken",
  "update_scene_l1": "Bewaar de huidige staat in deze sfeer.",
  "update_server": "Update de server",
  "updating_server": "Server updaten",
  "updating_server_info": "Zet de server niet af",
  "up_to_date": "Actueel",
  "username": "Gebruikersnaam",
  "uv": "UV",
  "value_invalid": "De opgegeven waarde is ongeldig",
  "verify": "Bevestig",
  "verifying": "verifiëren",
  "version": "Versie",
  "video_background": "Video achtergrond",
  "video_go_to": "Ga naar TV",
  "video_is_active": "TV is actief",
  "video_is_playing": "Video is aan het spelen",
  "video_unavailable": "TV of audio systeem is niet beschikbaar",
  "volume": "Volume",
  "warning": "Waarschuwing",
  "water": "Water",
  "weather": "Weer",
  "weather_daily_data_unavailable": "Er is momenteel geen dagelijkse weersinformatie beschikbaar",
  "weather_hourly_data_unavailable": "Er is momenteel geen uurlijkse weersinformatie beschikbaar",
  "weather_information": "Weersinformatie",
  "week": "Week",
  "weekly": "Wekelijks",
  "what": "Wat",
  "what_music": "Welke muziek",
  "when": "Wanneer",
  "where": "Waar",
  "who": "Wie",
  "widget_favourite_scenes_description": "Snelle toegang tot uw favoriete sferen.",
  "widget_favourite_scenes_title": "Thuis sferen",
  "wind": "Wind",
  "wrong_password": "Fout wachtwoord",
  "wrong_password_txt": "Het ingegeven wachtwoord stemt niet overeen met het gebruikerswachtwoord",
  "wrong_username_and_or_password": "Verkeerde gebruikersnaam en/of wachtwoord",
  "year": "Jaar",
  "yes": "Ja",
  "yes_all_off": "Ja, alles uitschakelen",
  "yes_cur_off": "Ja, uitschakelen",
  "yes_deezer_dis": "Ja, Deezer account afmelden",
  "yes_delete_it": "Ja, wissen",
  "yesterday": "Gisteren",
  "zone": "Kamer",
  "zones": "Kamers"
}
