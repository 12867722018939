'use strict'
import Sortable from 'sortablejs'
const BasUtil = require('@basalte/bas-util')

angular
  .module('basalteApp')
  .controller('lightsCtrl', [
    '$rootScope',
    '$scope',
    'BAS_ROOMS',
    'BAS_SHARED_SERVER_STORAGE',
    'BasAppDevice',
    'BasUtilities',
    'CurrentRoom',
    lightsCtrl
  ])

/**
 * @param $rootScope
 * @param $scope
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {BAS_SHARED_SERVER_STORAGE} BAS_SHARED_SERVER_STORAGE
 * @param {BasAppDevice} BasAppDevice
 * @param {BasUtilities} BasUtilities
 * @param {CurrentRoom} CurrentRoom
 */
function lightsCtrl (
  $rootScope,
  $scope,
  BAS_ROOMS,
  BAS_SHARED_SERVER_STORAGE,
  BasAppDevice,
  BasUtilities,
  CurrentRoom
) {
  const lights = this

  const CSS_CLASS_ADD_DRAG_SPACING_TO_GROUPS = 'add-drag-spacing-to-groups'
  const CSS_CLASS_LIGHTS = 'lights'
  const CSS_CLASS_LIGHT_GROUP_ITEMS = 'light-group-items'
  const CSS_CLASS_LIGHT_GROUPS = 'light-groups'
  const CSS_CLASS_LIGHT_DROPPED_ELEMENT = 'light-dropped'
  const CSS_CLASS_LIGHT_DRAG_HANDLER = 'light-drag-handler'
  const CSS_CLASS_LIGHT_SELECTED = 'light-selected'
  const CSS_CLASS_LIGHT_SELECTED_GHOST_ELEMENT = 'light-selected-ghost-element'

  const lightGroupsContainerDOM =
    document.getElementsByClassName(CSS_CLASS_LIGHT_GROUPS)[0]

  const lightsWithoutGroupDOM =
    document.getElementsByClassName(CSS_CLASS_LIGHTS)[0]

  lights.createGroup = createGroup
  lights.toggleEdit = toggleEdit
  lights.cancelEditMode = cancelEditMode
  lights.toggleHideGroup = toggleHideGroup
  lights.removeGroup = removeGroup
  lights.renameGroup = renameGroup

  /**
   * @type {BasRooms}
   */
  lights.rooms = BAS_ROOMS.ROOMS

  /**
   * @type {TCurrentRoomState}
   */
  lights.currentRoom = CurrentRoom.get()

  /**
   * @type {Object<string, boolean>}
   */
  lights.groupsHiddenState = {}

  /**
   * @type {Object<string, boolean>}
   */
  lights.groupsRenameState = {}

  const SHOW_GROUP_DEBOUNCE_MS = 500

  let _listeners = []
  let showGroupTimeoutId = null
  let groupUuidDebounced = ''

  lights.isAllGroupCollapsed = true
  lights.editState = false
  lights.uiLightsWithoutGroupSortables = []
  lights.uiLightGroupsSortables = []

  init()

  function init () {
    const basRoomLights = lights.rooms.rooms[lights.currentRoom.roomId]?.lights

    $scope.$on('$destroy', _onDestroy)

    if (basRoomLights?.hasLightGroupOrder) _lightGroupOrderUpdated()

    _listeners.push($rootScope.$on(
      BAS_SHARED_SERVER_STORAGE.EVT_LIGHT_GROUP_ORDER_UPDATED,
      _lightGroupOrderUpdated
    ))
  }

  function createGroup () {
    const basRoomLights = lights.rooms.rooms[lights.currentRoom.roomId]?.lights

    basRoomLights?.createGroup()

    BasUtilities.waitForFrames(2, _createSortables)
  }

  /**
   * @param {boolean} [force]
   */
  function toggleEdit (force) {
    const basRoomLights = lights.rooms.rooms[lights.currentRoom.roomId]?.lights

    lights.editState = BasUtil.isBool(force) ? force : !lights.editState

    if (lights.uiLightGroupsSortables.length > 0) {
      _toggleDrag(lights.uiLightGroupsSortables)
    }

    if (lights.uiLightsWithoutGroupSortables.length > 0) {
      _toggleDrag(lights.uiLightsWithoutGroupSortables)
    }

    if (!lights.editState && basRoomLights) {

      for (const uiLightGroupUuid of basRoomLights.uiLightGroups) {

        if (lights.groupsRenameState[uiLightGroupUuid]) {
          renameGroup(uiLightGroupUuid)
        }
      }

      _removeGroupsWithSingleLight()

      basRoomLights.updateServerStorageWithGroups()
    }
  }

  function cancelEditMode () {
    const basRoom = lights.rooms.rooms[lights.currentRoom.roomId]

    lights.editState = false

    if (basRoom) basRoom.lights?.syncLightGroupOrderFromStorage(basRoom)

    BasUtilities.waitForFrames(2, _createSortableForLightGroups)
  }

  /**
   * @param {string} uiLightGroupUuid
   * @param {boolean} [force]
   */
  function toggleHideGroup (uiLightGroupUuid, force) {
    const basRoomLights = lights.rooms.rooms[lights.currentRoom.roomId]?.lights
    const uiLightGroup = basRoomLights?.basLights[uiLightGroupUuid]

    if (
      uiLightGroup &&
      !lights.groupsRenameState[uiLightGroupUuid] &&
      uiLightGroup.groupLightUuids.length
    ) {

      lights.groupsHiddenState[uiLightGroupUuid] =
        BasUtil.isBool(force)
          ? force
          : !lights.groupsHiddenState[uiLightGroupUuid]
    }

    if (typeof force === 'undefined') {
      lights.isAllGroupCollapsed =
        !!Object.values(lights.groupsHiddenState).some(val => val === true)
    }
  }

  /**
   * @param {string} uiLightGroupUuid
   */
  function removeGroup (uiLightGroupUuid) {
    const basRoomLights = lights.rooms.rooms[lights.currentRoom.roomId]?.lights

    if (
      basRoomLights?.uiLightsWithoutGroup &&
      basRoomLights?.uiLightGroups?.length &&
      basRoomLights?.basLights
    ) {
      const uiLightGroup = basRoomLights.basLights[uiLightGroupUuid]

      if (uiLightGroup) {

        for (const lightUuid of uiLightGroup.groupLightUuids) {
          basRoomLights.uiLightsWithoutGroup.push(lightUuid)
        }

        basRoomLights.removeGroup(uiLightGroupUuid)

        delete lights.groupsHiddenState[uiLightGroupUuid]
        delete lights.groupsRenameState[uiLightGroupUuid]
      }
    }
  }

  /**
   * @param {string} uiLightGroupUuid
   */
  function renameGroup (uiLightGroupUuid) {

    lights.groupsRenameState[uiLightGroupUuid] =
        !lights.groupsRenameState[uiLightGroupUuid]
  }

  /**
   * @private
   */
  function _lightGroupOrderUpdated () {
    const basRoomLights = lights.rooms.rooms[lights.currentRoom.roomId]?.lights

    BasUtilities.waitForFrames(2, _createSortables)

    if (
      !Object.keys(lights.groupsHiddenState).length &&
      !Object.keys(lights.groupsRenameState).length &&
      basRoomLights
    ) {

      for (const uiLightGroupUuid of basRoomLights.uiLightGroups) {

        lights.groupsHiddenState[uiLightGroupUuid] = true
        lights.groupsRenameState[uiLightGroupUuid] = false
      }
    }

    $scope.$applyAsync()
  }

  /**
   * @private
   */
  function _onDestroy () {
    lights.groupsHiddenState = {}
    lights.groupsRenameState = {}
    lights.editState = false

    // Destroy sortables
    lights.uiLightsWithoutGroupSortables.forEach(sortable => sortable.destroy())
    lights.uiLightGroupsSortables.forEach(sortable => sortable.destroy())

    lights.uiLightsWithoutGroupSortables = []
    lights.uiLightGroupsSortables = []

    BasUtil.executeArray(_listeners)
    _listeners = []
  }

  /**
   * @private
   */
  function _createSortables () {
    _createSortableForLightGroups()
    _createSortablesForLightsWithoutGroup()
  }

  /**
   * @private
   */
  function _createSortableForLightGroups () {

    const lightGroupsDOMElements =
      document.getElementsByClassName(CSS_CLASS_LIGHT_GROUP_ITEMS)

    lights.uiLightGroupsSortables.forEach(sortable => sortable.destroy())

    lights.uiLightGroupsSortables = []

    for (const lightGroupDom of lightGroupsDOMElements) {
      lights.uiLightGroupsSortables.push(
        Sortable.create(lightGroupDom, {
          group: 'shared',
          filter: '.light-group-item-sort-disabled',
          animation: 150,
          forceFallback: true,
          disabled: !lights.editState,
          onAdd: (evt) => {
            evt.item.classList.add(CSS_CLASS_LIGHT_DROPPED_ELEMENT)
            _syncUILightsGroupWithDom(evt)
          },
          onRemove: (evt) => {
            _syncUILightsGroupWithDom(evt)
          },
          onStart: () => {

            if (lightGroupsContainerDOM) {
              lightGroupsContainerDOM.classList.add(
                CSS_CLASS_ADD_DRAG_SPACING_TO_GROUPS
              )
            }
          },
          onEnd: (evt) => {
            _syncUILightsGroupWithDom(evt)
            if (lightGroupsContainerDOM) {
              lightGroupsContainerDOM.classList.remove(
                CSS_CLASS_ADD_DRAG_SPACING_TO_GROUPS
              )
            }
          },
          onMove: _onMoveShowGroupIfNeeded
        })
      )
    }
  }

  /**
   * @private
   * @param {Object} sortableEvent
   */
  function _syncUILightsGroupWithDom (sortableEvent) {
    const basRoomLights = lights.rooms.rooms[lights.currentRoom.roomId]?.lights

    const uuid = sortableEvent.type === 'remove'
      ? sortableEvent.from.id
      : sortableEvent.to.id

    const lightGroup = basRoomLights?.getGroupByUuid(uuid)

    if (lightGroup) {

      const groupLightUuids = lightGroup.groupLightUuids

      const index = groupLightUuids.indexOf(sortableEvent.item.id)
      const newIndex = sortableEvent.newIndex
      const oldIndex = sortableEvent.oldIndex

      if (index >= 0) {

        if (
          sortableEvent.from.id === sortableEvent.to.id &&
            sortableEvent.type === 'end' &&
            groupLightUuids[newIndex]
        ) {
          // Meant for sorting in group
          groupLightUuids.splice(oldIndex, 1)
          groupLightUuids.splice(
            newIndex,
            0,
            sortableEvent.item.id
          )
        } else if (sortableEvent.type === 'remove') {

          groupLightUuids.splice(index, 1)
        }

        if (!groupLightUuids.length) removeGroup(uuid)

      } else {
        // Handle sortable add event
        groupLightUuids.splice(newIndex, 0, sortableEvent.item.id)
      }
    }

    _removeSortableGhostElements()

    $scope.$applyAsync()
  }

  /**
   * @private
   */
  function _createSortablesForLightsWithoutGroup () {

    if (!lights.uiLightsWithoutGroupSortables.length) {

      lights.uiLightsWithoutGroupSortables.push(
        Sortable.create(lightsWithoutGroupDOM, {
          group: 'shared',
          filter: '.light-sort-disabled',
          animation: 150,
          handle: (
            BasAppDevice.isCoreClient() ||
              BasAppDevice.isAndroid() ||
              BasAppDevice.isIos()
          )
            ? '.' + CSS_CLASS_LIGHT_DRAG_HANDLER
            : '',
          forceFallback: true,
          chosenClass: CSS_CLASS_LIGHT_SELECTED,
          ghostClass: CSS_CLASS_LIGHT_SELECTED_GHOST_ELEMENT,
          fallbackClass: CSS_CLASS_LIGHT_SELECTED,
          disabled: !lights.editState,
          onAdd: (evt) => {
            evt.item.classList.add(CSS_CLASS_LIGHT_DROPPED_ELEMENT)
            _syncUIForLightsWithoutGroup()
          },
          onRemove: _syncUIForLightsWithoutGroup,
          onStart: () => {

            if (lightGroupsContainerDOM) {
              lightGroupsContainerDOM.classList.add(
                CSS_CLASS_ADD_DRAG_SPACING_TO_GROUPS
              )
            }
          },
          onEnd: () => {
            _syncUIForLightsWithoutGroup()
            if (lightGroupsContainerDOM) {
              lightGroupsContainerDOM.classList.remove(
                CSS_CLASS_ADD_DRAG_SPACING_TO_GROUPS
              )
            }
          },
          onMove: _onMoveShowGroupIfNeeded
        })
      )
    }
  }

  /**
   * @private
   * @param {Object} sortableEvent
   */
  function _onMoveShowGroupIfNeeded (sortableEvent) {
    const basRoomLights = lights.rooms.rooms[lights.currentRoom.roomId]?.lights

    if (
      sortableEvent.to.classList.contains(CSS_CLASS_LIGHT_GROUP_ITEMS) &&
      sortableEvent.to.id
    ) {

      const uiLightGroup = basRoomLights?.getGroupByUuid(sortableEvent.to.id)

      uiLightGroup
        ? _showGroupDebounced(sortableEvent.to.id)
        : clearShowGroupTimeout()
    }
  }

  /**
   * @private
   * @param {string} uiLightGroupUuid
   */
  function _showGroupDebounced (uiLightGroupUuid) {
    const basRoomLights = lights.rooms.rooms[lights.currentRoom.roomId]?.lights
    const uiLightGroup = basRoomLights?.basLights[uiLightGroupUuid]

    if (groupUuidDebounced !== uiLightGroup?.uuid) {
      groupUuidDebounced = uiLightGroup.uuid
      clearShowGroupTimeout()
    }

    showGroupTimeoutId = setTimeout(
      () => {

        toggleHideGroup(uiLightGroupUuid, false)
        lights.isAllGroupCollapsed =
          !!Object.values(lights.groupsHiddenState).some(val => val === true)

        $rootScope.$applyAsync()
      },
      SHOW_GROUP_DEBOUNCE_MS
    )
  }

  function clearShowGroupTimeout () {
    clearTimeout(showGroupTimeoutId)
    showGroupTimeoutId = null
  }

  /**
   * @private
   * @param {Sortable[]} sortablesArray
   */
  function _toggleDrag (sortablesArray) {
    for (const sortable of sortablesArray) {

      if (sortable && BasUtil.isFunction(sortable.option)) {

        sortable.option('disabled', !lights.editState)
      }
    }
  }

  // Working with SortableJS and ng-repeat can create duplicates
  // when dropping an element into a list.
  /**
   * @private
   */
  function _removeSortableGhostElements () {

    const sortableGhostsDOMElements =
      document.getElementsByClassName(CSS_CLASS_LIGHT_DROPPED_ELEMENT)

    for (const ghostElement of sortableGhostsDOMElements) {
      ghostElement.remove()
    }
  }

  /**
   * @private
   */
  function _syncUIForLightsWithoutGroup () {
    const basRoomLights = lights.rooms.rooms[lights.currentRoom.roomId]?.lights

    if (lightsWithoutGroupDOM && basRoomLights) {
      const newArray = []

      for (const childDOM of lightsWithoutGroupDOM.children) {
        if (childDOM && childDOM.id) {
          newArray.push(childDOM.id)
        }
      }

      basRoomLights.uiLightsWithoutGroup = newArray
    }

    _removeSortableGhostElements()
    $scope.$applyAsync()
  }

  /**
   * @private
   */
  function _removeGroupsWithSingleLight () {
    const basRoomLights = lights.rooms.rooms[lights.currentRoom.roomId]?.lights

    if (basRoomLights) {
      basRoomLights.uiLightGroups
        .map(uuid => basRoomLights.basLights[uuid])
        .forEach(lightGroup => {

          if (lightGroup.groupLightUuids?.length <= 1) {

            removeGroup(lightGroup.uuid)
          }
        })
    }
  }
}
