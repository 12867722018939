'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasStateConditions', [
    '$transitions',
    'STATES',
    'SCENE_STATES',
    'SETTINGS_STATES',
    'BAS_STATE',
    'BAS_API',
    'BAS_ROOM',
    'BasState',
    'MainDrawer',
    'CurrentBasCore',
    'CurrentRoom',
    'BasStateHelper',
    'BasSettingsStateHelper',
    BasStateConditions
  ])

/**
 * "previousMainState" represents the last state which had a different
 * main or sub state from the current one
 *
 * @typedef {Object} TBasState
 * @property {Object} css
 * @property {string} previousMainState
 * @property {Object} previousMainStateParams
 */

/**
 * @constructor
 * @param $transitions
 * @param {STATES} STATES
 * @param {SCENE_STATES} SCENE_STATES
 * @param {SETTINGS_STATES} SETTINGS_STATES
 * @param {BAS_STATE} BAS_STATE
 * @param BAS_API
 * @param {BAS_ROOM} BAS_ROOM
 * @param {BasState} BasState
 * @param {MainDrawer} MainDrawer
 * @param {CurrentBasCore} CurrentBasCore
 * @param {CurrentRoom} CurrentRoom
 * @param {BasStateHelper} BasStateHelper
 * @param {BasSettingsStateHelper} BasSettingsStateHelper
 */
function BasStateConditions (
  $transitions,
  STATES,
  SCENE_STATES,
  SETTINGS_STATES,
  BAS_STATE,
  BAS_API,
  BAS_ROOM,
  BasState,
  MainDrawer,
  CurrentBasCore,
  CurrentRoom,
  BasStateHelper,
  BasSettingsStateHelper
) {
  var CSS_STATE_TRANSITION_FADE = 'bas-state-transition-fade'
  var CSS_STATE_TRANSITION_IN = 'bas-state-transition-in'
  var CSS_STATE_TRANSITION_OUT = 'bas-state-transition-out'
  var CSS_STATE_ELLIE_NO_ANIMATION = 'bas-state-ellie-no-animation'
  var CSS_STATE_LENA_NO_ANIMATION = 'bas-state-lena-no-animation'

  var CSS_STATE_CONTENT_TRANSITION_IN = 'bas-state-content-transition-in'
  var CSS_STATE_CONTENT_TRANSITION_OUT = 'bas-state-content-transition-out'

  /**
   * @type {TBasState}
   */
  var state = {
    css: {},
    previousMainState: '',
    previousMainStateParams: null
  }

  this.get = get

  init()

  function init () {

    resetCss()
    $transitions.onStart({}, _onTransitionStart)

    // Priority is set to execute this transition hook first
    // before executing the other onStart transition hooks
    $transitions.onStart(
      {},
      _onTransitionStartPriority,
      {
        priority: 10
      }
    )
  }

  /**
   * @returns {TBasState}
   */
  function get () {
    return state
  }

  function _onTransitionStartPriority (transition) {
    var targetState, from, fromName, toName, options

    targetState = transition.targetState()
    from = transition.from()
    fromName = from.name
    toName = targetState.name()
    options = transition.options()

    // Prevent exiting the main state with native “back“ controls
    if (
      BasStateHelper.hasBaseState(fromName, STATES.MAIN) &&
      !BasUtil.startsWith(toName, STATES.MAIN)
    ) {

      if (!(
        options &&
        options.custom &&
        options.custom.BasExitMainState
      )) {
        return false
      }
    }
  }

  function _onTransitionStart (transition) {

    var targetState, from, fromName, toName, params
    var audioOnly, stateRoomsMusic
    var singleUiFunction, room, roomMusic
    var lights, shades, singleLightUuid, singleShadeUuid, shade
    var settingsStatePrefix

    targetState = transition.targetState()
    from = transition.from()
    fromName = from.name
    toName = targetState.name()
    params = transition.params()

    settingsStatePrefix = BasSettingsStateHelper.getPrefix(toName)

    audioOnly = CurrentBasCore.isAudioOnly()
    room = CurrentRoom.getRoom()
    roomMusic = CurrentRoom.getRoomMusic()

    processTransitions(fromName, toName)
    processPreviousState(transition, fromName, toName)

    stateRoomsMusic = targetState
      .withState(STATES.ROOMS)
      .withParams({
        view: BAS_STATE.S_ROOMS_VIEW_MUSIC
      })

    if (toName === STATES.HOME) {

      if (audioOnly) return stateRoomsMusic
    }

    if (toName === STATES.ROOMS) {

      if (audioOnly) {

        if (params.view !== BAS_STATE.S_ROOMS_VIEW_MUSIC) {

          return stateRoomsMusic
        }
      }
    }

    if (toName === STATES.ROOM) {

      if (
        room ||
        BasStateHelper.hasBaseState(fromName, STATES.CONNECT) ||
        fromName === STATES.MAIN
      ) {
        // Either room is already selected, we are coming from a CONNECT
        //  state, or we are coming from MAIN state

        if (audioOnly || (roomMusic && roomMusic.isGroup())) {

          // In audio only or music group room case, if we are coming
          //  from a room state, go to rooms, music view.
          //  Else go to player.
          return BasStateHelper.hasBaseState(fromName, STATES.ROOM)
            ? stateRoomsMusic
            : targetState.withState(STATES.MUSIC_PLAYER)

        } else if (room) {

          singleUiFunction = room.singleUiFunctionDashboard

          if (fromName === toName) {

            // Do nothing

          } else if (BasStateHelper.hasBaseState(fromName, STATES.ROOM)) {

            // Going from room subview to rooms view
            //  -> navigating backwards: check on single ui function
            //  to redirect directly to rooms view instead of room
            //  dash.

            if (BasUtil.isNEString(singleUiFunction)) {

              if (singleUiFunction ===
                BAS_API.Room.FUNCTIONS.SCENES) {

                // Allow STATES.ROOM (quad favourite scenes),
                //  do nothing

              } else {

                return BasState.target(STATES.ROOMS)
              }

            } else if (BasUtil.isString(singleUiFunction)) {

              // Multiple functions, allow STATES.ROOM, do nothing

            } else {

              // No functions have been parsed yet, go to rooms
              return BasState.target(STATES.ROOMS)
            }

          } else {

            // Going from ... to room view
            //  -> navigating forwards, check on room functions to
            //  possibly redirect directly to the single function
            //  view.

            if (BasUtil.isNEString(singleUiFunction)) {

              switch (singleUiFunction) {
                case BAS_ROOM.F_AV_AUDIO:
                case BAS_API.Room.FUNCTIONS.AUDIO:

                  return targetState.withState(STATES.MUSIC_PLAYER)

                case BAS_ROOM.F_AV_VIDEO:

                  return targetState.withState(STATES.VIDEO_PLAYER)

                case BAS_API.Room.FUNCTIONS.THERMOSTAT:

                  return targetState.withState(STATES.THERMOSTAT).withParams({
                    room: room.id,
                    thermostat: room.thermostats?.thermostats?.[0]?.uuid
                  })

                case BAS_API.Room.FUNCTIONS.LIGHTS:

                  return targetState.withState(STATES.LIGHTS)

                case BAS_API.Room.FUNCTIONS.WINDOW_TREATMENTS:

                  return targetState.withState(STATES.SHADES)

                case BAS_API.Room.FUNCTIONS.SCENES:
                default:

                // Single ui function is scenes or another
                //  not handled function. Allow STATES.ROOM,
                //  do nothing
              }

            } else if (BasUtil.isString(singleUiFunction)) {

              // Multiple functions, allow STATES.ROOM, do nothing

            } else {

              // No functions have been parsed yet,
              //  abort transition
              return false
            }
          }

        } else {

          // No room is selected yet, if audio only show music rooms
          //  overview, else normal rooms overview
          return _toRooms()
        }

      } else {

        // No room is selected yet, if audio only show music rooms
        //  overview, else normal rooms overview

        return _toRooms()
      }
    }

    if (toName === STATES.LISA_LIGHTS) {

      if (room) {

        lights = (room.room && room.room.lights && room.room.lights.length)
          ? room.room.lights
          : null

        // If room has a single light -> set to that light uuid
        //  else if room as multiple lights: set to empty string
        //  else set to null
        singleLightUuid = lights
          ? lights.length === 1 ? lights[0] : ''
          : null

        if (BasUtil.isNEString(singleLightUuid)) {

          return BasStateHelper.hasBaseState(fromName, STATES.LISA_LIGHTS)
            ? BasState.target(STATES.LISA)
            : BasState.target(
              STATES.LISA_LIGHT_DETAIL,
              {
                light: singleLightUuid
              }
            )

        } else {

          // If singleLightUuid is string -> there are multiple lights
          // If singleLightUuid is not a string -> there are no lights
          return BasUtil.isString(singleLightUuid)
        }

      } else {

        // No room is selected yet, disallow state transition
        return false
      }
    }

    if (toName === STATES.LISA_SHADES) {

      if (room) {

        shades = (room.room && room.room.shades && room.room.shades.length)
          ? room.room.shades
          : null

        // If room has a single shade -> set to that shade uuid
        //  else if room as multiple shades: set to empty string
        //  else set to null
        singleShadeUuid = shades
          ? shades.length === 1 ? shades[0] : ''
          : null

        if (BasUtil.isNEString(singleShadeUuid)) {

          return BasStateHelper.hasBaseState(fromName, STATES.LISA_SHADES)
            ? BasState.target(
              STATES.LISA,
              {
                view: BAS_STATE.S_LISA_VIEW_SHADES
              }
            )
            : BasState.target(
              STATES.LISA_SHADE_DETAIL,
              {
                shade: singleShadeUuid
              }
            )

        } else {

          // If singleShadeUuid is string -> there are multiple shades
          // If singleShadeUuid is not a string -> there are no shades
          return BasUtil.isString(singleShadeUuid)
        }

      } else {

        // No room is selected yet, disallow state transition
        return false
      }
    }

    if (
      toName === STATES.LISA_SHADE_DETAIL &&
      params.view !== BAS_STATE.S_LISA_SHADE_DETAILS_VIEW_EXTRA
    ) {

      if (room && room.shades) {

        shade = room.shades.getShade(params.shade)

        if (shade && !shade.canChangePosition) {

          // Going to shade detail view but no position capability

          if (fromName === STATES.LISA_SHADE_DETAIL) {

            return BasState.target(STATES.LISA_SHADES)

          } else {

            return BasState.target(
              STATES.LISA_SHADE_DETAIL,
              {
                shade: params.shade,
                view: BAS_STATE.S_LISA_SHADE_DETAILS_VIEW_EXTRA
              }
            )
          }
        }
      }
    }

    if (toName === STATES.LISA_INTERCOM) {

      // Don't go to detail view in demo (it's empty, no demo intercom)
      if (CurrentBasCore.has() && CurrentBasCore.get().core.isDemo()) {

        return BasState.target(fromName)
      }
    }

    if (toName.indexOf(SCENE_STATES.SCENES) !== -1 &&
      toName.indexOf(STATES.SCHEDULE_SCENES) === -1) {

      if (audioOnly) return stateRoomsMusic

      MainDrawer.toggle(
        BasUtil.endsWith(toName, SCENE_STATES.SCENES) &&
        !BasUtil.endsWith(toName, SCENE_STATES.SCENE_STEP_SCENES)
      )

    } else if (BasStateHelper.hasBaseState(toName, STATES.MUSIC_LIBRARY)) {

      MainDrawer.toggle(toName === STATES.MUSIC_LIBRARY)

    } else if (BasStateHelper.hasBaseState(toName, STATES.SCHEDULES)) {

      if (audioOnly) return stateRoomsMusic

      if (toName === STATES.SCHEDULES) {

        MainDrawer.toggle(true)

      } else {

        MainDrawer.toggle(false)
      }

    } else if (BasStateHelper.hasBaseState(toName, STATES.ALARMS)) {

      if (!audioOnly) return BasState.target(STATES.ROOMS)
      MainDrawer.toggle(toName === STATES.ALARMS)

    } else if (BasStateHelper.hasBaseState(toName, STATES.CALL_HISTORY)) {

      MainDrawer.toggle(toName === STATES.CALL_HISTORY)

    } else if (BasStateHelper.hasBaseState(
      toName,
      BasSettingsStateHelper.getSettingsState(
        SETTINGS_STATES.SETTINGS,
        settingsStatePrefix
      )
    )) {
      MainDrawer.toggle(
        BasSettingsStateHelper.getSettingsState(
          SETTINGS_STATES.SETTINGS,
          settingsStatePrefix
        ) === toName
      )

    } else if (BasStateHelper.hasBaseState(toName, STATES.TIMERS)) {

      if (audioOnly) return stateRoomsMusic

      MainDrawer.toggle(toName === STATES.TIMERS)
    }

    function _toRooms () {

      return audioOnly
        ? stateRoomsMusic
        : targetState.withState(STATES.ROOMS)
    }
  }

  /**
   * @param {string} fromName
   * @param {string} toName
   */
  function processTransitions (fromName, toName) {

    var fromLength, toLength

    fromLength = fromName.length
    toLength = toName.length

    resetCss()

    if (
      (
        BasUtil.endsWith(fromName, SCENE_STATES.SCENE) &&
        toName.indexOf(SCENE_STATES.SCENE) !== -1 &&
        toLength > fromLength
      ) || (
        BasUtil.endsWith(toName, SCENE_STATES.SCENE_ROOM_FUNCTIONS) &&
        toLength > fromLength
      ) || (
        toName.indexOf(SCENE_STATES.SCENE_STEP) !== -1 &&
        fromName.indexOf(SCENE_STATES.SCENE) !== -1 &&
        fromName.indexOf(SCENE_STATES.SCENE_STEP) === -1
      ) || (
        BasUtil.endsWith(
          toName,
          SCENE_STATES.SCENE_STEP_MUSIC_FAVOURITES
        )
      ) || (
        BasUtil.endsWith(
          toName,
          SCENE_STATES.SCENE_STEP_VIDEO_FAVOURITES
        )
      ) || (
        BasUtil.endsWith(toName, SCENE_STATES.SCENE_PICTURE)
      ) || (
        toName === STATES.TIMER_DAY
      ) || (
        fromName === STATES.SCHEDULE &&
        toName.indexOf(STATES.SCHEDULE) === 0 &&
        toLength > fromLength
      ) || (
        toName === STATES.SETTINGS_GENERAL_LISA_ORDER
      ) || (
        fromName === STATES.SETTINGS_MUSIC &&
        toName === STATES.SETTINGS_MUSIC_DEFAULT_ROOMS
      ) || (
        fromName === STATES.SETTINGS_MUSIC_DEFAULT_ROOMS &&
        toName === STATES.SETTINGS_MUSIC_DEFAULT_ROOMS_ROOMS
      ) || (
        fromName === STATES.SETTINGS_MUSIC &&
        toName === STATES.SETTINGS_MUSIC_STREAMING
      ) || (
        fromName === STATES.SETTINGS_MUSIC &&
        toName === STATES.SETTINGS_MUSIC_KNX_PRESETS
      ) || (
        toName === STATES.SETTINGS_PROJECT_ROOM_IMAGES_ROOM
      ) || (
        fromName === STATES.SETTINGS_PROJECT &&
        toName === STATES.SETTINGS_PROJECT_ROOM_IMAGES
      ) || (
        fromName === STATES.SETTINGS_PROJECT &&
        toName === STATES.SETTINGS_PROJECT_PROJECT_IMAGE
      ) || (
        fromName.indexOf(STATES.SETTINGS_MUSIC_KNX_PRESETS) === 0 &&
        toName.indexOf(STATES.SETTINGS_MUSIC_KNX_PRESETS) === 0 &&
        toLength > fromLength
      ) || (
        fromName.indexOf(STATES.SETTINGS_MUSIC_STREAMING) === 0 &&
        toLength > fromLength
      )
    ) {

      state.css[CSS_STATE_CONTENT_TRANSITION_IN] = true

    } else if (
      (
        BasUtil.endsWith(toName, SCENE_STATES.SCENE) &&
        fromName.indexOf(SCENE_STATES.SCENE) !== -1 &&
        fromLength > toLength
      ) || (
        BasUtil.endsWith(toName, SCENE_STATES.SCENE_STEP_MUSIC) &&
        fromName.indexOf(SCENE_STATES.SCENE_STEP_MUSIC) !== -1 &&
        fromLength > toLength
      ) || (
        BasUtil.endsWith(toName, SCENE_STATES.SCENE_STEP_VIDEO) &&
        fromName.indexOf(SCENE_STATES.SCENE_STEP_VIDEO) !== -1 &&
        fromLength > toLength
      ) || (
        BasUtil.endsWith(toName, SCENE_STATES.SCENE_ROOMS) &&
        BasUtil.endsWith(toName, SCENE_STATES.SCENE_ROOM_FUNCTIONS)
      ) || (
        fromName.indexOf(STATES.SCHEDULE) === 0 &&
        toName.indexOf(STATES.SCHEDULE) === 0 &&
        fromLength > toLength
      ) || (
        toName === STATES.SETTINGS_GENERAL
      ) || (
        toName === STATES.SETTINGS_MUSIC &&
        fromName === STATES.SETTINGS_MUSIC_DEFAULT_ROOMS
      ) || (
        toName === STATES.SETTINGS_MUSIC_DEFAULT_ROOMS &&
        fromName === STATES.SETTINGS_MUSIC_DEFAULT_ROOMS_ROOMS
      ) || (
        toName === STATES.SETTINGS_MUSIC &&
        fromName === STATES.SETTINGS_MUSIC_STREAMING
      ) || (
        toName === STATES.SETTINGS_MUSIC &&
        fromName === STATES.SETTINGS_MUSIC_KNX_PRESETS
      ) || (
        toName === STATES.SETTINGS_PROJECT_ROOM_IMAGES &&
        fromName === STATES.SETTINGS_PROJECT_ROOM_IMAGES_ROOM
      ) || (
        toName === STATES.SETTINGS_PROJECT &&
        fromName === STATES.SETTINGS_PROJECT_ROOM_IMAGES
      ) || (
        toName === STATES.SETTINGS_PROJECT &&
        fromName === STATES.SETTINGS_PROJECT_PROJECT_IMAGE
      ) || (
        toName === STATES.TIMER_WEEK &&
        fromName === STATES.TIMER_DAY
      ) || (
        fromName.indexOf(STATES.SETTINGS_MUSIC_KNX_PRESETS) === 0 &&
        toName.indexOf(STATES.SETTINGS_MUSIC_KNX_PRESETS) === 0 &&
        toLength < fromLength
      ) || (
        toName.indexOf(STATES.SETTINGS_MUSIC_STREAMING) === 0 &&
        toLength < fromLength
      )
    ) {

      state.css[CSS_STATE_CONTENT_TRANSITION_OUT] = true

    } else if (
      (
        fromName === STATES.ROOM &&
        toName.indexOf(STATES.ROOM) === 0 &&
        toLength > STATES.ROOM.length
      ) || (
        fromName.indexOf(STATES.THERMOSTAT) === 0 &&
        toName.indexOf(STATES.THERMOSTAT) === 0 &&
        toLength > fromLength
      ) || (
        fromName === STATES.HOME &&
        toName === STATES.ROOMS
      ) || (
        fromName === STATES.HOME &&
        toName === STATES.ROOM
      ) || (
        fromName === STATES.ROOMS &&
        toName === STATES.ROOM
      ) || (
        toName === STATES.CAMERAS_DETAIL
      ) || (
        fromName.indexOf(STATES.ROOM_SCENES) === 0 &&
        toName.indexOf(STATES.ROOM_SCENES) === 0 &&
        toLength > fromLength
      ) || (
        fromName === STATES.ROOMS && (
          toName === STATES.LIGHTS ||
          toName === STATES.THERMOSTAT ||
          toName === STATES.SHADES ||
          toName === STATES.GENERIC_DEVICES ||
          BasUtil.startsWith(toName, STATES.MUSIC) ||
          BasUtil.startsWith(toName, STATES.VIDEO) ||
          BasUtil.startsWith(toName, STATES.ROOM_SCENES)
        )
      )
    ) {

      state.css[CSS_STATE_TRANSITION_IN] = true

    } else if (
      (
        toName === STATES.ROOM &&
        BasUtil.startsWith(fromName, STATES.ROOM)
      ) || (
        BasUtil.startsWith(fromName, STATES.THERMOSTAT) &&
        BasUtil.startsWith(toName, STATES.THERMOSTAT) &&
        toLength < fromLength
      ) || (
        fromName === STATES.ROOMS &&
        toName === STATES.HOME
      ) || (
        fromName === STATES.ROOM &&
        toName === STATES.ROOMS
      ) || (
        fromName === STATES.ROOM &&
        toName === STATES.HOME
      ) || (
        toName === STATES.CAMERAS &&
        fromName === STATES.CAMERAS_DETAIL
      ) || (
        BasUtil.startsWith(fromName, STATES.ROOM_SCENES) &&
        BasUtil.startsWith(toName, STATES.ROOM_SCENES) &&
        toLength < fromLength
      ) || (
        toName === STATES.ROOMS && (
          fromName === STATES.LIGHTS ||
          fromName === STATES.THERMOSTAT ||
          fromName === STATES.SHADES ||
          fromName === STATES.GENERIC_DEVICES ||
          BasUtil.startsWith(fromName, STATES.MUSIC) ||
          BasUtil.startsWith(fromName, STATES.VIDEO) ||
          BasUtil.startsWith(fromName, STATES.ROOM_SCENES)
        )
      )
    ) {

      state.css[CSS_STATE_TRANSITION_OUT] = true

    } else if (
      fromName === STATES.HOME ||
      fromName === STATES.ROOMS ||
      toName === STATES.ROOMS ||
      toName === STATES.HOME ||
      toName.indexOf(STATES.ALARMS) === 0 ||
      toName.indexOf(STATES.CALL_HISTORY) === 0 ||
      toName.indexOf(STATES.SETTINGS) === 0 ||
      fromName.indexOf(STATES.SETTINGS) === 0 ||
      fromName.indexOf(STATES.LISA) === 0
    ) {

      state.css[CSS_STATE_TRANSITION_FADE] = true
    }

    // Ellie allowed state transition animations

    state.css[CSS_STATE_ELLIE_NO_ANIMATION] =
      !(
        (
          (
            fromName === STATES.HOME ||
            fromName === STATES.ROOMS ||
            fromName === STATES.ROOM
          ) && (
            toName === STATES.HOME ||
            toName === STATES.ROOMS ||
            toName === STATES.ROOM
          )
        ) || (
          (
            fromName === STATES.ROOM ||
            fromName === STATES.LIGHTS
          ) && (
            toName === STATES.ROOM ||
            toName === STATES.LIGHTS
          )
        ) || (
          (
            fromName === STATES.ROOM ||
            fromName === STATES.SHADES
          ) && (
            toName === STATES.ROOM ||
            toName === STATES.SHADES
          )
        ) || (
          (
            fromName === STATES.ROOM ||
            fromName === STATES.GENERIC_DEVICES
          ) && (
            toName === STATES.ROOM ||
            toName === STATES.GENERIC_DEVICES
          )
        )
      )

    // Ellie performs very poorly with animations, disable for now
    state.css[CSS_STATE_ELLIE_NO_ANIMATION] = true
    state.css[CSS_STATE_LENA_NO_ANIMATION] = true
  }

  /**
   * @param transition
   * @param {string} fromName
   * @param {string} toName
   */
  function processPreviousState (transition, fromName, toName) {

    var audioOnly

    audioOnly = CurrentBasCore.isAudioOnly()

    if (!audioOnly) {

      if (!compareState(fromName, toName)) {

        state.previousMainState = fromName
        state.previousMainStateParams = transition.params('from')
      }

    } else if (fromName.indexOf(STATES.ROOMS) === 0) {

      state.previousMainState = fromName
      state.previousMainStateParams = transition.params('from')
    }
  }

  /**
   * Returns true if states are same main state and sub state
   *
   * @param {string} oldState
   * @param {string} newState
   * @returns {boolean}
   */
  function compareState (oldState, newState) {

    var splitOld, splitNew

    if (!BasUtil.isNEString(oldState) ||
      !BasUtil.isNEString(newState)) {

      return false
    }

    splitOld = oldState.split('.')
    splitNew = newState.split('.')

    // First check is whether main state is the same
    // Second whether sub state is the same
    return splitOld[0] === splitNew[0] && splitOld[1] === splitNew[1]
  }

  function resetCss () {

    state.css[CSS_STATE_TRANSITION_FADE] = false
    state.css[CSS_STATE_TRANSITION_IN] = false
    state.css[CSS_STATE_TRANSITION_OUT] = false
    state.css[CSS_STATE_CONTENT_TRANSITION_IN] = false
    state.css[CSS_STATE_CONTENT_TRANSITION_OUT] = false
    state.css[CSS_STATE_ELLIE_NO_ANIMATION] = true
    state.css[CSS_STATE_LENA_NO_ANIMATION] = true
  }
}
