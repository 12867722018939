'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('liveCtrl', [
    '$rootScope',
    '$scope',
    '$uiRouterGlobals',
    'STATES',
    'BAS_LIVE_ACCOUNT',
    'BasState',
    'BasLiveAccount',
    'BasLiveHelper',
    'BasCoreConnection',
    'BasAppDevice',
    liveCtrl
  ])

/**
 * @param $rootScope
 * @param $scope
 * @param $uiRouterGlobals
 * @param {STATES} STATES
 * @param {BAS_LIVE_ACCOUNT} BAS_LIVE_ACCOUNT
 * @param {BasState} BasState
 * @param {BasLiveAccount} BasLiveAccount
 * @param {BasLiveHelper} BasLiveHelper
 * @param {BasCoreConnection} BasCoreConnection
 * @param {BasAppDevice} BasAppDevice
 */
function liveCtrl (
  $rootScope,
  $scope,
  $uiRouterGlobals,
  STATES,
  BAS_LIVE_ACCOUNT,
  BasState,
  BasLiveAccount,
  BasLiveHelper,
  BasCoreConnection,
  BasAppDevice
) {
  var live = this

  var _listeners = []

  var _toVerifyCognitoUser = null
  var _toVerifyPassword = ''

  /**
   * @type {STATES}
   */
  live.STATES = STATES

  live.$uiRouterGlobals = $uiRouterGlobals

  /**
   * @type {TBasLiveAccountState}
   */
  live.basLive = BasLiveAccount.get()

  /**
   * @type {string}
   */
  live.toVerifyEmail = ''

  /**
   * @type {string}
   */
  live.uiToVerifyEmail = '-'

  live.login = login
  live.register = register
  live.logout = logout
  live.manageAccount = manageAccount
  live.changePassword = changePassword
  live.chooseHome = chooseHome
  live.continueWithout = continueWithout

  live.toVerify = toVerify
  live.getToVerifyUser = getToVerifyUser
  live.loginToVerifyUser = loginToVerifyUser

  init()

  function init () {

    $scope.$on('$destroy', _onDestroy)

    _listeners.push($rootScope.$on(
      BAS_LIVE_ACCOUNT.EVT_AUTH_STATE_CHECKED,
      _syncScope
    ))
    _listeners.push($rootScope.$on(
      BAS_LIVE_ACCOUNT.EVT_ACCOUNT_NAME_UPDATED,
      _syncScope
    ))
    _listeners.push($rootScope.$on(
      BAS_LIVE_ACCOUNT.EVT_LIVE_UI_STATE,
      _syncScope
    ))

    BasLiveAccount.toggleShowBackButton(true)
  }

  function login () {

    BasState.go(STATES.CONNECT_LIVE_LOGIN)
  }

  function register () {

    BasState.go(STATES.CONNECT_LIVE_REGISTER)
  }

  function logout () {

    BasLiveAccount.showLogoutModal()
  }

  function manageAccount () {

    if (live.basLive.isLoggedIn) {

      BasLiveAccount.openManageWindow()
    }
  }

  function changePassword () {

    if (live.basLive.isLoggedIn) {

      BasState.go(STATES.CONNECT_LIVE_CHANGE)
    }
  }

  function chooseHome () {

    BasCoreConnection.prepareChangeBasCore()
    BasState.go(STATES.CONNECT_DISCOVERY)
  }

  function continueWithout () {

    BasLiveAccount.saveContinueWithoutLive()

    if (BasAppDevice.isPossiblyCoreHosted()) {

      BasCoreConnection.start().catch(() => {})

    } else {

      BasState.go(STATES.CONNECT_DISCOVERY)
    }
  }

  /**
   * @param {(CognitoUser|string)} user
   * @param {string} [password]
   */
  function toVerify (user, password) {

    var _user, _username

    if (BasUtil.isNEString(user)) {

      _user = BasLiveAccount.getCognitoUser(user)

    } else if (BasUtil.isObject(user)) {

      _user = user
    }

    if (_user) {

      _toVerifyCognitoUser = _user
      _toVerifyPassword = BasUtil.isNEString(password) ? password : ''
    }

    if (_toVerifyCognitoUser) {

      _username = _toVerifyCognitoUser.getUsername()

      live.toVerifyEmail = _username || ''
      live.uiToVerifyEmail = _username || '-'

    } else {

      live.toVerifyEmail = ''
      live.uiToVerifyEmail = '-'
    }
  }

  /**
   * @returns {?CognitoUser}
   */
  function getToVerifyUser () {

    return _toVerifyCognitoUser
  }

  function loginToVerifyUser () {

    if (_toVerifyCognitoUser && _toVerifyPassword) {

      BasLiveAccount.login(
        _toVerifyCognitoUser.getUsername(),
        _toVerifyPassword,
        _onLogin
      )
    }
  }

  function _onLogin (error) {

    _toVerifyCognitoUser = null
    _toVerifyPassword = ''
    live.toVerifyEmail = ''
    live.uiToVerifyEmail = '-'

    if (error) {

      BasState.go(STATES.CONNECT_LIVE_LOGIN)

    } else {

      BasLiveHelper.onLiveLoginSuccess()
    }
  }

  function _syncScope () {

    $scope.$applyAsync()
  }

  function _onDestroy () {

    _toVerifyCognitoUser = null
    _toVerifyPassword = ''
    live.toVerifyEmail = ''
    live.uiToVerifyEmail = '-'

    BasUtil.executeArray(_listeners)
    _listeners = []
  }
}
