'use strict'

/**
 * @constant {string}
 */
exports.H_X_BASALTE_API = 'x-basalte-api'

/**
 * @constant {string}
 */
exports.H_X_BASALTE_METHOD = 'X-Basalte-Method'

/**
 * @constant {string}
 */
exports.H_CONTENT_TYPE = 'content-type'

/**
 * @constant {string}
 */
exports.H_ACCEPT = 'accept'

/**
 * @constant {string}
 */
exports.V_IMAGE_JPEG = 'image/jpeg'

/**
 * @constant {string}
 */
exports.ID = 'id'

/**
 * @constant {string}
 */
exports.CHUNK_ID = 'chunkId'

/**
 * @constant {string}
 */
exports.CHUNK_SEQ = 'chunkSeq'

/**
 * @constant {string}
 */
exports.CHUNK_SEQ_MAX = 'chunkSeqMax'

/**
 * @constant {string}
 */
exports.CHUNK = 'chunk'

/**
 * @constant {string}
 */
exports.REQUEST = 'request'

/**
 * @constant {string}
 */
exports.ENCODING = 'encoding'

/**
 * @constant {string}
 */
exports.DESCRIBE_ZONE = 'describeZone'

/**
 * @constant {string}
 */
exports.INIT_POS = 'initPos'

/**
 * @constant {string}
 */
exports.PATH = 'path'

/**
 * @constant {string}
 */
exports.HEADERS = 'headers'

/**
 * @constant {string}
 */
exports.SHARED = 'shared'

/**
 * @constant {string}
 */
exports.UUID = 'uuid'

/**
 * @constant {string}
 */
exports.UUIDS = 'uuids'

/**
 * @constant {string}
 */
exports.DESCRIBE = 'describe'

/**
 * @constant {string}
 */
exports.DESCRIBE_DSP = 'describeDSP'

/**
 * @constant {string}
 */
exports.PING = 'ping'

/**
 * @constant {string}
 */
exports.PONG = 'pong'

/**
 * @constant {string}
 */
exports.TYPE = 'type'

/**
 * @constant {string}
 */
exports.ORIGIN = 'origin'

/**
 * @constant {string}
 */
exports.LOGOUT = 'logout'

/**
 * @constant {string}
 */
exports.TRACK = 'track'

/**
 * @constant {string}
 */
exports.TRACK_LIST = 'trackList'

/**
 * @constant {string}
 */
exports.FILTER = 'filter'

/**
 * @constant {string}
 */
exports.BAS_TYPE = 'basType'

/**
 * @constant {string}
 */
exports.EXTRA_TYPE = 'extraType'

/**
 * @constant {string}
 */
exports.SUB_TYPE = 'subType'

/**
 * @constant {string}
 */
exports.SUBTYPE = 'subtype'

/**
 * @constant {string}
 */
exports.PASS_FILTERS = 'passFilters'

/**
 * @constant {string}
 */
exports.SHELF_FILTERS = 'shelfFilters'

/**
 * @constant {string}
 */
exports.EQUALISERS = 'equalisers'

/**
 * @constant {string}
 */
exports.GAIN = 'gain'

/**
 * @constant {string}
 */
exports.FREQUENCY = 'frequency'

/**
 * @constant {string}
 */
exports.CONFIG = 'config'

/**
 * @constant {string}
 */
exports.VERSION = 'version'

/**
 * @constant {string}
 */
exports.APP = 'app'

/**
 * @constant {string}
 */
exports.MUSIC = 'music'

/**
 * @constant {string}
 */
exports.PARAMS = 'params'

/**
 * @constant {string}
 */
exports.TIMEOUT = 'timeout'

/**
 * @constant {string}
 */
exports.FROM = 'from'

/**
 * @constant {string}
 */
exports.TO = 'to'

/**
 * @constant {string}
 */
exports.U = 'u'

/**
 * @constant {string}
 */
exports.T = 't'

/**
 * @constant {string}
 */
exports.CN = 'cn'

/**
 * @constant {string}
 */
exports.H = 'h'

/**
 * @constant {string}
 */
exports.USER = 'user'

/**
 * @constant {string}
 */
exports.JWT = 'jwt'

/**
 * @constant {string}
 */
exports.LIVE = 'live'

/**
 * @constant {string}
 */
exports.HASH = 'hash'

/**
 * @constant {string}
 */
exports.SALT = 'salt'

/**
 * @constant {string}
 */
exports.CHALLENGE = 'challenge'

/**
 * @constant {string}
 */
exports.CNONCE = 'cnonce'

/**
 * @constant {string}
 */
exports.TOKEN = 'token'

/**
 * @constant {string}
 */
exports.CORE_NAME = 'coreName'

/**
 * @constant {string}
 */
exports.OWNER = 'owner'

/**
 * @constant {string}
 */
exports.OWNER_EMAIL = 'ownerEmail'

/**
 * @constant {string}
 */
exports.PROJECT_UUID = 'project_uuid'

/**
 * @constant {string}
 */
exports.STATUS_CODE = 'statusCode'

/**
 * @constant {string}
 */
exports.COMPANY = 'company'

/**
 * @constant {string}
 */
exports.PHONE = 'phone'

/**
 * @constant {string}
 */
exports.LINK_REQUEST = 'linkRequest'

/**
 * @constant {string}
 */
exports.UNLINK_REQUEST = 'unlinkRequest'

/**
 * @constant {string}
 */
exports.MASTER = 'master'

/**
 * @constant {string}
 */
exports.SOURCE = 'source'

/**
 * @constant {string}
 */
exports.SOURCES = 'sources'

/**
 * @constant {string}
 */
exports.DEVICES = 'devices'

/**
 * @constant {string}
 */
exports.AUDIO_OUTPUT = 'audioOutput'

/**
 * @constant {string}
 */
exports.PLAYER = 'player'

/**
 * @constant {string}
 */
exports.BARP = 'barp'

/**
 * @constant {string}
 */
exports.EXTERNAL = 'external'

/**
 * @constant {string}
 */
exports.NOTIFICATION = 'notification'

/**
 * @constant {string}
 */
exports.BLUETOOTH = 'bluetooth'

/**
 * @constant {string}
 */
exports.GROUP = 'group'

/**
 * @constant {string}
 */
exports.GROUPS = 'groups'

/**
 * @constant {string}
 */
exports.ZONE = 'zone'

/**
 * @constant {string}
 */
exports.ZONES = 'zones'

/**
 * @constant {string}
 */
exports.LEVEL = 'level'

/**
 * @constant {string}
 */
exports.IMAGES = 'images'

/**
 * @constant {string}
 */
exports.SET_IMAGE = 'setImage'

/**
 * @constant {string}
 */
exports.CLEAR_IMAGE = 'clearImage'

/**
 * @constant {string}
 */
exports.PARENT = 'parent'

/**
 * @constant {string}
 */
exports.ORDER = 'order'

/**
 * @constant {string}
 */
exports.TAGS = 'tags'

/**
 * @constant {string}
 */
exports.FUNCTIONS = 'functions'

/**
 * @constant {string}
 */
exports.AUDIO = 'audio'

/**
 * @constant {string}
 */
exports.SCENES = 'scenes'

/**
 * @constant {string}
 */
exports.THERMOSTAT = 'thermostat'

/**
 * @constant {string}
 */
exports.SCENE_CONTROLLER = 'sceneController'

/**
 * @constant {string}
 */
exports.TIMER = 'timer'

/**
 * @constant {string}
 */
exports.TIMERS = 'timers'

/**
 * @constant {string}
 */
exports.LIGHTS = 'lights'

/**
 * @constant {string}
 */
exports.LIGHT = 'light'

/**
 * @constant {string}
 */
exports.WINDOW_TREATMENTS = 'windowTreatments'

/**
 * @constant {string}
 */
exports.SECURITY = 'security'

/**
 * @constant {string}
 */
exports.CAMERA = 'camera'

/**
 * @constant {string}
 */
exports.CAMERAS = 'cameras'

/**
 * @constant {string}
 */
exports.ENERGY_METER = 'energyMeter'

/**
 * @constant {string}
 */
exports.ENERGY = 'energy'

/**
 * @constant {string}
 */
exports.GAS = 'gas'

/**
 * @constant {string}
 */
exports.WATER = 'water'

/**
 * @constant {string}
 */
exports.CONSUMING = 'consuming'

/**
 * @constant {string}
 */
exports.TIMESTAMP = 'timestamp'

/**
 * @constant {string}
 */
exports.GET_DATA = 'getData'

/**
 * @constant {string}
 */
exports.GET_AGGREGATE_DATA = 'getAggregateData'

/**
 * @constant {string}
 */
exports.START = 'start'

/**
 * @constant {string}
 */
exports.DATA_RAW = 'data_raw'

/**
 * @constant {string}
 */
exports.DATA_BY_10MIN = 'data_by_10min'

/**
 * @constant {string}
 */
exports.DATA_BY_HOUR = 'data_by_hour'

/**
 * @constant {string}
 */
exports.DATA_BY_DAY = 'data_by_day'

/**
 * @constant {string}
 */
exports.WINDOW_TREATMENT = 'windowTreatment'

/**
 * @constant {string}
 */
exports.WEATHER_STATION = 'weatherStation'

/**
 * @constant {string}
 */
exports.AIR_PRESSURE = 'airPressure'

/**
 * @constant {string}
 */
exports.COLOUR = 'colour'

/**
 * @constant {string}
 */
exports.TEMPLATE = 'template'

/**
 * @constant {string}
 */
exports.DSP = 'dsp'

/**
 * @constant {string}
 */
exports.STARTUP_VOLUME = 'startupVolume'

/**
 * @constant {string}
 */
exports.DSP_SUPPORT = 'dspSupport'

/**
 * @constant {string}
 */
exports.BASS = 'bass'

/**
 * @constant {string}
 */
exports.TREBLE = 'treble'

/**
 * @constant {string}
 */
exports.STEREO_WIDENING = 'stereoWidening'

/**
 * @constant {string}
 */
exports.SETTINGS = 'settings'

/**
 * @constant {string}
 */
exports.INTEGRATOR_ACCESS = 'integratorAccess'

/**
 * @constant {string}
 */
exports.INTEGRATOR_INFO = 'integratorInfo'

/**
 * @constant {string}
 */
exports.MESSAGE = 'message'

/**
 * @constant {string}
 */
exports.MESSAGES = 'messages'

/**
 * @constant {string}
 */
exports.USER_MESSAGE = 'userMessage'

/**
 * @constant {string}
 */
exports.BASE64 = 'base64'

/**
 * @constant {string}
 */
exports.SYSTEM = 'system'

/**
 * @constant {string}
 */
exports.SYSTEM_UPPER_CASE = 'SYSTEM'

/**
 * @constant {string}
 */
exports.SETTINGS = 'settings'

/**
 * @constant {string}
 */
exports.INTEGRATOR_ACCESS = 'integratorAccess'

/**
 * @constant {string}
 */
exports.SERVER = 'server'

/**
 * @constant {string}
 */
exports.ROOM = 'room'

/**
 * @constant {string}
 */
exports.ROOMS = 'rooms'

/**
 * @constant {string}
 */
exports.DEVICE = 'device'

/**
 * @constant {string}
 */
exports.ERROR = 'error'

/**
 * @constant {string}
 */
exports.ERRORS = 'errors'

/**
 * @constant {string}
 */
exports.CMD = 'cmd'

/**
 * @constant {string}
 */
exports.ARGS = 'args'

/**
 * @constant {string}
 */
exports.RESPONSE = 'response'

/**
 * @constant {string}
 */
exports.FAVOURITES_VERSION = 'favouritesVersion'

/**
 * @constant {string}
 */
exports.COVERART_IS_LOCAL = 'coverartIsLocal'

/**
 * @constant {string}
 */
exports.SET_VOLUME = 'setVolume'

/**
 * @constant {string}
 */
exports.SET_MUTED = 'setMuted'

/**
 * @constant {string}
 */
exports.SKIP_NEXT = 'skipNext'

/**
 * @constant {string}
 */
exports.SKIP_PREVIOUS = 'skipPrevious'

/**
 * @constant {string}
 */
exports.LIST_FAVOURITES = 'listFavourites'

/**
 * @constant {string}
 */
exports.SET_SHUFFLED = 'setShuffled'

/**
 * @constant {string}
 */
exports.SHUFFLED = 'shuffled'

/**
 * @constant {string}
 */
exports.SEEK_TO = 'seekTo'

/**
 * @constant {string}
 */
exports.SET_REPEAT_MODE = 'setRepeatMode'

/**
 * @constant {string}
 */
exports.PLAY_FAVOURITE = 'playFavourite'

/**
 * @constant {string}
 */
exports.QUEUE_TYPE = 'queue_type'

/**
 * @constant {string}
 */
exports.QUEUE = 'queue'

/**
 * @constant {string}
 */
exports.SEQ = 'seq'

/**
 * @constant {string}
 */
exports.ELAPSED = 'elapsed'

/**
 * @constant {string}
 */
exports.TOTAL = 'total'

/**
 * @constant {string}
 */
exports.CONSUME = 'consume'

/**
 * @constant {string}
 */
exports.SINGLE = 'single'

/**
 * @constant {string}
 */
exports.RANDOM = 'random'

/**
 * @constant {string}
 */
exports.REPEAT = 'repeat'

/**
 * @constant {string}
 */
exports.REPEAT_MODE = 'repeatMode'

/**
 * @constant {string}
 */
exports.RELATIVE_SEEK = 'relativeSeek'

/**
 * @constant {string}
 */
exports.RELAY = 'relay'

/**
 * @constant {string}
 */
exports.SECONDS = 'seconds'

/**
 * @constant {string}
 */
exports.STATE = 'state'

/**
 * @constant {string}
 */
exports.LABEL = 'label'

/**
 * @constant {string}
 */
exports.CONTROLS = 'controls'

/**
 * @constant {string}
 */
exports.CONTROL = 'control'

/**
 * @constant {string}
 */
exports.MUTED = 'muted'

/**
 * @constant {string}
 */
exports.PLAY = 'play'

/**
 * @constant {string}
 */
exports.PAUSE = 'pause'

/**
 * @constant {string}
 */
exports.PLAYPAUSE = 'playpause'

/**
 * @constant {string}
 */
exports.STOP = 'stop'

/**
 * @constant {string}
 */
exports.STEP = 'step'

/**
 * @constant {string}
 */
exports.STORAGE = 'storage'

/**
 * @constant {string}
 */
exports.SONG = 'song'

/**
 * @constant {string}
 */
exports.SONGS = 'songs'

/**
 * @constant {string}
 */
exports.PLAYLISTS = 'playlists'

/**
 * @constant {string}
 */
exports.ALBUMS = 'albums'

/**
 * @constant {string}
 */
exports.ARTISTS = 'artists'

/**
 * @constant {string}
 */
exports.RECENTLY = 'recently'

/**
 * @constant {string}
 */
exports.SIZE = 'size'

/**
 * @constant {string}
 */
exports.GENRES = 'genres'

/**
 * @constant {string}
 */
exports.TUNEIN = 'tunein'

/**
 * @constant {string}
 */
exports.PAGE = 'page'

/**
 * @constant {string}
 */
exports.THUMB = 'thumb'

/**
 * @constant {string}
 */
exports.COVER = 'cover'

/**
 * @constant {string}
 */
exports.LIST_DEFAULT_ROOMS = 'listDefaultRooms'

/**
 * @constant {string}
 */
exports.DEFAULT_ROOMS_CHANGED = 'defaultRoomsChanged'

/**
 * @constant {string}
 */
exports.PRIO = 'prio'

/**
 * @constant {string}
 */
exports.PROFILE = 'profile'

/**
 * @constant {string}
 */
exports.FILE = 'file'

/**
 * @constant {string}
 */
exports.CHARTS = 'charts'

/**
 * @constant {string}
 */
exports.FEATURED = 'featured'

/**
 * @constant {string}
 */
exports.RELEASE = 'release'

/**
 * @constant {string}
 */
exports.RELEASES = 'releases'

/**
 * @constant {string}
 */
exports.OPTION = 'option'

/**
 * @constant {string}
 */
exports.OVERVIEW = 'overview'

/**
 * @constant {string}
 */
exports.APPEND = 'append'

/**
 * @constant {string}
 */
exports.NEXT = 'next'

/**
 * @constant {string}
 */
exports.NOW = 'now'

/**
 * @constant {string}
 */
exports.END = 'end'

/**
 * @constant {string}
 */
exports.REPLACE_NOW = 'replaceNow'

/**
 * @constant {string}
 */
exports.REPLACE = 'replace'

/**
 * @constant {string}
 */
exports.PREVIOUS = 'previous'

/**
 * @constant {string}
 */
exports.DATABASE = 'database'

/**
 * @constant {string}
 */
exports.PLAYLIST = 'playlist'

/**
 * @constant {string}
 */
exports.RADIO = 'radio'

/**
 * @constant {string}
 */
exports.FLOW = 'flow'

/**
 * @constant {string}
 */
exports.CHANGED = 'changed'

/**
 * @constant {string}
 */
exports.UPDATING = 'updating'

/**
 * @constant {string}
 */
exports.FAVOURITE = 'favourite'

/**
 * @constant {string}
 */
exports.FAVOURITES = 'favourites'

/**
 * @constant {string}
 */
exports.STATUS = 'status'

/**
 * @constant {string}
 */
exports.SUB_STATUS = 'subStatus'

/**
 * @constant {string}
 */
exports.ACTION = 'action'

/**
 * @constant {string}
 */
exports.ADDRESSES = 'addresses'

/**
 * @constant {string}
 */
exports.NEW = 'new'

/**
 * @constant {string}
 */
exports.OK = 'ok'

/**
 * @constant {string}
 */
exports.FORBIDDEN = 'Forbidden'

/**
 * @constant {string}
 */
exports.RENAME = 'rename'

/**
 * @constant {string}
 */
exports.SHARE = 'share'

/**
 * @constant {string}
 */
exports.CHANGES = 'changes'

/**
 * @constant {string}
 */
exports.DELETE_SONG_POS = 'delete_song_pos'

/**
 * @constant {string}
 */
exports.MOVE_SONG_POS = 'move_song_pos'

/**
 * @constant {string}
 */
exports.REMOVE = 'remove'

/**
 * @constant {string}
 */
exports.ADD_PLAYLIST = 'addPlaylist'

/**
 * @constant {string}
 */
exports.ADD_RADIO_STATION = 'addRadioStation'

/**
 * @constant {string}
 */
exports.ADD_DEEZER = 'addDeezer'

/**
 * @constant {string}
 */
exports.ADD_TIDAL = 'addTidal'

/**
 * @constant {string}
 */
exports.LINK = 'link'

/**
 * @constant {string}
 */
exports.DETAIL = 'detail'

/**
 * @constant {string}
 */
exports.CONTENT = 'content'

/**
 * @constant {string}
 */
exports.NL = 'nl'

/**
 * @constant {string}
 */
exports.RU = 'ru'

/**
 * @constant {string}
 */
exports.PL = 'pl'

/**
 * @constant {string}
 */
exports.ES = 'es'

/**
 * @constant {string}
 */
exports.DE = 'de'

/**
 * @constant {string}
 */
exports.FR = 'fr'

/**
 * @constant {string}
 */
exports.EN = 'en'

/**
 * @constant {string}
 */
exports.V_WILDCARD = '*'

/**
 * @constant {string}
 */
exports.OPEN_CLOSE = 'openClose'

/**
 * @constant {string}
 */
exports.AUTO_MODE = 'autoMode'

/**
 * @constant {string}
 */
exports.FAVOURITE_EXISTS = 'favouriteExists'

/**
 * @constant {string}
 */
exports.PLAYLIST_EXISTS = 'playlistExists'

/**
 * @constant {string}
 */
exports.INVALID_PLAYLIST = 'invalidPlaylist'

/**
 * @constant {string}
 */
exports.INVERTED = 'inverted'

/**
 * @constant {string}
 */
exports.TRUE = 'true'

/**
 * @constant {string}
 */
exports.FALSE = 'false'

/**
 * @constant {string}
 */
exports.PERMISSION_DENIED = 'permissionDenied'

/**
 * @constant {string}
 */
exports.SUCCESS = 'success'

/**
 * @constant {string}
 */
exports.CONNECTED = 'connected'

/**
 * @constant {string}
 */
exports.CLIENT_NAME = 'clientName'

/**
 * @constant {string}
 */
exports.PAIRING = 'pairing'

/**
 * @constant {string}
 */
exports.TRACKS = 'tracks'

/**
 * @constant {string}
 */
exports.PAUSED = 'paused'

/**
 * @constant {string}
 */
exports.PLAYING = 'playing'

/**
 * @constant {string}
 */
exports.TITLE = 'title'

/**
 * @constant {string}
 */
exports.BODY = 'body'

/**
 * @constant {string}
 */
exports.ARTIST = 'artist'

/**
 * @constant {string}
 */
exports.ALBUM = 'album'

/**
 * @constant {string}
 */
exports.CONTEXT = 'context'

/**
 * @constant {string}
 */
exports.CONTEXT_URI = 'contextURI'

/**
 * @constant {string}
 */
exports.COVERART = 'coverart'

/**
 * @constant {string}
 */
exports.LENGTH = 'length'

/**
 * @constant {string}
 */
exports.PRESET = 'preset'

/**
 * @constant {string}
 */
exports.PRECISION = 'precision'

/**
 * @constant {string}
 */
exports.URI = 'uri'

/**
 * @constant {string}
 */
exports.OPTIONS = 'options'

/**
 * @constant {string}
 */
exports.LIST = 'list'

/**
 * @constant {string}
 */
exports.SAVE = 'save'

/**
 * @constant {string}
 */
exports.LOAD = 'load'

/**
 * @constant {string}
 */
exports.CLEAR = 'clear'

/**
 * @constant {string}
 */
exports.OFFSET = 'offset'

/**
 * @constant {string}
 */
exports.LINK_URL = 'linkUrl'

/**
 * @constant {string}
 */
exports.LINK_DISCONNECT = 'linkDisconnect'

/**
 * @constant {string}
 */
exports.LINK_RESTORE = 'linkRestore'

/**
 * @constant {string}
 */
exports.UNLINK = 'unlink'

/**
 * @constant {string}
 */
exports.LINK_FINISHED = 'linkFinished'

/**
 * @constant {string}
 */
exports.LINK_ERROR = 'linkError'

/**
 * @constant {string}
 */
exports.LINK_SUCCESS = 'linkSuccess'

/**
 * @constant {string}
 */
exports.LINK_ERROR_UNKNOWN = 'linkErrorUnknown'

/**
 * @constant {string}
 */
exports.LINK_ERROR_USER_DENIED = 'user_denied'

/**
 * @constant {string}
 */
exports.LINK_ERROR_TOKEN = 'token_error'

/**
 * @constant {string}
 */
exports.LINK_ERROR_UNKNOWN_ERROR = 'unknown_error'

/**
 * @constant {string}
 */
exports.DEEZER = 'deezer'

/**
 * @constant {string}
 */
exports.TIDAL = 'tidal'

/**
 * @constant {string}
 */

exports.ACCESS_TOKEN = 'accessToken'

/**
 * @constant {string}
 */
exports.LOGIN = 'login'

/**
 * @constant {string}
 */
exports.COUNTRY_CODE = 'countryCode'

/**
 * @constant {string}
 */
exports.SESSION_ID = 'sessionId'

/**
 * @constant {string}
 */
exports.SESSION = 'session'

/**
 * @constant {string}
 */
exports.USER_ID = 'userId'

/**
 * @constant {string}
 */
exports.BUILDING = 'building'

/**
 * @constant {string}
 */
exports.FLOOR = 'floor'

/**
 * @constant {string}
 */
exports.LINKED = 'linked'

/**
 * @constant {string}
 */
exports.NAME = 'name'

/**
 * @constant {string}
 */
exports.NAME_TYPE = 'nameType'

/**
 * @constant {number}
 */
exports.CHANNELMODE = 'channelMode'

/**
 * @constant {string}
 */
exports.ON_OFF = 'onOff'

/**
 * @constant {string}
 */
exports.PERCENTAGE = 'percentage'

/**
 * @constant {string}
 */
exports.BOOLEAN = 'boolean'

/**
 * @constant {string}
 */
exports.STRING = 'string'

/**
 * @constant {string}
 */
exports.GID = 'gid'

/**
 * @constant {string}
 */
exports.DEVICE_TYPE = 'deviceType'

/**
 * @constant {string}
 */
exports.CTRL_TYPE = 'ctrlType'

/**
 * @constant {string}
 */
exports.AUDIO_OUTPUTS = 'audioOutputs'

/**
 * @constant {string}
 */
exports.USERNAME = 'username'

/**
 * @constant {string}
 */
exports.LOGIN_OK = 'LOGIN OK'

/**
 * @constant {string}
 */
exports.PASSWORD = 'password'

/**
 * @constant {string}
 */
exports.ADMIN = 'admin'

/**
 * @constant {string}
 */
exports.RTSP = 'rtsp'

/**
 * @constant {string}
 */
exports.MJPEG = 'mjpeg'

/**
 * @constant {string}
 */
exports.RTSP_REMOTE = 'rtspRemote'

/**
 * @constant {string}
 */
exports.MJPEG_REMOTE = 'mjpegRemote'

/**
 * @constant {string}
 */
exports.ALARM = 'alarm'

/**
 * @constant {string}
 */
exports.ALARMS = 'alarms'

/**
 * @constant {string}
 */
exports.AVATAR = 'avatar'

/**
 * @constant {string}
 */
exports.AVATAR_URL = 'avatarUrl'

/**
 * @constant {string}
 */
exports.DEFAULT = 'default'

/**
 * @constant {string}
 */
exports.VOLUME = 'volume'

/**
 * @constant {string}
 */
exports.DATETIME = 'datetime'

/**
 * @constant {string}
 */
exports.SUNRISE = 'sunrise'

/**
 * @constant {string}
 */
exports.SUNSET = 'sunset'

/**
 * @constant {string}
 */
exports.RESULT = 'result'

/**
 * @constant {string}
 */
exports.NO_PERMISSION = 'noPermission'

/**
 * @constant {string}
 */
exports.AUDIO_ONLY = 'audioOnly'

/**
 * @constant {string}
 */
exports.SINGLE_ROOM = 'singleRoom'

/**
 * @constant {string}
 */
exports.SINGLE_SOURCE = 'singleSource'

/**
 * @constant {string}
 */
exports.CITY = 'city'

/**
 * @constant {string}
 */
exports.COUNTRY = 'country'

/**
 * @constant {string}
 */
exports.LATITUDE = 'latitude'

/**
 * @constant {string}
 */
exports.LONGITUDE = 'longitude'

/**
 * @constant {string}
 */
exports.LOCALE = 'locale'

/**
 * @constant {string}
 */
exports.TIMEZONE = 'timezone'

/**
 * @constant {string}
 */
exports.CHECKING_FOR_UPDATES = 'checkingForUpdates'

/**
 * @constant {string}
 */
exports.DOWNLOADING_UPDATES = 'downloadingUpdates'

/**
 * @constant {string}
 */
exports.HAS_UPDATE = 'hasUpdate'

/**
 * @constant {string}
 */
exports.STREAM = 'stream'

/**
 * @constant {string}
 */
exports.URL = 'url'

/**
 * @constant {string}
 */
exports.DATA = 'data'

/**
 * @constant {string}
 */
exports.SCHEME = 'scheme'

/**
 * @constant {string}
 */
exports.METHOD = 'method'

/**
 * @constant {string}
 */
exports.GET = 'get'

/**
 * @constant {string}
 */
exports.C_GET = 'GET'

/**
 * @constant {string}
 */
exports.C_POST = 'POST'

/**
 * @constant {string}
 */
exports.JSON = 'json'

/**
 * @constant {string}
 */
exports.EVENT = 'event'

/**
 * @constant {string}
 */
exports.DEFAULT_ROOMS = 'defaultRooms'

/**
 * @constant {string}
 */
exports.DEFAULT_ROOM = 'defaultRoom'

/**
 * @constant {string}
 */
exports.REACHABLE = 'reachable'

/**
 * @constant {string}
 */
exports.LIMIT = 'limit'

/**
 * @constant {string}
 */
exports.ON = 'on'

/**
 * @constant {string}
 */
exports.VALUE = 'value'

/**
 * @constant {string}
 */
exports.CAN_STOP = 'canStop'

/**
 * @constant {string}
 */
exports.SUPPORTS_INTERMEDIATE = 'supportsIntermediate'

/**
 * @constant {string}
 */
exports.BRIGHTNESS = 'brightness'

/**
 * @constant {string}
 */
exports.UNKNOWN = 'unknown'

/**
 * @constant {string}
 */
exports.WHITE = 'white'

/**
 * @constant {string}
 */
exports.COLOR = 'color'

/**
 * @constant {string}
 */
exports.COLOR_TEMPERATURE = 'colorTemperature'

/**
 * @constant {string}
 */
exports.MIN = 'min'

/**
 * @constant {string}
 */
exports.MAX = 'max'

/**
 * @constant {string}
 */
exports.HUE = 'hue'

/**
 * @constant {string}
 */
exports.SATURATION = 'saturation'

/**
 * @constant {string}
 */
exports.MODE = 'mode'

/**
 * @constant {string}
 */
exports.NOW_PLAYING = 'nowPlaying'

/**
 * @constant {string}
 */
exports.PLAY_PAUSE = 'playPause'

/**
 * @constant {string}
 */
exports.REPEAT_TRACK = 'repeatTrack'

/**
 * @constant {string}
 */
exports.SHUFFLE = 'shuffle'

/**
 * @constant {string}
 */
exports.INTERNAL = 'internal'

/**
 * @constant {string}
 */
exports.INTERCOM = 'intercom'

/**
 * @constant {string}
 */
exports.KNX = 'knx'

/**
 * @constant {string}
 */
exports.KEYS = 'keys'

/**
 * @constant {string}
 */
exports.SCENE = 'scene'

/**
 * @constant {string}
 */
exports.TIME = 'time'

/**
 * @constant {string}
 */
exports.ARMED = 'armed'

/**
 * @constant {string}
 */
exports.JOB = 'job'

/**
 * @constant {string}
 */
exports.SCENES = 'scenes'

/**
 * @constant {string}
 */
exports.JOBS = 'jobs'

/**
 * @constant {string}
 */
exports.ACTIVATE = 'activate'

/**
 * @constant {string}
 */
exports.ACTIVE = 'active'

/**
 * @constant {string}
 */
exports.ASANO = 'asano'

/**
 * @constant {string}
 */
exports.SONOS = 'sonos'

/**
 * @constant {string}
 */
exports.LEARN = 'learn'

/**
 * @constant {string}
 */
exports.ADD = 'add'

/**
 * @constant {string}
 */
exports.MODIFY = 'modify'

/**
 * @constant {string}
 */
exports.DELETE = 'delete'

/**
 * @constant {string}
 */
exports.MOVEID = 'moveid'

/**
 * @constant {string}
 */
exports.DEEZER_ID = 'deezerId'

/**
 * @constant {string}
 */
exports.TIDAL_ID = 'tidalId'

/**
 * @constant {string}
 */
exports.TUNEIN_GID = 'tunein_gid'

/**
 * @constant {string}
 */
exports.DEEZER__ID = 'deezer_id'

/**
 * @constant {string}
 */
exports.TIDAL__ID = 'tidal_id'

/**
 * @constant {string}
 */
exports.SPOTIFY_PRESET = 'spotify_preset'

/**
 * @constant {string}
 */
exports.TUNEIN_OTHER_RADIOS = 'tunein_other_radios'

/**
 * @constant {string}
 */
exports.RADIOS = 'radios'

/**
 * @constant {string}
 */
exports.DIRECTORY = 'directory'

/**
 * @constant {string}
 */
exports.USERID = 'userid'

/**
 * @constant {string}
 */
exports.POS = 'pos'

/**
 * @constant {string}
 */
exports.CONTENT_SRC = 'content_src'

/**
 * @constant {string}
 */
exports.CONTENT_SRC_LOCAL = 'local'

/**
 * @constant {string}
 */
exports.CONTENT_SRC_DEEZER = 'deezer'

/**
 * @constant {string}
 */
exports.CONTENT_SRC_TIDAL = 'tidal'

/**
 * @constant {string}
 */
exports.CONTENT_SRC_UNKNOWN = 'unknown'

/**
 * @constant {string}
 */
exports.MAC = 'mac'

/**
 * @constant {string}
 */
exports.IP = 'ip'

/**
 * @constant {string}
 */
exports.HOST = 'host'

/**
 * @constant {string}
 */
exports.PORT = 'port'

/**
 * @constant {string}
 */
exports.SPOTIFY = 'spotify'

/**
 * @constant {string}
 */
exports.UPDATE = 'update'

/**
 * @constant {string}
 */
exports.NONE = 'none'

/**
 * @constant {string}
 */
exports.CHECKING = 'checking'

/**
 * @constant {string}
 */
exports.DOWNLOADING = 'downloading'

/**
 * @constant {string}
 */
exports.READY = 'ready'

/**
 * @constant {string}
 */
exports.CID = 'cid'

/**
 * @constant {string}
 */
exports.LVL = 'lvl'

/**
 * @constant {string}
 */
exports.COMPONENT = 'component'

/**
 * @constant {string}
 */
exports.CHECK_FOR_UPDATES = 'checkForUpdates'

/**
 * @constant {string}
 */
exports.RESTART = 'restart'

/**
 * @constant {string}
 */
exports.R = 'r'

/**
 * @constant {string}
 */
exports.W = 'w'

/**
 * @constant {string}
 */
exports.X = 'x'

/**
 * @constant {string}
 */
exports.LOCATION = 'location'

/**
 * @constant {string}
 */
exports.CAPABILITIES = 'capabilities'

/**
 * @constant {string}
 */
exports.TOGGLE = 'toggle'

/**
 * @constant {string}
 */
exports.SLIDER = 'slider'

/**
 * @constant {string}
 */
exports.TRIGGER = 'trigger'

/**
 * @constant {string}
 */
exports.NUMBER = 'number'

/**
 * @constant {string}
 */
exports.ATTRIBUTES = 'attributes'

/**
 * @constant {string}
 */
exports.LABELS = 'labels'

/**
 * @constant {string}
 */
exports.OPEN = 'open'

/**
 * @constant {string}
 */
exports.CLOSE = 'close'

/**
 * @constant {string}
 */
exports.POSITION = 'position'

/**
 * @constant {string}
 */
exports.POSITIONS = 'positions'

/**
 * @constant {string}
 */
exports.ROTATION = 'rotation'

/**
 * @constant {string}
 */
exports.ELLIE = 'ellie'

/**
 * @constant {string}
 */
exports.LISA = 'lisa'

/**
 * @constant {string}
 */
exports.LENA = 'lena'

/**
 * @constant {string}
 */
exports.BUTTONS = 'buttons'

/**
 * @constant {string}
 */
exports.DOORBIRD = 'doorbird'

/**
 * @constant {string}
 */
exports.DOOR_PHONE_2N = '2n'

/**
 * @constant {string}
 */
exports.DOOR_PHONE_SCHUCO = 'schüco'

/**
 * @constant {string}
 */
exports.DOOR_PHONE_GATEWAY = 'doorPhoneGateway'

/**
 * @constant {string}
 */
exports.DOOR_PHONE_IDENTIFIER = 'doorPhoneIdentifier'

/**
 * @constant {string}
 */
exports.DOOR_PHONE = 'doorPhone'

/**
 * @constant {string}
 */
exports.MUTE_AREA_ON_CALL = 'muteAreaOnCall'

/**
 * @constant {string}
 */
exports.GENERIC = 'generic'

/**
 * @constant {string}
 */
exports.GENERIC_V2 = 'generic_v2'

/**
 * @constant {string}
 */
exports.WIND = 'wind'

/**
 * @constant {string}
 */
exports.WIND_SPEED = 'windSpeed'

/**
 * @constant {string}
 */
exports.WIND_DIRECTION = 'windDirection'

/**
 * @constant {string}
 */
exports.UV = 'uv'

/**
 * @constant {string}
 */
exports.PRECIPITATION = 'precipitation'

/**
 * @constant {string}
 */
exports.FROST = 'frost'

/**
 * @constant {string}
 */
exports.RAIN = 'rain'

/**
 * @constant {string}
 */
exports.SIEDLE = 'siedle'

/**
 * @constant {string}
 */
exports.GIRA = 'gira'

/**
 * @constant {string}
 */
exports.RESIDIUM = 'residium'

/**
 * @constant {string}
 */
exports.GATEWAY = 'gateway'

/**
 * @constant {string}
 */
exports.CALL_STATE = 'callState'

/**
 * @constant {string}
 */
exports.CALLER = 'caller'

/**
 * @constant {string}
 */
exports.CALL_STATIONS = 'callStations'

/**
 * @constant {string}
 */
exports.IDLE = 'idle'

/**
 * @constant {string}
 */
exports.INCOMING = 'incoming'

/**
 * @constant {string}
 */
exports.ONGOING = 'ongoing'

/**
 * @constant {string}
 */
exports.DIRECT_DIAL_NUMBER = 'directDialNumber'

/**
 * @constant {string}
 */
exports.DTMF = 'dtmf'

/**
 * @constant {string}
 */
exports.DOOR = 'door'

/**
 * @constant {string}
 */
exports.TEMPERATURE = 'temperature'

/**
 * @constant {string}
 */
exports.HUMIDITY = 'humidity'

/**
 * @constant {string}
 */
exports.SETPOINT = 'setpoint'

/**
 * @constant {string}
 */
exports.FAN_MODE = 'fanMode'

/**
 * @constant {string}
 */
exports.THERMOSTAT_MODE = 'thermostatMode'

/**
 * @constant {string}
 */
exports.HEAT_COOL_MODE = 'heatCoolMode'

/**
 * @constant {string}
 */
exports.LOUVER_MODE = 'louverMode'

/**
 * @constant {string}
 */
exports.COOLING_ACTIVE = 'coolingActive'

/**
 * @constant {string}
 */
exports.SCHEDULER = 'scheduler'

/**
 * @constant {string}
 */
exports.SCHEDULER_V2 = 'schedulerV2'

/**
 * @constant {string}
 */
exports.HEATING_ACTIVE = 'heatingActive'

/**
 * @constant {string}
 */
exports.HEATING = 'heating'

/**
 * @constant {string}
 */
exports.COOLING = 'cooling'

/**
 * @constant {string}
 */
exports.AUTO = 'auto'

/**
 * @constant {string}
 */
exports.SWING = 'swing'

/**
 * @constant {string}
 */
exports.ANY = 'any'

/**
 * @constant {string}
 */
exports.OFF = 'off'

/**
 * @constant {string}
 */
exports.LOW = 'low'

/**
 * @constant {string}
 */
exports.MID = 'mid'

/**
 * @constant {string}
 */
exports.HIGH = 'high'

/**
 * @constant {string}
 */
exports.CELSIUS = 'celsius'

/**
 * @constant {string}
 */
exports.FAHRENHEIT = 'fahrenheit'

/**
 * @constant {string}
 */
exports.KELVIN = 'kelvin'

/**
 * @constant {string}
 */
exports.UNIT = 'unit'

/**
 * @constant {string}
 */
exports.UNITS = 'units'

/**
 * @constant {string}
 */
exports.REAL_TIME_UNIT = 'realTimeUnit'

/**
 * @constant {string}
 */
exports.TOTAL_UNIT = 'totalUnit'

/**
 * @constant {string}
 */
exports.RESOURCE_TYPE = 'resourceType'

/**
 * @constant {string}
 */
exports.GENERATOR = 'generator'

/**
 * @constant {string}
 */
exports.CONSUMER = 'consumer'

/**
 * @constant {string}
 */
exports.NET = 'net'

/**
 * @constant {string}
 */
exports.OLDEST_TIMESTAMP = 'oldestTimestamp'

/**
 * @constant {string}
 */
exports.VIRTUAL = 'virtual'

/**
 * @constant {string}
 */
exports.ENABLED = 'enabled'

/**
 * @constant {string}
 */
exports.ENABLE = 'enable'

/**
 * @constant {string}
 */
exports.REPEATED = 'repeated'

/**
 * @constant {string}
 */
exports.HOUR = 'hour'

/**
 * @constant {string}
 */
exports.SUNDAY = 'sunday'

/**
 * @constant {string}
 */
exports.MONDAY = 'monday'

/**
 * @constant {string}
 */
exports.TUESDAY = 'tuesday'

/**
 * @constant {string}
 */
exports.WEDNESDAY = 'wednesday'

/**
 * @constant {string}
 */
exports.THURSDAY = 'thursday'

/**
 * @constant {string}
 */
exports.FRIDAY = 'friday'

/**
 * @constant {string}
 */
exports.SATURDAY = 'saturday'

/**
 * @constant {string}
 */
exports.DASHBOARD = 'dashboard'

/**
 * @constant {string}
 */
exports.PERMISSIONS = 'permissions'

/**
 * @constant {string}
 */
exports.AV_SOURCES = 'avSources'

/**
 * @constant {string}
 */
exports.AV_SOURCE = 'avSource'

/**
 * @constant {string}
 */
exports.AUDIO = 'audio'

/**
 * @constant {string}
 */
exports.DURATION_MS = 'durationMs'

/**
 * @constant {string}
 */
exports.AVAILABLE = 'available'

/**
 * @constant {string}
 */
exports.CURRENT = 'current'

/**
 * @constant {string}
 */
exports.PLAYBACK = 'playback'

/**
 * @constant {string}
 */
exports.POSITION_MS = 'positionMs'

/**
 * @constant {string}
 */
exports.PLAY_URI = 'playUri'

/**
 * @constant {string}
 */
exports.BUFFERING = 'buffering'

/**
 * @constant {string}
 */
exports.UNKNOWN = 'unknown'

/**
 * @constant {string}
 */
exports.ALL = 'all'

/**
 * @constant {string}
 */
exports.AV = 'av'

/**
 * @constant {string}
 */
exports.COMPATIBLE_SOURCES = 'compatibleSources'

/**
 * @constant {string}
 */
exports.DEFAULT_NAME = 'defaultName'

/**
 * @constant {string}
 */
exports.INVALID_ARGUMENTS = 'invalidArguments'

/**
 * @constant {string}
 */
exports.UNSUPPORTED_ACTION = 'unsupportedAction'

/**
 * @constant {string}
 */
exports.UNEXPECTED_ERROR = 'unexpectedError'

/**
 * @constant {string}
 */
exports.BAD_REQUEST = 'badRequest'

/**
 * @constant {string}
 */
exports.ERROR_CODE = 'errorCode'

/**
 * @constant {string}
 */
exports.REASON = 'reason'

/**
 * @constant {string}
 */
exports.AV_SUPPORT = 'avSupport'

/**
 * @constant {string}
 */
exports.ORIG = 'orig'

/**
 * @constant {string}
 */
exports.VALUES = 'values'

/**
 * @constant {string}
 */
exports.RESET = 'reset'

/**
 * @constant {string}
 */
exports.LISTENING_ROOMS = 'listeningRooms'

/**
 * @constant {string}
 */
exports.MUTE = 'mute'

/**
 * @constant {string}
 */
exports.ALERT = 'alert'

/**
 * @constant {string}
 */
exports.MOVE = 'move'

/**
 * @constant {string}
 */
exports.SET = 'set'

/**
 * @constant {string}
 */
exports.EDITABLE = 'editable'

/**
 * @constant {string}
 */
exports.STREAMING_SERVICES = 'streamingServices'

/**
 * @constant {string}
 */
exports.ICON = 'icon'

/**
 * @constant {string}
 */
exports.STREAMING_SERVICE = 'streamingService'

/**
 * @constant {string}
 */
exports.LIBRARY = 'library'

/**
 * @constant {string}
 */
exports.SEARCH = 'search'

/**
 * @constant {string}
 */
exports.QUERY = 'query'

/**
 * @constant {string}
 */
exports.ASANO_AV_SUPPORT = 'asanoAvSupport'

/**
 * @constant {string}
 */
exports.HAS_LEGACY_AUTH = 'hasLegacyAuth'

/**
 * @constant {string}
 */
exports.DEG_30 = '30degrees'

/**
 * @constant {string}
 */
exports.DEG_45 = '45degrees'

/**
 * @constant {string}
 */
exports.DEG_60 = '60degrees'

/**
 * @constant {string}
 */
exports.HORIZONTAL = 'horizontal'

/**
 * @constant {string}
 */
exports.VERTICAL = 'vertical'

/**
 * @constant {string}
 */
exports.DRYING = 'drying'

/**
 * @constant {string}
 */
exports.FAN_ONLY = 'fanOnly'

/**
 * @constant {string}
 */
exports.CONTENT_TYPE = 'contentType'

/**
 * @constant {string}
 */
exports.IDS = 'ids'

/**
 * @constant {string}
 */
exports.INDEX = 'index'

/**
 * @constant {string}
 */
exports.CAPTION = 'caption'

/**
 * @constant {string}
 */
exports.DEFAULT_SOURCE = 'defaultSource'

/**
 * @constant {string}
 */
exports.REMOVABLE = 'removable'

/**
 * @constant {string}
 */
exports.URIS = 'uris'

/**
 * @constant {string}
 */
exports.SERVICE = 'service'

/**
 * @constant {string}
 */
exports.SERVICES = 'services'

/**
 * @constant {string}
 */
exports.MOVED = 'moved'

/**
 * @constant {string}
 */
exports.REMOVED = 'removed'

/**
 * @constant {string}
 */
exports.ADDED = 'added'

/**
 * @constant {string}
 */
exports.POSITIONS = 'positions'

/**
 * @constant {string}
 */
exports.UP = 'up'

/**
 * @constant {string}
 */
exports.CHANNEL_UP = 'channelUp'

/**
 * @constant {string}
 */
exports.CHANNEL_DOWN = 'channelDown'

/**
 * @constant {string}
 */
exports.WATCHING_ROOMS = 'watchingRooms'

/**
 * @constant {string}
 */
exports.VIDEO = 'video'

/**
 * @constant {string}
 */
exports.DOWN = 'down'

/**
 * @constant {string}
 */
exports.LEFT = 'left'

/**
 * @constant {string}
 */
exports.RIGHT = 'right'

/**
 * @constant {string}
 */
exports.FAST_FORWARD = 'fastForward'

/**
 * @constant {string}
 */
exports.REWIND = 'rewind'

/**
 * @constant {string}
 */
exports.MENU = 'menu'

/**
 * @constant {string}
 */
exports.BACK = 'back'

/**
 * @constant {string}
 */
exports.ENTER = 'enter'

/**
 * @constant {string}
 */
exports.HOLDABLE_ACTIONS = 'holdableActions'

/**
 * @constant {string}
 */
exports.CUSTOM_GRID = 'customGrid'

/**
 * @constant {string}
 */
exports.NUMERIC_GRID = 'numericGrid'

/**
 * @constant {string}
 */
exports.BUTTON = 'button'

/**
 * @constant {string}
 */
exports.VOLUME_UP = 'volumeUp'

/**
 * @constant {string}
 */
exports.VOLUME_DOWN = 'volumeDown'

/**
 * @constant {string}
 */
exports.CHANNEL = 'channel'

/**
 * @constant {string}
 */
exports.AVR = 'avr'

/**
 * @constant {string}
 */
exports.REASON = 'reason'

/**
 * @constant {string}
 */
exports.SCAN = 'scan'

/**
 * @constant {string}
 */
exports.SCANNING = 'scanning'

/**
 * @constant {string}
 */
exports.PRESETS = 'presets'

/**
 * @constant {string}
 */
exports.UPDATED = 'updated'

/**
 * @constant {string}
 */
exports.FOLLOW_ROOM_NAME_UUID = 'followRoomNameUuid'

/**
 * @constant {string}
 */

exports.LOCAL = 'local'

/**
 * @constant {string}
 */
exports.ITUNES = 'iTunes'

/**
 * @constant {string}
 */
exports.SEQUENCE = 'sequence'

/**
 * @constant {string}
 */
exports.QUEUE_EMPTY = 'queueEmpty'

/**
 * @constant {string}
 */
exports.END_OF_QUEUE = 'endOfQueue'

/**
 * @constant {string}
 */
exports.NUMERIC_INPUT = 'numericInput'

/**
 * @constant {string}
 */
exports.KEYPAD = 'keypad'

/**
 * @constant {string}
 */
exports.INPUT_TYPES = 'inputTypes'

/**
 * @constant {string}
 */
exports.QUICK = 'quick'

/**
 * @constant {string}
 */
exports.SPOTIFY_CONNECT = 'spotifyConnect'

/**
 * @constant {string}
 */
exports.TOPIC = 'topic'

/**
 * @constant {string}
 */
exports.SUBSCRIBE = 'subscribe'

/**
 * @constant {string}
 */
exports.CALL = 'call'

/**
 * @constant {string}
 */
exports.JOIN_CALL = 'joinCall'

/**
 * @constant {string}
 */
exports.HANGUP = 'hangup'

/**
 * @constant {string}
 */
exports.ANSWER = 'answer'

/**
 * @constant {string}
 */
exports.START_WATCHING = 'startWatching'

/**
 * @constant {string}
 */
exports.PUBLISH_STREAM = 'publishStream'

/**
 * @constant {string}
 */
exports.LIST_CONTACTS = 'listContacts'

/**
 * @constant {string}
 */
exports.GET_CALL_HISTORY = 'getCallHistory'

/**
 * @constant {string}
 */
exports.GET_CALL_DETAILS = 'getCallDetails'

/**
 * @constant {string}
 */
exports.CONTACTS = 'contacts'

/**
 * @constant {string}
 */
exports.CONTACT = 'contact'

/**
 * @constant {string}
 */
exports.CALL_UUID = 'callUuid'

/**
 * @constant {string}
 */
exports.PC_ID = 'pcId'

/**
 * @constant {string}
 */
exports.JSEP = 'jsep'

/**
 * @constant {string}
 */
exports.SDP = 'sdp'

/**
 * @constant {string}
 */
exports.CALLED = 'called'

/**
 * @constant {string}
 */
exports.NEW_STREAM = 'newStream'

/**
 * @constant {string}
 */
exports.CANDIDATE = 'candidate'

/**
 * @constant {string}
 */
exports.JOINED_CALL = 'joinedCall'

/**
 * @constant {string}
 */
exports.LEFT_CALL = 'leftCall'

/**
 * @constant {string}
 */
exports.CALL_ENDED = 'callEnded'

/**
 * @constant {string}
 */
exports.OFFER = 'offer'

/**
 * @constant {string}
 */
exports.ADD_PUBLISHER = 'addPublisher'

/**
 * @constant {string}
 */
exports.PARTICIPANTS = 'participants'

/**
 * @constant {string}
 */
exports.PARTICIPANT = 'participant'

/**
 * @constant {string}
 */
exports.CANDIDATES = 'candidates'

/**
 * @constant {string}
 */
exports.COMPLETED = 'completed'

/**
 * @constant {string}
 */
exports.HUNG_UP = 'Hung up'

/**
 * @constant {string}
 */
exports.CONTACT_UNREACHABLE = 'contactUnreachable'

/**
 * @constant {string}
 */
exports.CONTACT_TYPE = 'contactType'

/**
 * @constant {string}
 */
exports.DOORS = 'doors'

/**
 * @constant {string}
 */
exports.OPEN_DOOR = 'openDoor'

/**
 * @constant {string}
 */
exports.ADELANTE = 'adelante'

/**
 * @constant {string}
 */
exports.AUDIO_ALERT = 'audioAlert'

/**
 * @constant {string}
 */
exports.DURATION = 'duration'

/**
 * @constant {string}
 */
exports.CACHE_BUSTER = 'cache-buster'

/**
 * @constant {string}
 */
exports.INTERRUPTED = 'interrupted'

/**
 * @constant {string}
 */
exports.PLAY_AUDIO = 'playAudio'

/**
 * @constant {string}
 */
exports.JWT_LOGIN = 'jwtLogin'

/**
 * @constant {string}
 */
exports.GET_SIP_ADDRESSES = 'getSipAddresses'

/**
 * @constant {string}
 */
exports.SIP_ADDRESSES = 'sipAddresses'

/**
 * @constant {string}
 */
exports.GET_EXTRA_CAMERAS = 'getExtraCameras'

/**
 * @constant {string}
 */

exports.EMAIL = 'email'

/**
 * @constant {string}
 */
exports.CATEGORIES = 'categories'

/**
 * @constant {string}
 */
exports.CALL_HISTORY_CHANGED = 'callHistoryChanged'

/**
 * @constant {string}
 */
exports.SIP_ADDRESSES_CHANGED = 'sipAddressesChanged'

/**
 * @constant {string}
 */
exports.PRESS = 'press'

/**
 * @constant {string}
 */
exports.ADELANTE_NAME = 'adelanteName'

/**
 * @constant {string}
 */
exports.SCENE_UPDATE = 'sceneUpdate'

/**
 * @constant {string}
 */
exports.FULL = 'full'
