'use strict'

angular
  .module('basalteApp')
  .controller('thermostatDetailCtrl', [
    '$element',
    'BAS_ROOMS',
    'STATES',
    'BasUtilities',
    'CurrentRoom',
    thermostatDetailCtrl
  ])

/**
 * @param  $element
 * @param BAS_ROOMS
 * @param {STATES} STATES
 * @param {BasUtilities} BasUtilities
 * @param {CurrentRoom} CurrentRoom
 */
function thermostatDetailCtrl (
  $element,
  BAS_ROOMS,
  STATES,
  BasUtilities,
  CurrentRoom
) {
  var basThermostat = this

  /**
   * @type {TCurrentRoomState}
   */
  var currentRoom = CurrentRoom.get()

  basThermostat.goSchedule = goSchedule

  // "setMode" and "setFanMode" are also in this controller so scene step state
  //  can function correctly.
  // TODO Future improvement: Separate controller for detail thermostat state
  //  and scene step thermostat state
  basThermostat.setMode = setMode
  basThermostat.setFanMode = setFanMode
  basThermostat.smoothVerticalScroll = smoothVerticalScroll

  basThermostat.thermostats =
    BAS_ROOMS.ROOMS.rooms[currentRoom.roomId].thermostats.thermostats

  function goSchedule () {

    if (basThermostat.thermostat &&
      basThermostat.thermostat.thermostatScheduler) {

      basThermostat.thermostat.thermostatScheduler
        .determineInitialFilter()

      CurrentRoom.go(STATES.THERMOSTAT_WEEK_SCHEDULER)
    }
  }

  /**
   * @param {string} mode
   */
  function setMode (mode) {

    if (basThermostat.thermostat &&
      basThermostat.thermostat.canSetMode() &&
      basThermostat.thermostat.setMode) {

      basThermostat.thermostat.setMode(mode)
    }
  }

  /**
   * @param {string} mode
   */
  function setFanMode (mode) {

    if (basThermostat.thermostat &&
      basThermostat.thermostat.canSetFanMode() &&
      basThermostat.thermostat.setFanMode) {

      basThermostat.thermostat.setFanMode(mode)
    }
  }

  function smoothVerticalScroll () {
    const elem = $element[0]

    if (elem) {
      const offsetHeight = elem.offsetHeight
      const scrollHeight = elem.scrollHeight
      const isScrolled = elem.scrollTop > 0

      return isScrolled
        ? BasUtilities.smoothScrollVertical(elem, 0)
        : BasUtilities.smoothScrollVertical(elem, scrollHeight - offsetHeight)
    }
  }
}
