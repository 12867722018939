'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasRoomScheduler', [
    '$rootScope',
    'BAS_API',
    'BAS_ROOM',
    'CurrentBasCore',
    'BasSceneSchedule',
    basRoomSchedulerFactory
  ])

/**
 * @param $rootScope
 * @param BAS_API
 * @param {BAS_ROOM} BAS_ROOM
 * @param {CurrentBasCore} CurrentBasCore
 * @param BasSceneSchedule
 * @returns BasRoomScheduler
 */
function basRoomSchedulerFactory (
  $rootScope,
  BAS_API,
  BAS_ROOM,
  CurrentBasCore,
  BasSceneSchedule
) {
  /**
   * @type {TCurrentBasCoreState}
   */
  var currentBasCoreState = CurrentBasCore.get()

  /**
   * @constructor
   * @param {BasRoom} basRoom
   */
  function BasRoomScheduler (basRoom) {

    /**
     * @type {Object<string, BasSceneSchedule>}
     */
    this.schedules = {}

    /**
     * @type {string[]}
     */
    this.uiSchedules = []

    /**
     * @private
     * @type {BasRoom}
     */
    this._basRoom = basRoom

    this._deviceListeners = []

    this._doCompareSchedules = this._compareSchedules.bind(this)

    this._handleNewJob = this._onNewJob.bind(this)
    this._handleJobsChanged = this._onJobsChanged.bind(this)
    this._handleJobChanged = this._onJobChanged.bind(this)
    this._handleJobRemoved = this._onJobRemoved.bind(this)
    this._handleJobAdded = this._onJobAdded.bind(this)

    this._setDeviceListeners()
    this.parseRoom()
    this._syncOrder()
  }

  /**
   * @constant {string}
   */
  BasRoomScheduler.ERR_INVALID_SCHEDULE = 'errInvalidSchedule'

  /**
   * @constant {string}
   */
  BasRoomScheduler.ERR_NO_CTRL = 'errNoCtrl'

  /**
   * @param {BasRoom} room
   * @returns {boolean}
   */
  BasRoomScheduler.hasSchedules = function (room) {

    // Scheduler only possible on the home area
    return (
      room &&
      room.room &&
      BasUtil.isNEString(room.room.sceneCtrl) &&
      room.level === BAS_API.Room.LEVELS.LVL_HOME
    )
  }

  BasRoomScheduler.prototype.parseRoom = function () {

    if (BasRoomScheduler.hasSchedules(this._basRoom)) this.syncSchedules()
  }

  BasRoomScheduler.prototype.syncSchedules = function () {

    var sceneCtrl, jobs, keys, i, length, uuid, job

    this.schedules = {}
    this.uiSchedules = []

    if (BasRoomScheduler.hasSchedules(this._basRoom)) {

      sceneCtrl = this._getSceneCtrl()

      if (sceneCtrl) {

        jobs = sceneCtrl.jobs

        keys = Object.keys(jobs)
        length = keys.length
        for (i = 0; i < length; i++) {

          uuid = keys[i]
          job = jobs[uuid]

          if (job) {

            this.schedules[uuid] =
              new BasSceneSchedule(uuid, this._basRoom)
            this.uiSchedules.push(uuid)
          }
        }

        this.uiSchedules.sort(this._doCompareSchedules)
      }
    }
  }

  /**
   * @returns {Promise<BasSceneSchedule>}
   */
  BasRoomScheduler.prototype.addNewSchedule = function () {

    var sceneCtrl

    sceneCtrl = this._getSceneCtrl()

    if (sceneCtrl) {

      return sceneCtrl.addNewJob().then(this._handleNewJob)
    }

    return Promise.reject(BasRoomScheduler.ERR_NO_CTRL)
  }

  /**
   * @private
   * @param {Job} result
   * @returns {(BasSceneSchedule|Promise)}
   */
  BasRoomScheduler.prototype._onNewJob = function (result) {

    var job, uuid

    if (BasUtil.isObject(result) &&
      BasUtil.isNEString(result.uuid)) {

      uuid = result.uuid
      job = new BasSceneSchedule(uuid, this._basRoom)

      this.schedules[uuid] = job
      this.uiSchedules.push(uuid)

      this.uiSchedules.sort(this._doCompareSchedules)

      return job
    }

    return Promise.reject(BasRoomScheduler.ERR_INVALID_SCHEDULE)
  }

  /**
   * @param {BasSceneSchedule} schedule
   * @returns {Promise}
   */
  BasRoomScheduler.prototype.updateSchedule = function (
    schedule
  ) {
    var job, sceneCtrl

    if (schedule) {

      job = schedule.getJob()

      if (job) {

        sceneCtrl = this._getSceneCtrl()

        if (sceneCtrl) return sceneCtrl.updateJob(job)
      }
    }

    return Promise.reject(new Error('no schedule'))
  }

  /**
   * @param {string} scheduleId
   */
  BasRoomScheduler.prototype.removeSchedule = function (scheduleId) {

    var sceneCtrl

    if (BasUtil.isNEString(scheduleId)) {

      sceneCtrl = this._getSceneCtrl()

      if (sceneCtrl) sceneCtrl.removeJob(scheduleId)
    }
  }

  BasRoomScheduler.prototype._onJobsChanged = function () {

    this.syncSchedules()

    $rootScope.$applyAsync()
  }

  /**
   * @private
   * @param {Job} job
   */
  BasRoomScheduler.prototype._onJobAdded = function (job) {

    var uuid

    if (BasUtil.isObject(job) &&
      BasUtil.isNEString(job.uuid)) {

      uuid = job.uuid

      this.schedules[uuid] = new BasSceneSchedule(uuid, this._basRoom)
      this.uiSchedules.push(uuid)

      this.uiSchedules.sort(this._doCompareSchedules)
    }

    $rootScope.$applyAsync()
  }

  /**
   * @private
   * @param {Job} job
   */
  BasRoomScheduler.prototype._onJobChanged = function (job) {

    if (BasUtil.isObject(job) &&
      BasUtil.isNEString(job.uuid) &&
      BasUtil.isObject(this.schedules[job.uuid])) {

      this.schedules[job.uuid].sync(job)

      $rootScope.$emit(BAS_ROOM.EVT_BAS_JOB_UPDATED, job.uuid)
    }

    $rootScope.$applyAsync()
  }

  /**
   * @private
   * @param {Job} job
   */
  BasRoomScheduler.prototype._onJobRemoved = function (job) {

    var i, length, oldSchedules

    if (BasUtil.isObject(job) &&
      BasUtil.isNEString(job.uuid)) {

      oldSchedules = this.uiSchedules
      this.uiSchedules = []

      length = oldSchedules.length

      for (i = 0; i < length; i++) {

        if (oldSchedules[i] !== job.uuid) {

          this.uiSchedules.push(oldSchedules[i])
        }
      }

      this.schedules[job.uuid] = null

      $rootScope.$emit(BAS_ROOM.EVT_BAS_JOB_REMOVED, job.uuid)
    }

    $rootScope.$applyAsync()
  }

  BasRoomScheduler.prototype.handleScenesUpdated = function () {

    var keys, i, length, schedule

    keys = Object.keys(this.schedules)
    length = keys.length
    for (i = 0; i < length; i++) {

      schedule = this.schedules[keys[i]]

      if (schedule && schedule.handleScenesUpdated) {

        schedule.handleScenesUpdated()
      }
    }
  }

  /**
   * Sets listeners on current Scene Controller Device
   */
  BasRoomScheduler.prototype.syncDevice = function () {

    this._setDeviceListeners()
  }

  BasRoomScheduler.prototype.suspend = function () {

    this._clearDeviceListeners()
  }

  BasRoomScheduler.prototype.destroy = function () {

    this.suspend()
  }

  BasRoomScheduler.prototype.updateTranslation = function () {

    var keys, i, length, schedule

    keys = Object.keys(this.schedules)
    length = keys.length
    for (i = 0; i < length; i++) {

      schedule = this.schedules[keys[i]]

      if (schedule && schedule.updateTranslation) schedule.updateTranslation()
    }
  }

  /**
   * @param {number} spliceIndex
   * @param {number} originalIndex
   */
  BasRoomScheduler.prototype.uiReorder = function (
    spliceIndex,
    originalIndex
  ) {
    var original

    original = this.uiSchedules[originalIndex]

    if (original) {

      this.uiSchedules.splice(originalIndex, 1)
      this.uiSchedules.splice(
        spliceIndex,
        0,
        original
      )

      this._saveOrder()
    }
  }

  BasRoomScheduler.prototype._saveOrder = function () {

    if (this._basRoom &&
      CurrentBasCore.hasCore() &&
      currentBasCoreState.core.core.sharedServerStorage) {

      currentBasCoreState.core.core.sharedServerStorage
        .updateSchedulesOrder(
          this._basRoom.id,
          this.uiSchedules
        )
    }
  }

  BasRoomScheduler.prototype.onOrderUpdated = function (
    _event,
    orderData
  ) {
    var roomUuid, storageUuids, sortedUuids, keys, length, i, _uuid

    if (this._basRoom) {

      roomUuid = this._basRoom.id

      if (BasUtil.isObject(orderData) &&
        Array.isArray(orderData[roomUuid])) {

        storageUuids = orderData[roomUuid]
      }
    }

    if (storageUuids &&
      !BasUtil.isEqualArray(this.uiSchedules, storageUuids)) {

      sortedUuids = []

      // Match devices from server storage

      length = storageUuids.length
      for (i = 0; i < length; i++) {

        _uuid = storageUuids[i]

        if (this.schedules[_uuid]) {

          if (sortedUuids.indexOf(_uuid) < 0) {

            sortedUuids.push(_uuid)
          }
        }
      }

      // Match devices NOT in server storage

      keys = Object.keys(this.schedules)
      length = keys.length
      for (i = 0; i < length; i++) {

        _uuid = keys[i]

        if (sortedUuids.indexOf(_uuid) < 0) {

          sortedUuids.push(_uuid)
        }
      }

      // Set schedules

      this.uiSchedules = sortedUuids
    }
  }

  BasRoomScheduler.prototype._syncOrder = function () {

    var orderData

    if (CurrentBasCore.hasCore() &&
      currentBasCoreState.core.core.sharedServerStorage) {

      orderData = currentBasCoreState.core.core.sharedServerStorage
        .schedulesOrder

      if (BasUtil.isNEObject(orderData)) {

        this.onOrderUpdated(null, orderData)
      }
    }
  }

  BasRoomScheduler.prototype._setDeviceListeners = function () {

    var sceneCtrl

    this._clearDeviceListeners()

    sceneCtrl = this._getSceneCtrl()

    if (sceneCtrl) {

      this._deviceListeners.push(BasUtil.setEventListener(
        sceneCtrl,
        BAS_API.SceneCtrlDevice.EVT_JOBS_CHANGED,
        this._handleJobsChanged
      ))

      this._deviceListeners.push(BasUtil.setEventListener(
        sceneCtrl,
        BAS_API.SceneCtrlDevice.EVT_JOB_CHANGED,
        this._handleJobChanged
      ))

      this._deviceListeners.push(BasUtil.setEventListener(
        sceneCtrl,
        BAS_API.SceneCtrlDevice.EVT_JOB_REMOVED,
        this._handleJobRemoved
      ))

      this._deviceListeners.push(BasUtil.setEventListener(
        sceneCtrl,
        BAS_API.SceneCtrlDevice.EVT_JOB_ADDED,
        this._handleJobAdded
      ))
    }
  }

  BasRoomScheduler.prototype._clearDeviceListeners = function () {

    BasUtil.executeArray(this._deviceListeners)
    this._deviceListeners = []
  }

  /**
   * @private
   * @returns {?SceneCtrlDevice}
   */
  BasRoomScheduler.prototype._getSceneCtrl = function () {

    if (this._basRoom &&
      this._basRoom.room &&
      this._basRoom.room.sceneCtrl) {

      return CurrentBasCore.getDevice(this._basRoom.room.sceneCtrl)
    }

    return null
  }

  /**
   * Compares Jobs on order.
   * Takes 2 Job UUID's
   *
   * @private
   * @param {string} scheduleId1
   * @param {string} scheduleId2
   * @returns {number}
   */
  BasRoomScheduler.prototype._compareSchedules = function (
    scheduleId1,
    scheduleId2
  ) {
    var schedule1, schedule2

    schedule1 = this.schedules[scheduleId1]
    schedule2 = this.schedules[scheduleId2]

    if (schedule1 && schedule1.getOrder && schedule2 && schedule2.getOrder) {

      return schedule1.getOrder() - schedule2.getOrder()

    } else if (schedule1) {

      return -1

    } else if (schedule2) {

      return 1
    }

    return 0
  }

  /**
   * Handles time format change
   */
  BasRoomScheduler.prototype.onTimeFormatChanged = function () {

    var keys, i

    keys = Object.keys(this.schedules)

    for (i = 0; i < keys.length; i++) {

      this.schedules[keys[i]].onTimeFormatChanged()
    }
  }

  return BasRoomScheduler
}
