{
  "12_hr": "12時間",
  "24_hr": "24時間",
  "24_hr_time": "24時間表示",
  "about": "About",
  "account": "アカウント",
  "account_exists": "アカウントを出る",
  "account_exists_l1": "このアカウントは既に存在します",
  "account_exists_l2": "他のEメールアドレスを選択するか、このEメールアドレスでログインしてください。",
  "account_not_confirmed": "アカウントを確認できませんでした",
  "account_not_confirmed_l1": "このアカウントはまだ確認が取れていません",
  "account_not_confirmed_l2": "Eメールアドレス確認用のメールを確認してください",
  "account_not_confirmed_l3": "このアカウントを認証しますか？",
  "act_in": "イン",
  "activate": "アクティベート",
  "active": "アクティブ",
  "act_out": "アウト",
  "act_push": "プッシュ",
  "adaptive_brightness": "明るさ調整",
  "add": "追加",
  "add_favourite": "お気に入りを追加",
  "add_group": "グループ追加",
  "add_home": "家を追加",
  "address": "アドレス",
  "add_to_fav": "お気に入りに追加",
  "add_to_playlist": "プレイリストに追加",
  "add_to_queue": "キューに追加",
  "air_conditioning": "エアコン",
  "air_pressure": "気圧",
  "alarm_at": "アラーム",
  "alarm_missing_txt": "１つ又は複数の要求項目が不足しています",
  "alarm_not_selected_l1": "アラーム無しを選択しています",
  "alarm_not_selected_l2": "アラームを選択してください",
  "alarms": "アラーム",
  "album": "アルバム",
  "albums": "アルバム",
  "alert": "警告",
  "all": "全て",
  "all_off": "All Off",
  "all_off_txt_l1": "全ての部屋をOffしようとしています。",
  "all_off_txt_l2": "続けますか？",
  "all_on": "All On",
  "almost_done": "完了間近です",
  "almost_done_txt_l1": "お気に入りのプレイリスト又はラジオステーションがまだありません",
  "almost_done_txt_l2": "引き続き最初にお気に入りを追加してください",
  "already_linked": "Basalte アカウントにリンクされています",
  "already_verified": "既に認証されています",
  "alt_power": "電源",
  "alt_user": "ユーザー",
  "application": "アプリケーション",
  "application_version": "アプリバージョン",
  "app_update_required": "アプリの更新が必要です",
  "app_update_required_android_l2": "Play Store よりアプリの更新をしてください",
  "app_update_required_ios_l2": "App Store よりアプリの更新をしてください",
  "app_update_required_l2": "store よりアプリの更新をしてください",
  "artist": "アーティスト",
  "artists": "アーティスト",
  "attention": "注意",
  "audio_unavailable": "オーディオシステムは利用できません。",
  "audio_zones": "オーディオゾーン",
  "author": "作者",
  "back": "戻る",
  "basalte_account": "Basalte アカウント",
  "basalte_info_l1": "Basalte アカウントであなたのホームネットワーク外から、ホームの制御をすることが出来るようになります",
  "basalte_link_server_l1": "あなたの Basalte アカウントとこのホームを関連付けますか",
  "basalte_link_server_l2": "あなたのローカルネットワーク外からこのホームを制御できるようになります",
  "basalte_unlink_server_l1": "このホームをあなたの Basalte アカウントから接続解除しますか",
  "basalte_unlink_server_l2": "ローカルネットワーク外からあなたのホームへ接続が出来なくなります",
  "bass": "低音",
  "BE": "ベルギー",
  "beta": "ベータ",
  "bluetooth": "Bluetooth",
  "bluetooth_pairing_txt": "bluetooth 経由であなたのデバイスに接続ができます",
  "brightness": "明るさ",
  "browse": "閲覧",
  "browse_deezer": "Deezer favourites",
  "browse_library": "ミュージックライブラリ",
  "browser_app_update_required_l2": "ページを再読み込みするかサーバーを更新してください",
  "browse_stations": "ラジオステーション",
  "buffering": "バッファーリング",
  "building_building": "建物",
  "building_garage": "ガレージ",
  "building_main_house": "主邸宅",
  "building_pool_house": "プールハウス",
  "building_stables": "厩舎",
  "cameras": "カメラ",
  "cancel": "キャンセル",
  "categories": "カテゴリー",
  "category": "カテゴリー",
  "celsius": "摂氏",
  "change_account": "アカウントの変更",
  "change_home": "ホームの変更",
  "change_image": "イメージ変更",
  "change_password": "パスワード変更",
  "change_password_l1": "パスワードを変更しようとしています",
  "change_user": "プロファイルの変更",
  "channel_number": "チャンネル番号",
  "charts": "チャート",
  "check_for_updates": "更新確認",
  "checking": "確認中",
  "checking_for_updates": "更新確認中",
  "choose_favourite": "お気に入りを選択しますか？",
  "choose_home": "ホームを選択",
  "choose_music": "お気に入りを選択しますか？",
  "choose_preset": "プリセット選択",
  "choose_source": "ソースを選択しますか？",
  "clear": "クリア",
  "cleared": "クリア済み",
  "clear_queue_txt_l1": "キューを削除しようとしています",
  "clear_queue_txt_l2": "続けてよろしいですか？",
  "clear_selection": "選択解除",
  "clear_selection_txt_l1": "選択を削除しようとしています",
  "clear_selection_txt_l2": "続けてよろしいですか？",
  "close": "クローズ",
  "closed": "閉じました",
  "code": "コード",
  "color": "カラー",
  "color_temperature": "色温度",
  "color_theme": "カラーテーマ",
  "color_white": "白",
  "comfort": "コンフォート",
  "configure": "設定",
  "confirmation_code_sent": "確認用コードの送信",
  "confirmation_code_sent_l1": "認証用コードを次のアドレス宛に送信しました",
  "confirm_continue": "このまま続けますか?",
  "connect": "接続",
  "connected": "接続済み",
  "connecting": "接続中",
  "connecting_no_active_servers": "サーバーがオフライン状態です。",
  "connecting_no_integrator_access": "このサーバーはリモート設定が有効ではありません。",
  "connecting_not_authorized": "このサーバーへの接続権限がありません。",
  "connecting_small": "接続中",
  "connecting_stop_and_retry_text": "サーバーへの接続に失敗しました。 10秒で再試行します。",
  "connecting_stop_text": "サーバーへの接続に失敗しました",
  "connecting_text": "接続中…",
  "connecting_text_small": "接続中…",
  "connection_lost": "接続無し",
  "connection_lost_text": "サーバーへ再接続中",
  "connection_unable": "指定されたサーバーに接続できません",
  "connect_offline_server_l1": "このホームはリンクされていますがオフラインのようです。",
  "connect_offline_server_l2": "接続を続けますか？",
  "connect_server": "サーバーへ接続",
  "consumption": "消費",
  "contact_support": "サポートへ連絡",
  "continue_anyway": "そのまま続ける",
  "continue_without_basalte_account": "Basalte アカウントを使用しないで続ける",
  "cover_art_background": "バックグランドカバーアート",
  "current_password": "現在のパスワード",
  "current_temperature": "現状",
  "custom": "カスタム",
  "date": "日付",
  "day": "日",
  "day_friday": "金曜日",
  "day_monday": "月曜日",
  "day_saturday": "土曜日",
  "day_short2_friday": "金",
  "day_short2_monday": "月",
  "day_short2_saturday": "土",
  "day_short2_sunday": "日",
  "day_short2_thursday": "木",
  "day_short2_tuesday": "火",
  "day_short2_wednesday": "水",
  "day_sunday": "日曜日",
  "day_thursday": "木曜日",
  "day_tuesday": "火曜日",
  "day_wednesday": "水曜日",
  "debug": "デバッグ",
  "deezer": "Deezer",
  "deezer_disconnect_txt": "プレーヤーからDeezerアカウントを削除しようとしています",
  "deezer_favourite": "Deezer favourite",
  "deezer_flow": "Flow",
  "deezer_flow_description": "パーソナルサウンドトラック",
  "deezer_flow_full": "Deezer Flow",
  "deezer_no_link": "Deezerアカウントがリンクされていません",
  "deezer_oauth_error": "Deezer認証エラー",
  "default_rooms": "デフォルトルーム",
  "default_rooms_hint": "ホームストリーミングが正しく機能するよう必ず一部屋はデフォルトルームに設定してください",
  "default_rooms_info_l1": "サードパーティ製オーディオストリーミングのデフォルトルーム（Spotify Connect 他）",
  "default_rooms_info_l2": "いずれの部屋もストリーミングに未接続の場合、特定の部屋を起動する",
  "default_rooms_spotify_hint": "Spotify Connect が正しく機能するよう必ず一部屋はデフォルトルームに設定してください",
  "default_rooms_spotify_warning_l1": "各ストリーミング用にデフォルトルームを最低１つ設定してください",
  "default_rooms_spotify_warning_l2": "設定の完了",
  "default_rooms_spotify_warning_title": "Spotify Connect がサポートされました",
  "default_view": "デフォルトビュー",
  "delay": "遅延",
  "delay_seconds_b": "遅延（秒）",
  "delete": "削除",
  "delete_account": "アカウントの削除",
  "demo_enter": "デモ モード",
  "demo_functionality_warning_txt": "この機能はデモモードではサポートされていません。",
  "demo_location": "Ghent",
  "demo_server_name": "Demo Home",
  "demo_title": "デモ",
  "demo_warning_txt": "デモモードを開始しようとしています",
  "detail": "詳細",
  "device": "デバイス",
  "devices": "デバイス",
  "device_settings": "デバイス設定",
  "device_volume": "デバイス音量",
  "dhcp": "DHCP",
  "direction_east": "東",
  "direction_east-northeast": "東北東",
  "direction_east-southeast": "東南東",
  "direction_north": "北",
  "direction_northeast": "北東",
  "direction_north-northeast": "北北東",
  "direction_north-northwest": "北北西",
  "direction_northwest": "北西",
  "direction_short_east": "東",
  "direction_short_east-northeast": "東北東",
  "direction_short_east-southeast": "東南東",
  "direction_short_north": "北",
  "direction_short_northeast": "北東",
  "direction_short_north-northeast": "北北東",
  "direction_short_north-northwest": "北北西",
  "direction_short_northwest": "北西",
  "direction_short_south": "南",
  "direction_short_southeast": "南東",
  "direction_short_south-southeast": "南南東",
  "direction_short_south-southwest": "南南西",
  "direction_short_southwest": "南西",
  "direction_short_west": "西",
  "direction_short_west-northwest": "西北西",
  "direction_short_west-southwest": "西南西",
  "direction_south": "南",
  "direction_southeast": "南東",
  "direction_south-southeast": "南南東",
  "direction_south-southwest": "南南西",
  "direction_southwest": "南西",
  "direction_west": "西",
  "direction_west-northwest": "西北西",
  "direction_west-southwest": "西南西",
  "disband_group": "グループを解散する",
  "dns": "DNS",
  "domain": "ドメイン",
  "done": "Done",
  "do_not_disturb": "応答不可",
  "do_not_disturb_off_l1": "現在、すべての音声通知がオンになっています",
  "do_not_disturb_on_l1": "現在、すべての音声通知はオフになっています",
  "do_not_show_again": "今後表示しない",
  "door_phone": "インターホン",
  "doors": "ドア",
  "down": "下降",
  "downloading": "ダウンロード中",
  "downloading_updates": "更新をダウンロード中",
  "do_you_want_to_continue": "続行しますか?",
  "drag_and_drop_images": "イメージの選択又はここへドロップ",
  "drag_and_drop_lights": "ここにライトをドラッグ＆ドロップ",
  "drag_to_reorder": "ドラッグして順序変更",
  "dsp": "DSP",
  "dsp_configuration": "DSP設定",
  "dsp_profile_club": "クラブ",
  "dsp_profile_custom": "カスタム",
  "dsp_profile_flat": "フラット",
  "dsp_profile_jazz": "ジャズ",
  "dsp_profile_live": "ライブ",
  "dsp_profile_pop": "ポップ",
  "dsp_profile_rock": "ロック",
  "dsp_shortcut_l1": "DSP設定を開こうとしています",
  "echo_cancellation": "エコーキャンセレーション",
  "echo_limit": "最大エコー",
  "edit": "編集",
  "edit_dns": "DNS編集",
  "edit_gateway": "ゲートウェイ編集",
  "edit_ip_address": "IPアドレス編集",
  "edit_subnet_mask": "サブネットマスク編集",
  "ellie_room": "Ellie room",
  "ellie_system_update": "Ellieシステムアップデート",
  "ellie_system_update_l1": "システムアップデートをインストールする為、Ellieの再起動が必要です",
  "ellie_update": "Ellie アップデート",
  "email": "Eメールアドレス",
  "empty": "空き",
  "empty_queue": "キューは空です",
  "empty_queue_txt_l1": "キューに曲が無いようです",
  "empty_queue_txt_l2": "続けて追加する",
  "empty_song": "プレーヤーが終了しました",
  "empty_song_txt_l1": "キューの最後に到達しました",
  "empty_song_txt_l2": "リスタートするには再生を押してください",
  "empty_src": "ソースが選択されていません",
  "empty_src_txt_l1": "ソースが選択されていないようです",
  "empty_src_txt_l2": "続けてソースを選択する",
  "empty_zone": "部屋が選択されていません",
  "empty_zone_txt_l1": "部屋が選択されていないようです",
  "empty_zone_txt_l2": "続けて部屋を選択する",
  "energy": "エネルギー",
  "energy_meter": "エナジーメーター",
  "eq": "EQ",
  "error": "エラー",
  "external": "外部",
  "extra_permissions_required": "いくつかの機能は追加のデバイス認証が要求されます",
  "ext_src": "外部ソース",
  "fahrenheit": "華氏",
  "failed": "失敗",
  "fan": "ファン",
  "fan_auto": "オート",
  "fan_high": "強",
  "fan_low": "弱",
  "fan_medium": "中",
  "fan_off": "オフ",
  "favourite": "好き",
  "favourites": "お気に入り",
  "favourite_scenes_full": "お気に入りシーンが数量が上限に達しました",
  "favourite_scenes_full_sub": "お気に入りのシーンは一部屋に４つまで設定できます",
  "favourite_songs": "お気に入りの曲",
  "featured_playlists": "特集プレイリスト",
  "floor_attic": "屋根裏",
  "floor_basement": "地下",
  "floor_floor": "フロア",
  "floor_garden": "庭",
  "floor_heating": "床暖房",
  "floor_loft": "ロフト",
  "floor_lower": "下の階",
  "floor_lvl_0": "1階",
  "floor_lvl_1": "2階",
  "floor_lvl_2": "3階",
  "floor_lvl_3": "4階",
  "floor_lvl_4": "5階",
  "floor_lvl_5": "6階",
  "floor_lvl_6": "7階",
  "floor_lvl_7": "8階",
  "floor_lvl_8": "9階",
  "floor_lvl_9": "10階",
  "floor_lvl_10": "11階",
  "floor_lvl_11": "12階",
  "floor_lvl_12": "13階",
  "floor_lvl_13": "14階",
  "floor_lvl_14": "15階",
  "floor_lvl_15": "16階",
  "floor_lvl_16": "17階",
  "floor_lvl_17": "18階",
  "floor_lvl_18": "19階",
  "floor_lvl_19": "20階",
  "floor_lvl_20": "21階",
  "floor_upper": "上の階",
  "forgot_password": "パスワードを忘れた",
  "frequency": "頻度",
  "full_screen": "フル画面",
  "functions": "機能",
  "gas": "ガス",
  "gateway": "ゲートウェイ",
  "general": "一般",
  "generic_devices": "デバイス",
  "generic_devices_fire": "火",
  "generic_devices_garden": "庭",
  "generic_devices_socket": "ソケット",
  "genre": "ジャンル",
  "genre_moods": "ジャンルとムード",
  "genres": "ジャンル",
  "go_to_login_l1": "Basalte のアカウントでログインまたは登録する",
  "go_to_manage_l1": "Basalte アカウントの管理",
  "group": "群",
  "groups": "グループ",
  "home": "ホーム",
  "humidity": "湿度",
  "hz": "Hz",
  "images": "画像",
  "image_smaller_than_5mb": "画像サイズの上限は 5MB です",
  "in": "イン",
  "incorrect_album": "アルバムが見つかりません",
  "incorrect_artist": "アーティストが見つかりません",
  "install": "インストール",
  "installing": "インストール中",
  "install_update": "更新インストール",
  "install_updates": "更新インストール",
  "integrator": "インテグレーター",
  "integrator_access_allow": "遠隔設定を許可する",
  "integrator_access_ask": "リモート設定を有効にするようオーナーにお問合せください。",
  "integrator_access_disabled": "リモート設定が無効化されています。",
  "intercom": "インターホン",
  "internal": "内部",
  "invalid_address": "無効なアドレス",
  "invalid_code": "無効なコード",
  "invalid_code_l1": "入力したコードは正しくありません",
  "invalid_code_l2": "コードが正しいか確認していただくか再度新しいコードをリクエストしてください",
  "invalid_input": "無効な入力",
  "invalid_user": "無効なユーザー",
  "invalid_user_l1": "Eメールアドレスが有効か確認してください",
  "ios_plist_string_NSCameraUsageDescription": "カメラは部屋の写真撮影に使用されます",
  "ios_plist_string_NSLocalNetworkUsageDescription": "ローカルネットワークアクセスを使用して、サーバー、IPカメラなどを見つけることができます。",
  "ios_plist_string_NSPhotoLibraryAddUsageDescription": "フォトライブラリは部屋の写真の保存に使用されます",
  "ios_plist_string_NSPhotoLibraryUsageDescription": "フォトライブラリは部屋の写真の選択に使用されます",
  "ip": "IP",
  "ip_address": "IPアドレス",
  "join": "追加する",
  "join_alt": "追加する",
  "keep_awake": "iPadを動作状態で維持する",
  "keep_awake_device": "デバイスを動作状態で維持する",
  "kelvin": "Kelvin",
  "Key": "ja_JP",
  "knx_presets": "KNXプリセット",
  "knx_presets_info": "KNXプリセットを変更する",
  "language": "言語",
  "language_system": "システム言語",
  "last-7-days": "過去1週間",
  "last_hour": "過去1時間",
  "last_room": "最後の部屋",
  "last_view": "ラストビュー",
  "learn_more": "詳細",
  "leave_group": "グループを離れる",
  "left": "左",
  "lena_system_update": "Lenaシステムアップデート",
  "lena_system_update_l1": "システムアップデートをインストールする為、Lenaの再起動が必要です",
  "library": "ライブラリー",
  "library_empty": "ライブラリーは空です",
  "library_scanning": "ライブラリースキャン中",
  "light_accent_light": "アートライト",
  "light_art_light": "アクセントライト",
  "light_cabinet": "キャビネット",
  "light_ceiling_mounted_fixture": "シーリングライト",
  "light_chandelier": "シャンデリア",
  "light_desk": "デスク",
  "light_down": "ダウンライト",
  "light_floor_lamp": "フロアライト",
  "light_lampshade": "ランプシェード",
  "light_led": "LED照明",
  "light_led_strip": "LEDストリップ",
  "light_mirror": "ミラー",
  "light_mood_light": "ムードライト",
  "light_pendant": "ペンダント",
  "light_reading_light": "読書灯",
  "lights": "照明",
  "lights_off_all": "全消灯",
  "light_spot": "スポット",
  "light_table_lamp": "テーブルライト",
  "light_track": "トラック",
  "light_via": "Via",
  "light_wall_mounted_fixture": "ウォールライト",
  "link": "リンク",
  "link_deezer_info_l1": "Deezerアカウントをストリーミングにリンクする",
  "link_deezer_info_l2": "これによりストリーミングでのDeezerメディアを閲覧および聴く事が出来ます",
  "linked_homes": "リンクされたホーム",
  "linked_projects": "リンクされたプロジェクト",
  "linked_with": "とリンク",
  "link_home": "ローカルデバイス上の Basalteアプリを開き設定からホームをリンクします",
  "link_server": "Basalte アカウントにリンク付け",
  "link_server_full_sub": "このプロジェクトを次のBasalte アカウントにリンク付けますか？",
  "link_spotify_info_l1": "Spotifyアカウントをストリーミングにリンクする",
  "link_spotify_info_l2": "これによりストリーミングでのSpotifyメディアを閲覧および聴く事が出来ます",
  "link_tidal_info_l1": "TIDALアカウントをストリーミングにリンクする",
  "link_tidal_info_l2": "これによりストリーミングでのTIDALメディアを閲覧および聴く事が出来ます",
  "lisa_order_hint": "ページとタイルの順序と表示を変更",
  "lisa_system_update": "Lisaシステムアップデート",
  "lisa_system_update_l1": "システムアップデートをインストールする為、Lisaの再起動が必要です",
  "listen_in": "Listen in",
  "liters": "リットル",
  "live_adjustments": "Live調整",
  "loading": "ローディング中・・・",
  "local": "ローカル",
  "location": "位置",
  "location_back": "背後",
  "location_bottom": "下",
  "location_center": "中央",
  "location_chair": "椅子",
  "location_corner": "角",
  "location_desk": "机",
  "location_east": "東",
  "location_front": "正面",
  "location_high": "高",
  "location_island": "アイランド",
  "location_left": "左",
  "location_low": "低",
  "location_mid": "中",
  "location_niche": "ニッチ",
  "location_north": "北",
  "location_right": "右",
  "location_shelves": "棚",
  "location_south": "南",
  "location_stove": "ストーブ",
  "location_table": "テーブル",
  "location_top": "上",
  "location_west": "西",
  "logged_in_as": "次のアカウントでログイン中",
  "log_out": "ログアウト",
  "log_out_everywhere": "どこでもログアウト",
  "long-press-save-scene": "長押しでシーンを上書きする",
  "louver_30": "30 度",
  "louver_45": "45 度",
  "louver_60": "60 度",
  "louver_horizontal": "水平方向",
  "louver_mode": "ルーバーモード",
  "louver_off": "オフ",
  "louver_swing": "スイング",
  "louver_vertical": "垂直方向",
  "mac_address": "MACアドレス",
  "manage": "管理",
  "manage_account": "アカウントを管理する",
  "manual_conn": "手動接続",
  "manual_text": "ご要望のサーバーのアドレスを入力してください",
  "max": "マックス",
  "menu": "メニュー",
  "mf_device": "{count, plural, =1 {Device} other {Devices}}",
  "mf_feels_like": "Feels like {value}",
  "mf_minute": "{count} {count, plural, =1 {分} other {分}}",
  "mf_powered_by": "Powered by {value}",
  "mf_second": "{count} {count, plural, =1 {秒} other {秒}}",
  "mf_server": "{count, plural, =1 {サーバー} other {サーバー}}",
  "mf_song": "{count} {count, plural, =1 {song} other {songs}}",
  "mf_source": "ソース: {value}",
  "microphone_gain": "マイク音量",
  "min": "分",
  "mix": "ミックス",
  "mixed_sources": "マルチソース",
  "mixed_sources_txt_l1": "このグループはマルチソースの部屋を含んでいます",
  "mixed_sources_txt_l2": "続けてソースを選択してください",
  "mixes": "ミックス",
  "mixes_small": "ミックス",
  "mode": "モード",
  "mode_auto": "オート",
  "mode_cooling": "冷房",
  "mode_drying": "ドライ",
  "mode_fan_only": "送風",
  "mode_heating": "暖房",
  "mode_off": "オフ",
  "month": "月",
  "month_april": "四月",
  "month_august": "八月",
  "month_december": "十二月",
  "month_february": "二月",
  "month_january": "一月",
  "month_july": "七月",
  "month_june": "六月",
  "month_march": "三月",
  "month_may": "五月",
  "month_november": "十一月",
  "month_october": "十月",
  "month_september": "九月",
  "month_short_april": "四月",
  "month_short_august": "八月",
  "month_short_december": "十二月",
  "month_short_february": "二月",
  "month_short_january": "一月",
  "month_short_july": "七月",
  "month_short_june": "六月",
  "month_short_march": "三月",
  "month_short_may": "五月",
  "month_short_november": "十一月",
  "month_short_october": "十月",
  "month_short_september": "九月",
  "mood": "ムード",
  "moods": "ムード",
  "music": "音楽",
  "music_choice": "音楽選択",
  "music_go_to": "音楽に行く",
  "music_is_active": "音楽はアクティブです",
  "music_is_playing": "音楽は再生中です",
  "music_off": "音楽Off",
  "music_off_all": "全ての部屋をOff",
  "music_off_all_rooms": "全ての部屋の音楽をOff",
  "music_off_current": "この部屋をOffにする",
  "music_off_current_room": "この部屋の音楽をOffにする",
  "music_off_txt_l1": "一つ又は全ての部屋をOffしようとしています。",
  "music_off_txt_l2": "続けますか？",
  "music_on_current": "この部屋をOn",
  "music_server": "ミュージックサーバー",
  "mute_area_on_call": "着信時音楽をミュート",
  "my_favorites": "お気に入り",
  "my_music": "マイミュージック",
  "name": "名前",
  "nds": "サーバー検索中",
  "nds_music": "ミュージックサーバー検索中",
  "nds_none": "サーバーが見つかりません",
  "nds_none_music": "ミュージックサーバーが見つかりません",
  "nds_text_l1": "数秒かかる場合があります",
  "nds_text_l2": "デバイスがWi-Fiネットワークに接続されていることを確認ください",
  "network": "ネットワーク",
  "network_input_invalid": "全ての項目は正しく記入されていなければなりません",
  "never": "Never",
  "new": "New",
  "new_alarm": "新規アラーム",
  "new_image": "新しいイメージ",
  "new_password": "新しいパスワード",
  "new_password_confirmation": "新しいパスワードの確認",
  "new_playlist": "新規プレイリスト",
  "new_releases": "新規リリース",
  "new_releases_small": "新規リリース",
  "new_scene": "新規シーン",
  "new_schedule": "新規スケジュール",
  "new_step": "新ステップ",
  "next": "次",
  "next_song": "次の曲",
  "no": "No",
  "no_active_rooms": "アクティブな部屋がありません",
  "no_all_off": "電源を切らないでください",
  "no_av": "AV入力がありません",
  "no_configuration": "可能な設定がありません",
  "no_content_available": "コンテンツがありません",
  "no_data": "无数据",
  "no_deezer_dis": "Deezerの接続を解除しないでください",
  "no_devices_capabilities": "調整可能なデバイスがありません",
  "no_doors_available": "ドアがありません",
  "no_favourites": "お気に入りがありません",
  "no_keep_it": "保持してください",
  "no_lights_capabilities": "調整可能な照明がありません",
  "no_linked_homes": "リンクされたホームが見つかりませんでした",
  "none": "None",
  "no_network": "ネットワーク接続がありません",
  "no_preview_available": "プレビュー不可",
  "no_projects": "プロジェクトが見つかりませんでした",
  "no_rooms": "部屋がありません",
  "no_rooms_available": "部屋がありません",
  "no_room_selected": "部屋が選択されていません",
  "no_rooms_listening": "このソースを聞いている部屋はありません",
  "no_scene": "シーンがありません",
  "no_scenes_favourites": "お気に入りのシーンがありません",
  "no_scenes_txt": "表示するシーンを選択してください",
  "no_search_results": "検索結果がありません",
  "no_servers": "サーバーが見つかりません",
  "no_shades_capabilities": "調整可能なシェードがありません",
  "no_source": "-off-",
  "no_sources_available": "ソースがありません",
  "no_steps": "このシーンにはステップが含まれていません",
  "not_allowed": "許可されていません",
  "not_allowed_l1": "あなたはこれを実行する権限がありません",
  "notification": "お知らせ",
  "notification_permission_missing": "Basalte Home は通知を送信する権限がありません。ご使用のデバイスのシステム設定から通知を有効にしてください。",
  "notifications": "通知",
  "not_linked": "リンクされていません",
  "not_now": "後で",
  "not_set": "設定されていません",
  "no_users_l1": "ユーザーが見つかりません",
  "no_users_l2": "ユーザーと共にサーバーを選択してください",
  "now_playing": "現在再生中",
  "number_of_songs": "曲",
  "off": "Off",
  "offline_server": "オフラインサーバー",
  "offset_minutes": "オフセット（分）",
  "ok": "OK",
  "on": "on",
  "onix_black": "黒",
  "online": "オンライン",
  "only_png_jpeg_supported": "PNGとJPEGファイルのみ使用できます",
  "open": "オープン",
  "open_close_door": "ドア",
  "open_close_gate": "ゲート",
  "open_close_window": "窓",
  "open_settings": "設定を開く",
  "open_spotify": "Spotifyを立ち上げる",
  "open_spotify_l1": "Spotifyアプリを立ち上げようとしています",
  "open_state": "オープン",
  "or": "または",
  "order_cameras_by_holding_and_draging": "カメラをドラッグし使用してください",
  "other": "他",
  "out": "アウト",
  "page_order": "ページ/タイル 順序",
  "pair": "ペア",
  "password": "パスワード",
  "password_changed": "パスワードの変更",
  "password_confirmation": "パスワードの確認",
  "password_requirements": "パスワードは8文字以上で大文字、小文字、数字をそれぞれ少なくとも1文字含む必要があります",
  "permission_denied": "認証を拒否されました",
  "permission_denied_go_to_settings_l1": "この機能を正しく使用するには認証が必要です",
  "permission_denied_go_to_settings_l2": "このアプリの設定で全ての機能を有効にすることを許可してください",
  "photo_from_camera": "写真を撮る",
  "photo_from_library": "ライブラリから画像を選択する",
  "pick_room_function": "部屋と機能を選択してください",
  "play": "プレイ",
  "playback_gain": "サイド再生する",
  "playing_in": "再生中",
  "playlist": "プレイリスト",
  "playlists": "プレイリスト",
  "playlists_itunes": "iTunesプレイリスト",
  "playlists_l": "プレイリスト",
  "playlists_local": "ローカルプレイリスト",
  "playlists_shared": "共有プレイリスト",
  "play_next": "次を再生する",
  "play_now": "再生する",
  "please_try_a_different_image": "他の画像を試してください",
  "please_try_again": "もう一度試してください",
  "popular": "ポピュラー",
  "position": "ポジション",
  "precipitation": "降水量",
  "previous": "前",
  "problems_continue_integrator": "問題が発生しましたら担当のインテグレーターに連絡してください。",
  "project": "計画",
  "project_image": "プロジェクト画像",
  "projects_network_error": "ネットワークエラーによりプロジェクトが読み込めません",
  "proximity_activation": "近接起動",
  "proximity_far": "遠",
  "proximity_medium": "中",
  "proximity_near": "近",
  "queue": "キュー",
  "radio": "ラジオ",
  "radio_error": "ラジオステーションの読込にエラーがありました",
  "radio_stations": "ラジオステーション",
  "radio_stations_other": "他ステーション",
  "recently_played": "直近再生",
  "related_artists": "関連したアーティスト",
  "reload": "リロード",
  "remember_me": "記憶してください",
  "remote": "リモート",
  "remote_ellie_intercom_outdated": "リモート Ellie が古いものです",
  "remote_ellie_intercom_outdated_l1": "呼び出そうとした Ellie はソフトウェアの更新が必要です",
  "remote_ellie_intercom_outdated_l2": "アップデート後に再度お試しください",
  "remove": "消去",
  "remove_alarm": "アラーム消去",
  "remove_basalte_favourite": "Basalteのお気に入りを消去",
  "remove_deezer_favourite": "Deezerのお気に入りを消去",
  "remove_from_fav": "お気に入りから消去",
  "remove_schedule": "スケジュール消去",
  "remove_spotify_favourite": "Spotifyのお気に入りを消去",
  "remove_spotify_preset": "Spotifyプリセットを消去",
  "remove_tidal_favourite": "TIDALのお気に入りを消去",
  "remove_txt_l1": "このアイテムを消去しようとしています",
  "remove_txt_l2": "続けてもよろしいですか？",
  "rename": "リネーム",
  "repeat": "リピート",
  "replace_queue": "キューを入れ替える",
  "resend_code": "コードの再送",
  "resend_confirmation_code": "認証コードの再送",
  "reset_image": "画像をリセット",
  "reset_password": "パスワードをリセット",
  "reset_password_l1": "本当にパスワードをリセットしますか",
  "restart": "リスタート",
  "restart_app": "アプリの再起動",
  "restart_app_q": "アプリの再起動",
  "restarting_server": "サーバーを再起動中",
  "restart_music_server": "ミュージックサーバーを再起動する",
  "restart_music_server_txt_l1": "ミュージックサーバーを再起動しようとしてます",
  "restart_server": "サーバーを再起動",
  "restart_server_txt_l1": "サーバーを再起動しようとしています",
  "restart_server_txt_l2": "アプリケーションは自動的に再接続されます",
  "retrieving": "読込中・・・",
  "retrieving_users_stop_text": "ユーザーの読込に失敗しました。サーバーを修正しますか？",
  "retrieving_users_text": "ユーザー読込中・・・",
  "right": "右",
  "room": "部屋",
  "room_attic": "屋根裏",
  "room_ball_room": "ボールルーム",
  "room_bar": "バー",
  "room_basement": "地下",
  "room_bathroom": "浴室",
  "room_bedroom": "寝室",
  "room_corridor": "廊下",
  "room_dining_room": "ダイニング",
  "room_dressing_room": "更衣室",
  "room_driveway": "ドライブウェイ",
  "room_entry": "エントリー",
  "room_family_room": "ファミリールーム",
  "room_foyer": "フォイヤー",
  "room_garage": "ガレージ",
  "room_garden": "庭",
  "room_guest_room": "客室",
  "room_gym": "ジム",
  "room_hallway": "玄関ホール",
  "room_hobby_room": "ホビールーム",
  "room_home_cinema": "ホームシネマ",
  "room_home_office": "書斎",
  "room_images": "部屋の画像",
  "room_intercom_lost": "接続が切れました",
  "room_intercom_lost_sub": "コール接続が切れました、再度試してください",
  "room_intercom_unavailable": "無応答",
  "room_intercom_unavailable_sub": "呼び出しに応答がありません",
  "room_intercom_unreachable": "無応答",
  "room_intercom_unreachable_sub": "呼び出しに応答がありません",
  "room_kitchen": "キッチン",
  "room_laundry_room": "ランドリールーム",
  "room_library": "ライブラリー",
  "room_living_room": "リビング",
  "room_master_bedroom": "主寝室",
  "room_media_room": "メディアルーム",
  "room_meeting_room": "会議室",
  "room_nook": "隅",
  "room_nursery": "子供部屋",
  "room_pantry": "パントリー",
  "room_parking_place": "駐車場",
  "room_patio": "中庭",
  "room_pool": "プール",
  "room_pool_house": "プールハウス",
  "room_rack": "ラック",
  "room_room": "部屋",
  "rooms": "部屋",
  "room_scene_no_functions": "この部屋には機能がありません",
  "room_settings": "部屋設定",
  "room_spa": "スパ",
  "room_stable": "安定した",
  "room_staircase": "階段",
  "room_terrace": "テラス",
  "room_toilet": "トイレ",
  "room_utility_room": "ユーティリティ",
  "room_wine_cellar": "ワインセラー",
  "room_workshop": "ワークショップ",
  "rotation": "ローテーション",
  "save": "保存",
  "save_as": "名前を付けて保存",
  "save_basalte_favourite": "Basalteのお気に入りを保存する",
  "save_changes": "変更を保存する",
  "save_changes_l1": "一部変更がまだ保存されていません",
  "save_changes_l2": "それらを保存しますか？",
  "save_deezer_favourite": "Deezerのお気に入りを保存する",
  "save_spotify_favourite": "Spotifyのお気に入りを保存する",
  "save_spotify_preset": "Spotiyのプリセットを保存する",
  "save_tidal_favourite": "TIDALのお気に入りを保存する",
  "scene": "シーン",
  "scene_activated": "シーン起動",
  "scene_away": "不在",
  "scene_cooking": "料理",
  "scene_dinner": "食事",
  "scene_error": "シーンエラー",
  "scene_evening": "夕方",
  "scene_good_morning": "おはよう",
  "scene_good_night": "おやすみ",
  "scene_guests": "ゲスト",
  "scene_home": "ホーム",
  "scene_image_error": "シーン画像エラー",
  "scene_movie": "映画",
  "scene_not_available": "シーンの再生はされていません",
  "scene_not_selected_l1": "シーンが選択されていません",
  "scene_not_selected_l2": "シーンを選択してください",
  "scene_party": "パーティ",
  "scene_relax": "リラックス",
  "scenes": "シーン",
  "scene_select_or_new": "シーンを選択するか新規シーンを作成してください",
  "scene_vacation": "休暇",
  "scene_welcome": "ウェルカム",
  "scene_workout": "ワークアウト",
  "schedule": "タイマー",
  "schedule_at": "スケジュール時間",
  "schedule_not_selected_l1": "スケジュールが選択させていません",
  "schedule_not_selected_l2": "スケジュールを選択してください",
  "scheduler": "スケジューラー",
  "schedules": "スケジュール",
  "schedule_select_or_new": "スケジュールを選択するか新規スケジュールを作成してください",
  "screen_orientation": "画面の向き",
  "search": "検索",
  "search_again": "再検索",
  "search_ph": "アーティスト、曲もしくはアルバムを検索する",
  "search_result": "検索結果",
  "security": "セキュリティー",
  "select_admin": "管理者プロファイルを選択して、更新プロセスを続行します",
  "select_home": "自宅を選択してください",
  "selection": "セレクション",
  "selection_all": "全てをセレクションに加える",
  "select_photo": "画像を選択",
  "select_server": "サーバーを選択する",
  "select_user": "ログインの為のプロフィールを選択する",
  "serial_number": "シリアル番号",
  "server": "サーバー",
  "server_address": "サーバーアドレス",
  "server_basalte_cloud_no_connection": "サーバーが Basalteクラウドに接続できません",
  "server_exists_already": "既にサーバーが存在します",
  "server_mac": "サーバーMACアドレス",
  "servers": "サーバー",
  "server_status": "サーバーステータス",
  "server_update_info_l2": "Basalteサーバーのバージョンを最新へ更新してください",
  "server_update_required": "サーバーの更新が必要です",
  "server_version": "サーバーバージョン",
  "set": "設定",
  "set_new_password": "新しいパスワードを設定",
  "setpoint": "設定値",
  "settings": "設定",
  "shade_blind": "ブラインド",
  "shade_center": "中央",
  "shade_curtain": "カーテン",
  "shade_left": "左",
  "shade_pool_shutter": "プールシャッター",
  "shade_right": "右",
  "shade_roman_blind": "ローマンシェード",
  "shades": "シェード",
  "shade_shade": "シェード",
  "shade_shutter": "シャッター",
  "shade_solar_protection": "遮光",
  "shade_venetian_blind": "ベネチアンブラインド",
  "shade_vertical_blind": "バーチカルブラインド",
  "share": "共有",
  "shared_playlist": "共有プレイリスト",
  "show_album": "アルバムを表示する",
  "show_artist": "アーティストを表示する",
  "show_background_art": "背景画像を表示する",
  "show_more": "Show more…",
  "show_status_bar": "iPadステータスバーを表示する",
  "show_status_bar_device": "ステータスバーを表示する",
  "sign_in": "サインイン",
  "sign_in_info_tidal_l1": "TIDALのログイン情報を記入してください",
  "sign_up": "サインアップ",
  "single_room": "部屋",
  "sleep": "休止",
  "software_update": "ソフトウェア更新",
  "something_went_wrong": "失敗しました",
  "songs": "曲",
  "sonos": "Sonos",
  "sonos_unreachable": "Sonos が見つかりません",
  "sonos_unreachable_txt_l1": "デバイスの電源が入っておりネットワークに接続されていることを確認してください",
  "sound_settings": "サウンド設定",
  "source": "ソース",
  "sources": "ソース",
  "spotify": "Spotify",
  "spotify_beta": "Spotify (beta)",
  "spotify_connect": "Spotify Connect",
  "spotify_connect_instructions": "Spotify Connect",
  "spotify_connect_instructions_1": "Spotifyアプリを使用しているデバイスがCoreサーバと同じネットワークにあることを確認してください",
  "spotify_connect_instructions_2": "Spotifyアプリのdevice pickerよりCoreサーバのストリームを選択してください",
  "spotify_connect_instructions_body": "Spotifyアプリをリモートで使用し、スピーカーやTVで鑑賞する",
  "spotify_connect_instructions_title": "音楽を再生しますか？",
  "spotify_disconnect_txt": "プレーヤーからSpotifyアカウントを削除しようとしています",
  "spotify_free_user_txt": "Spotify Premium の申し込みが必要です",
  "spotify_no_link": "Spotifyアカウントのリンクがありません",
  "spotify_oauth_error": "Spotify認証エラー",
  "spotify_preset_error": "Spotifyエラー",
  "spotify_preset_error_l1": "Spotifyプリセットがロードできませんでした",
  "spotify_preset_error_l2": "有効なSpotifyアカウントがストリーミングに接続されていることを確認してください",
  "spotify_presets": "Spotifyプリセット",
  "start": "スタート",
  "start_page": "スタートページ",
  "start_page_customisation": "スタートページの編集",
  "start_time_date": "日付と時間",
  "startup_view": "スタートアップビュー",
  "startup_volume": "スタートアップ音量",
  "start_weather": "気象情報",
  "state_active": "アクティブ",
  "state_armed": "Armed",
  "state_closed": "クローズ",
  "state_disabled": "無効",
  "state_disarmed": "Disarmed",
  "state_down": "ダウン",
  "state_enabled": "有効",
  "state_false": "False",
  "state_free": "Free",
  "state_high": "High",
  "state_in": "In",
  "state_inactive": "インアクティブ",
  "state_left": "左",
  "state_low": "Low",
  "state_occupied": "Occupied",
  "state_off": "Off",
  "state_on": "On",
  "state_opened": "オープン",
  "state_out": "Out",
  "state_right": "右",
  "state_started": "再生中",
  "state_stopped": "停止",
  "state_true": "True",
  "state_up": "アップ",
  "static": "Static",
  "stations": "ステーション",
  "status": "ステータス",
  "stop": "ストップ",
  "stream": "ストリーム",
  "streaming": "ストリーミング",
  "streaming_services": "ストリーミングサービス",
  "streams": "ストリーム",
  "streams_beta": "ストリーム（ベータ）",
  "subnet_mask": "サブネットマスク",
  "sun": "太陽",
  "sunrise": "日の出",
  "sunset": "日の入",
  "swipe_to_pan": "スワイプしてパンする",
  "switch_server": "サーバーを変更する",
  "synchronise": "スキャンする",
  "system": "システム",
  "system_setting": "システム設定",
  "temperature": "温度",
  "temperature_unit": "温度単位",
  "theme": "テーマ",
  "thermostat": "サーモスタット",
  "this_cannot_be_undone": "この操作を元に戻すことはできません。",
  "this_week": "今週",
  "tidal": "TIDAL",
  "tidal_disconnect_txt": "プレーヤーからTIDALアカウントを削除しようとしています",
  "tidal_legacy_auth": "TIDALログインが必要です",
  "tidal_legacy_auth_l1": "TIDALを引き続きご利用いただくには、再度ログインしてください。",
  "tidal_no_link": "TIDALアカウントのリンクがありません",
  "tidal_oauth_error": "TIDAL認証エラー",
  "tidal_rising": "TIDAL起動中",
  "time": "時間",
  "time_format": "タイムフォーマット",
  "timer": "タイマー",
  "timers": "タイマー",
  "title": "タイトル",
  "today": "今日",
  "tomorrow": "明日",
  "top_tracks": "トップトラック",
  "treble": "高音",
  "try_again": "再度試してください",
  "try_again_later": "後程再度お試しください",
  "try_again_later_or_contact_support": "後程再度お試しいただくかサポートに連絡してください",
  "turned_off": "offにする",
  "turned_on": "onにする",
  "turn_off": "offにする",
  "turn_on": "onにする",
  "turn_on_default_rooms": "このソースのデフォルトルームをonにしますか？",
  "turn_on_music": "音楽をオンにする",
  "turn_on_tv": "テレビをつける",
  "tv": "テレビ",
  "tv_off": "テレビオフ",
  "tv_off_current_room": "この部屋のテレビを消す",
  "unavailable": "無効です",
  "unavailable_src": "無効なソース",
  "unavailable_src_txt": "現在、そのソースは無効です",
  "ungroup": "グループ化を解除",
  "unknown": "不明",
  "unknown_account": "不明なアカウント",
  "unknown_account_l1": "このEメールアドレスは Basalte のアカウントではありません",
  "unknown_av": "不明なAV入力",
  "unknown_src": "不明なソース",
  "unknown_src_txt": "現在のソースはこのサーバーに認識されていません",
  "unlink": "リンクの解除",
  "unlink_server": "Basalte アカウントからのリンクを解除",
  "unlink_server_full_sub": "Basalte アカウントからのリンクを解除しますか？あなたのローカルネットワークの外からアクセスすることが出来なくなります。",
  "unreachable": "到達不能",
  "unshare": "共有しない",
  "unsupported_browser": "サポートされていないブラウザ",
  "unsupported_browser_l1": "一部機能が期待通りに動かないかもしれません",
  "unsupported_browser_l2": "快適に使用するためにはChromeもしくはSafariをご使用ください",
  "unsupported_browser_live": "このブラウザはBasalteアカウントでサポートされていません。最新バージョンの Chrome 又は Safari をご使用ください。",
  "up": "上昇",
  "update": "更新",
  "update_available": "更新",
  "update_available_full": "更新があります",
  "update_info_l1": "最高の体験を手に入れるために",
  "update_library": "ミュージックライブラリを再スキャンします",
  "update_music_server": "ミュージックサーバーを更新する",
  "update_scene": "シーンを更新する",
  "update_scene_l1": "現在の状態をこのシーンに保存する",
  "update_server": "サーバーを更新する",
  "updating_server": "サーバー更新中",
  "updating_server_info": "サーバーの電源を切らないでください",
  "up_to_date": "最新の",
  "username": "ユーザー名",
  "uv": "紫外線",
  "value_invalid": "入力された値は無効です",
  "verify": "確認",
  "verifying": "検証中",
  "version": "バージョン",
  "video_background": "背景動画",
  "video_go_to": "テレビに行く",
  "video_is_active": "テレビは有効です",
  "video_is_playing": "ビデオは再生中です",
  "video_unavailable": "テレビ又はオーディオシステムが有効ではありません",
  "volume": "音量",
  "warning": "警告",
  "water": "水",
  "weather": "天気",
  "weather_daily_data_unavailable": "日付毎の気象情報がありません",
  "weather_hourly_data_unavailable": "時間毎の気象情報がありません",
  "weather_information": "天気予報",
  "week": "週",
  "weekly": "週間",
  "what": "何",
  "what_music": "どんな音楽",
  "when": "いつ",
  "where": "どこで",
  "who": "誰が",
  "wind": "風",
  "wrong_password": "誤ったパスワード",
  "wrong_password_txt": "入力されたパスワードがユーザーパスワードと一致しません",
  "wrong_username_and_or_password": "ユーザー名もしくはパスワードが間違っています",
  "year": "年",
  "yes": "はい",
  "yes_all_off": "はい、全てoffにします",
  "yes_cur_off": "はい、offにします",
  "yes_deezer_dis": "はい、Deezerアカウントの接続を解除します",
  "yes_delete_it": "はい、削除します",
  "yesterday": "昨日",
  "zone": "部屋",
  "zones": "部屋"
}
