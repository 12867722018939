'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasSip', [
    '$window',
    '$rootScope',
    'CurrentBasCore',
    'BAS_CORE_CLIENT',
    'BAS_ROOMS',
    'BAS_CURRENT_CORE',
    'BAS_ROOM',
    'BAS_SIP',
    'BAS_VOLUME',
    'BAS_API',
    'BasCamera',
    'BasAppDevice',
    'BasDeviceSip',
    'BasCoreClient',
    'BasCoreClientHelper',
    'BasOpenCloseDevices',
    'BasScreen',
    'BasVolume',
    'RoomsHelper',
    'BasUtilities',
    BasSip
  ])

/**
 * @typedef {Object} TBasButtonPluginObject
 * @property {string} uuid
 * @property {string} name
 * @property {string} username
 * @property {string} password
 * @property {number} relay
 */

/**
 * @typedef {Object} TBasDoorPhoneWithCameras
 * @property {string} name
 * @property {string} uuid
 * @property {string} username
 * @property {string} password
 * @property {string} doorPhoneIdentifier
 * @property {boolean} useDefaultVideo
 * @property {boolean} enableDoor
 * @property {TBasButtonPluginObject[]} buttons
 * @property {TBasCameraPluginObject[]} cameras
 */

/**
 * @typedef {Object} TBasCoreSipConfig
 * @property {string} user
 * @property {string} password
 * @property {string} domain
 * @property {string} flavor
 */

/**
 * @constructor
 * @param $window
 * @param $rootScope
 * @param {CurrentBasCore} CurrentBasCore
 * @param BAS_CORE_CLIENT
 * @param BAS_ROOMS
 * @param BAS_CURRENT_CORE
 * @param BAS_ROOM
 * @param BAS_SIP
 * @param BAS_VOLUME
 * @param BAS_API
 * @param BasCamera
 * @param {BasAppDevice} BasAppDevice
 * @param {BasDeviceSip} BasDeviceSip
 * @param {BasCoreClient} BasCoreClient
 * @param {BasCoreClientHelper} BasCoreClientHelper
 * @param {BasOpenCloseDevices} BasOpenCloseDevices
 * @param {BasScreen} BasScreen
 * @param {BasVolume} BasVolume
 * @param {RoomsHelper} RoomsHelper
 * @param {BasUtilities} BasUtilities
 */
function BasSip (
  $window,
  $rootScope,
  CurrentBasCore,
  BAS_CORE_CLIENT,
  BAS_ROOMS,
  BAS_CURRENT_CORE,
  BAS_ROOM,
  BAS_SIP,
  BAS_VOLUME,
  BAS_API,
  BasCamera,
  BasAppDevice,
  BasDeviceSip,
  BasCoreClient,
  BasCoreClientHelper,
  BasOpenCloseDevices,
  BasScreen,
  BasVolume,
  RoomsHelper,
  BasUtilities
) {

  const INTERCOM_SIP_PORT = 9820

  var SYNC_DEBOUNCE_TIME_MS = 1000

  var K_GATEWAY_TYPE = 'gatewayType'
  var K_GATEWAY_UUID = 'gatewayUuid'
  var K_DOOR_PHONES = 'doorPhones'
  var K_DOMAIN = 'domain'
  var K_PORT = 'port'
  var K_USERNAME = 'username'
  var K_PASSWORD = 'password'
  var K_MJPEG = 'mjpeg'
  var K_DTMF_DOOR = 'dtmfDoor'
  var K_DTMF_LIGHT = 'dtmfLight'
  var K_UPDATE_CORE_SIP_CONFIG = 'updateCoreSipConfig'
  var K_OPEN_CLOSE = 'openClose'
  var K_DATA = 'data'
  var K_USE_HOME_API = 'useHomeAPI'
  var K_STATE = 'active'

  var basTm = $window.basTModule

  var doorPhoneRegistrationStateMapping = {}

  /**
   * @type {TCurrentBasCoreState}
   */
  var currentBasCoreState = CurrentBasCore.get()

  /**
   * @type {TBasVolumeState}
   */
  var basVolumeState = BasVolume.get()

  /**
   * Keep track of core sip params
   *
   * @type {TBasCoreSipConfig}
   */
  var coreSipConfig = {
    user: '',
    password: '',
    domain: '',
    flavor: ''
  }

  var _syncSipConfigDebounceTimeoutId = 0
  let _syncIpNameMapDebounceTimeoutId = 0
  var _syncVolumeTimeoutId = 0

  var _newerSipConfigAvailable = false

  const doorPhoneTopicListeners = []

  this.get = get
  this.isInDoorPhoneCall = isInDoorPhoneCall
  this.getUsername = getUsername
  this.clearConfig = clearConfig
  this.callRoom = callRoom

  init()

  function init () {

    var basSip

    _resetUiSipState()

    basSip = _getBasSip()

    if ((BasAppDevice.isCoreClient()) && basSip) {

      basSip.init((setError, _setResult) => {
        if (!setError) {
          _syncVolume()
        }
      }, basSip.VOLUME_USAGE_ALARM)
      basSip.addListener(_onSipMessage)

      doorPhoneRegistrationStateMapping[basSip.REGISTRATION_STATE_CLEARED] =
        BAS_SIP.SIP_CLEARED
      doorPhoneRegistrationStateMapping[basSip.REGISTRATION_STATE_FAILED] =
        BAS_SIP.SIP_FAILED
      doorPhoneRegistrationStateMapping[basSip.REGISTRATION_STATE_NONE] =
        BAS_SIP.SIP_NO_CONFIGURATION
      doorPhoneRegistrationStateMapping[basSip.REGISTRATION_STATE_OK] =
        BAS_SIP.SIP_CONNECTED
      doorPhoneRegistrationStateMapping[basSip.REGISTRATION_STATE_PROGRESS] =
        BAS_SIP.SIP_CONNECTING

      $rootScope.$on(
        BAS_CURRENT_CORE.EVT_CORE_CORE_V2_CONNECTED,
        _onSubscriptionCoreConnected
      )

      $rootScope.$on(
        BAS_ROOMS.EVT_ROOMS_UPDATED,
        _onRoomsUpdated
      )

      $rootScope.$on(
        BAS_CURRENT_CORE.EVT_CORE_DEVICES_UPDATED,
        _onDevicesUpdated
      )

      $rootScope.$on(
        BAS_ROOM.EVT_DOOR_PHONE_GATEWAYS_UPDATED,
        _onDoorPhoneGatewaysUpdated
      )

      $rootScope.$on(
        BAS_ROOM.EVT_DOOR_PHONES_UPDATED,
        _onDoorPhonesUpdated
      )

      $rootScope.$on(
        BAS_ROOM.EVT_SECURITY_UPDATED,
        _onCamerasUpdated
      )

      $rootScope.$on(
        BAS_CORE_CLIENT.EVT_CORE_CLIENT_MAC_ADDRESS,
        _onCoreClientMacAddress
      )

      $rootScope.$on(
        BAS_ROOM.EVT_CORE_CLIENTS_UPDATED,
        _onCoreClientsUpdated
      )

      $rootScope.$on(
        BAS_VOLUME.EVT_VOLUME_CHANGED,
        _onVolumeChanged
      )

      $rootScope.$on(
        '$translateChangeSuccess',
        _onLanguageChanged
      )

      updateSipTranslations()
      debouncedSendIpNameMap()
    }
  }

  /**
   * @returns {TBasSipState}
   */
  function get () {
    return BAS_SIP.STATE
  }

  /**
   * @returns {boolean}
   */
  function isInDoorPhoneCall () {

    var basSip = _getBasSip()

    return basSip && basSip.uiVisible
  }

  // TODO: Place in a generic 'BasalteDevice' service
  /**
   * @returns {string}
   */
  function getUsername () {

    if (BasAppDevice.isCoreClient()) {

      return BasCoreClient.getUsername()
    }
    return ''
  }

  function clearConfig () {

    var basSip = _getBasSip()

    if (basSip) {

      coreSipConfig.user = ''
      coreSipConfig.password = ''
      coreSipConfig.domain = ''
      coreSipConfig.flavor = ''

      basSip.clear()
    }
  }

  /**
   * @param {BasRoom} basRoom
   */
  function callRoom (basRoom) {

    /**
     * @type {?BasCoreClientDevice}
     */
    const ccd = basRoom.coreClientDevices
      ?.coreClients
      ?.find(el => el.receiveIntercom)

    if (ccd) callSipAddress(`sip:${ccd.ip}:${INTERCOM_SIP_PORT}`)
  }

  /**
   * @param {string} sipAddress
   */
  function callSipAddress (sipAddress) {

    _getBasSip()?.callSipAddress(sipAddress)
  }

  /**
   * Fired when SIP plugin sends messages
   *
   * @private
   * @param {?Object} message
   * @param {string} message.type
   * @param {*} [message.data]
   */
  function _onSipMessage (message) {

    var basSip

    basSip = _getBasSip()

    if (BasUtil.isObject(message) && basSip) {

      switch (message[basSip.K_TYPE]) {
        case basSip.T_REGISTRATION_STATE:

          _syncSipConfig()

          break
        case basSip.T_ADELANTE_REGISTRATION_STATE:

          syncAdelanteState()

          break
        case basSip.T_KEEP_SCREEN_ON:

          BasScreen.keepScreenOn()

          break
        case basSip.T_RELEASE_KEEP_SCREEN_ON:

          BasScreen.releaseKeepScreenOn()

          break
        default:

          if (BasAppDevice.isCoreClient()) {

            BasCoreClient.getBasCoreClientDevice()
              .then(_handleDoorPhoneMessage, _ignore)
          }
      }
    }

    /**
     * @private
     * @param {BasCoreClientDevice} result
     */
    function _handleDoorPhoneMessage (result) {

      var doorPhoneUuid

      basSip = _getBasSip()

      if (basSip && result) {

        // Can be used in the future for additional features
        doorPhoneUuid = ''

        switch (message[basSip.K_TYPE]) {
          case basSip.T_CALL_INCOMING:

            result.setCallStateToIncoming(doorPhoneUuid)
            $rootScope.$emit(BAS_SIP.EVT_SIP_CALL)

            break
          case basSip.T_CALL_ANSWERED:

            result.setCallStateToOngoing(doorPhoneUuid)

            break
          case basSip.T_CALL_ENDED:

            result.setCallStateToIdle(doorPhoneUuid)
            _onDoorPhoneConfigChanged()
            break
          case basSip.T_DOOR_OPENED:

            if (
              BasUtil.isObject(message) &&
              BasUtil.isObject(message[K_DATA]) &&
              BasUtil.isNEString(message[K_DATA][K_OPEN_CLOSE])
            ) {

              if (message[K_DATA][K_USE_HOME_API]) {

                currentBasCoreState.core?.core?.doorPhoneTopic?.openDoor(
                  message[K_DATA][K_OPEN_CLOSE],
                  message[K_DATA][K_STATE]
                ).then(_ => {})
              } else {

                const openCloseDeviceUuid = message[K_DATA][K_OPEN_CLOSE]
                const openCloseDevice =
                  BasOpenCloseDevices.getOpenCloseDeviceByUuid(
                    openCloseDeviceUuid
                  )

                if (openCloseDevice && openCloseDevice.device) {

                  if (openCloseDevice.canTrigger()) {
                    openCloseDevice.trigger(message[K_DATA][K_STATE])
                  }
                }
              }
            }
            break
        }
      }
    }
  }

  function _onSubscriptionCoreConnected (_event, core, isConnected) {

    if (isConnected) {
      getSipAddresses()

      doorPhoneTopicListeners.push(BasUtil.setEventListener(
        core.core?.doorPhoneTopic,
        BAS_API.DoorPhoneTopic.EVT_SIP_ADDRESSES_CHANGED,
        getSipAddresses
      ))
    } else {
      BasUtil.executeArray(doorPhoneTopicListeners)
    }
  }

  async function getSipAddresses () {
    const core = currentBasCoreState.core.core
    return core?.doorPhoneTopic.getSipAddresses()
      .then((addresses) => {

        $rootScope.$emit(BAS_SIP.EVT_SIP_ADDRESSES_INITALIZED, addresses)

        addresses.forEach(el => {
          const address = `sip:${el.sipAddress}`
          core.doorPhoneTopic.getExtraCameras(el.doorPhone.uuid)
            .then(cameras => {
              _getBasSip()?.setDoorPhoneCameras(
                address,
                cameras.map(cam => new BasCamera(cam).getPluginObj(false))
              )
            })

          _getBasSip()?.setDoorPhoneDoors(
            address,
            el.doors
          )
        })

        _getBasSip()?.setSipAddresses({
          serverIp: CurrentBasCore.getServer()?._host?.host,
          addresses: addresses
        })
      })
      // Addresses should be empty when
      //  user switched to server without door phone topic
      .catch(() =>
        _getBasSip()?.setSipAddresses({
          serverIp: CurrentBasCore.getServer()?._host?.host,
          addresses: []
        }))
  }

  /**
   * @private
   */
  function _onRoomsUpdated () {

    _onDoorPhoneConfigChanged()
    debouncedSendIpNameMap()
  }

  function _onDevicesUpdated () {
    debouncedSendIpNameMap()
  }

  function debouncedSendIpNameMap () {

    clearTimeout(_syncIpNameMapDebounceTimeoutId)
    _syncIpNameMapDebounceTimeoutId = setTimeout(
      _syncIpNameMap,
      SYNC_DEBOUNCE_TIME_MS
    )
  }

  function _syncIpNameMap () {
    const map = {}
    RoomsHelper.forEachRoom(room => {
      room.coreClientDevices?.coreClients.forEach(ccd => {
        if (ccd.ip) map[`sip:${ccd.ip}:${INTERCOM_SIP_PORT}`] = room.uiTitle
      })
    })
    _getBasSip()?.setSipNameMap(map, null)
  }

  /**
   * @private
   */
  function _onCoreClientsUpdated () {

    _onDoorPhoneConfigChanged()
  }

  /**
   * @private
   */
  function _onDoorPhoneGatewaysUpdated () {

    _onDoorPhoneConfigChanged()
  }

  /**
   * @private
   */
  function _onDoorPhonesUpdated () {

    _onDoorPhoneConfigChanged()
  }

  /**
   * @private
   */
  function _onCamerasUpdated () {

    _onDoorPhoneConfigChanged()
  }

  function _onCoreClientMacAddress () {

    _onDoorPhoneConfigChanged()
  }

  /**
   * @private
   */
  function _onDoorPhoneConfigChanged () {

    clearTimeout(_syncSipConfigDebounceTimeoutId)
    _syncSipConfigDebounceTimeoutId = setTimeout(
      _syncDoorPhoneConfig,
      SYNC_DEBOUNCE_TIME_MS
    )
  }

  /**
   * @private
   */
  function _onVolumeChanged () {

    clearTimeout(_syncVolumeTimeoutId)
    _syncVolumeTimeoutId = setTimeout(
      _syncVolume,
      SYNC_DEBOUNCE_TIME_MS
    )
  }

  /**
   * @private
   */
  function _onLanguageChanged () {

    updateSipTranslations()
    _syncSipConfig()
    debouncedSendIpNameMap()
  }

  /**
   * @private
   */
  function _syncSipConfig () {

    var basSip, configState
    var regStateDetail, regStateDetailMapping
    var registrationStateSimple, cleared

    _resetUiSipState()

    basSip = _getBasSip()

    if (basSip) {

      configState = basSip.configState
      regStateDetail = configState.registrationState

      cleared = (
        regStateDetail === basSip.REGISTRATION_STATE_CLEARED ||
        regStateDetail === basSip.REGISTRATION_STATE_NONE
      )

      registrationStateSimple =
        regStateDetail === basSip.REGISTRATION_STATE_OK
          ? 'yes'
          : 'no'

      regStateDetailMapping =
        doorPhoneRegistrationStateMapping[regStateDetail]

      if (configState &&
        BasUtil.isNEObject(configState) &&
        !cleared) {

        BAS_SIP.STATE.uiSipStateDomain = configState.domain
        BAS_SIP.STATE.uiSipStateUsernames = configState.usernames
        BAS_SIP.STATE.uiSipStateRegistrationSimple =
          BasUtilities.translate(registrationStateSimple)

        // Check for valid mapping
        if (regStateDetailMapping) {

          BAS_SIP.STATE.uiShowSipState = true
          BAS_SIP.STATE.uiSipStateRegistrationDetailed =
            BasUtilities.translate(regStateDetailMapping)

        } else {

          BAS_SIP.STATE.uiShowSipState = false
        }

      } else {

        BAS_SIP.STATE.uiShowSipState = true
        BAS_SIP.STATE.uiSipStateRegistrationSimple =
          BasUtilities.translate('no')
        BAS_SIP.STATE.uiSipStateRegistrationDetailed =
          BasUtil.isNEString(regStateDetailMapping)
            ? BasUtilities.translate(regStateDetailMapping)
            : BasUtilities.translate('unknown')
      }

      $rootScope.$emit(BAS_SIP.EVT_SIP_CONFIG_CHANGED)
    }
  }

  function syncAdelanteState () {
    const basSip = _getBasSip()

    if (basSip) {
      const hasAdelante = !!basSip.adelanteRegistrationStates.length
      BAS_SIP.ADELANTE_STATE.hasAdelante = hasAdelante
      BAS_SIP.ADELANTE_STATE.states = basSip.adelanteRegistrationStates
        .map((state) => ({
          ...state,
          hasError: state.state !== basSip.REGISTRATION_STATE_OK
        }))

      if (hasAdelante) {
        const hasError = basSip.adelanteRegistrationStates.some((state) => {
          return state.state !== basSip.REGISTRATION_STATE_OK
        })

        BAS_SIP.ADELANTE_STATE.globalState = {
          domain: basSip.adelanteRegistrationStates[0].domain,
          hasError: hasError,
          status: hasError
            ? BasUtilities.translate('error')
            : BasUtilities.translate('ok')
        }
      }

      // Adelante state is used in views without controllers, so we need to call
      //  `$rootScope.$applyAsync` to make sure that they are updated too.
      $rootScope.$applyAsync()
      $rootScope.$emit(BAS_SIP.EVT_ADELANTE_STATES_CHANGED)
    }
  }

  /**
   * @private
   */
  function _syncDoorPhoneConfig () {

    var basSip, config

    basSip = _getBasSip()
    BAS_SIP.STATE.hasDoorPhone = false

    if (basSip) {

      config = _getDoorPhoneConfig()

      if (BasUtil.isObject(config)) {

        BAS_SIP.STATE.hasDoorPhone = true
        basSip.init(_onInit, basSip.VOLUME_USAGE_ALARM)

      } else if (config === false) {

        clearConfig()
      }
    }

    /**
     * Callback of init function
     *
     * Expects error if init is ongoing
     * Expects true if init is successful
     * Expects false if init was already successful
     *
     * @private
     * @param {?string} error
     * @param {boolean} [result]
     */
    function _onInit (error, result) {

      var doCoreSipUpdate = false

      if (error) {

        if (error === basSip.ARG_BUSY) {

          // Previous "BasSip.init" is ongoing
          _newerSipConfigAvailable = true
        }

      } else {

        if (_newerSipConfigAvailable) {

          _newerSipConfigAvailable = false
          _onDoorPhoneConfigChanged()

        } else {

          config = _getDoorPhoneConfig()

          if (BasUtil.isObject(config)) {

            if (BasUtil.isString(config[K_USERNAME]) &&
              config[K_USERNAME] !== coreSipConfig.user) {

              coreSipConfig.user = config[K_USERNAME]
              doCoreSipUpdate = true
            }

            if (BasUtil.isString(config[K_PASSWORD]) &&
              config[K_PASSWORD] !== coreSipConfig.password) {

              coreSipConfig.password = config[K_PASSWORD]
              doCoreSipUpdate = true
            }

            if (BasUtil.isString(config[K_DOMAIN]) &&
              config[K_DOMAIN] !== coreSipConfig.domain) {

              coreSipConfig.domain = config[K_DOMAIN]
              doCoreSipUpdate = true
            }

            if (BasUtil.isString(config[K_GATEWAY_TYPE]) &&
              config[K_GATEWAY_TYPE] !== coreSipConfig.flavor) {

              coreSipConfig.flavor = config[K_GATEWAY_TYPE]
              doCoreSipUpdate = true
            }

            config[K_UPDATE_CORE_SIP_CONFIG] = doCoreSipUpdate

            // Result is only true once on successful init
            if (result && basTm) {
              basTm.logEvt({
                evtType: basTm.T_APP_SIP,
                evtSubType: coreSipConfig.flavor
              })
            }

            basSip.set(config, _onSet)
          }
        }
      }

      /**
       * Callback of set function
       *
       * Expects error if set was still ongoing
       * Sets deviceParams if wanted
       *
       * @private
       * @param {string} [setError]
       * @param {string} [_setResult]
       */
      function _onSet (setError, _setResult) {

        if (setError) {

          if (setError === basSip.ARG_BUSY) {

            // Previous "BasSip.set" is ongoing
            _newerSipConfigAvailable = true

          } else {

            // eslint-disable-next-line no-console
            console.error('BasSip - Error BasSip.set', setError)
          }

        } else {

          // "BasSip.set" done
          // "BasSip.set" again if newer config is available
          if (_newerSipConfigAvailable) {

            _newerSipConfigAvailable = false
            _onDoorPhoneConfigChanged()

          } else if (doCoreSipUpdate) {

            // Device params are set on linphone manager
            // So config needs to be set before device params
            BasDeviceSip.setDeviceParams(
              null,
              _onDeviceParams
            )
          }
        }
      }
    }
  }

  /**
   * @private
   */
  function _syncVolume () {

    var basSip, doNotDisturb

    basSip = _getBasSip()

    if (basSip) {

      doNotDisturb = basVolumeState.muted

      if (BasUtil.isBool(doNotDisturb)) {

        basSip.setDoNotDisturb(doNotDisturb)
      }
    }
  }

  function _onDeviceParams (error) {

    if (error) {

      // eslint-disable-next-line no-console
      console.error('BasSip - Error setting device params', error)
    }
  }

  /**
   * Returns config if there is one.
   * Returns null if basCoreClientInfo is not yet received,
   * or no ellie profile is present
   * Returns false if basCoreClientInfo does not contain a config.
   *
   * @private
   * @returns {(?Object|boolean)}
   */
  function _getDoorPhoneConfig () {

    var basCoreClientInfo, gateway, doorPhoneConfig
    var result, doorPhoneList, filter

    if (!CurrentBasCore.hasCore()) return null

    if (BasAppDevice.isCoreClient()) {

      basCoreClientInfo = BasCoreClientHelper.getBasCoreClientInfo()

      if (basCoreClientInfo && basCoreClientInfo.device) {

        doorPhoneConfig =
          basCoreClientInfo.device.getDoorPhoneConfig()

      } else {

        // Core client device not found (yet)
        return null
      }
    } else {

      return null
    }

    if (doorPhoneConfig) {

      result = {}

      if (doorPhoneConfig.gateway) {

        gateway = RoomsHelper
          .getDoorPhoneGatewayByUuid(doorPhoneConfig.gateway)

        if (gateway) {

          result[K_GATEWAY_TYPE] = gateway.device.subType
          result[K_GATEWAY_UUID] = gateway.uuid
          result[K_DOMAIN] = BasUtil.isNEString(gateway.host)
            ? gateway.host
            : gateway.ip
          result[K_PORT] = gateway.port
          result[K_USERNAME] = doorPhoneConfig.username
          result[K_PASSWORD] = doorPhoneConfig.password
          result[K_MJPEG] = doorPhoneConfig.mjpeg
          result[K_DTMF_DOOR] = gateway.dtmfDoor
          result[K_DTMF_LIGHT] = gateway.dtmfLight

          if (CurrentBasCore.hasCore()) {

            doorPhoneList = currentBasCoreState.core.core
              .getDoorPhonesByGateway(doorPhoneConfig.gateway)
          }

          filter = null

        } else {

          // Gateway device not yet found (yet)
          return null
        }

      } else if (BasUtil.isNEString(doorPhoneConfig.type)) {

        result[K_GATEWAY_TYPE] = doorPhoneConfig.type

        if (CurrentBasCore.hasCore()) {

          doorPhoneList = currentBasCoreState.core.core
            .getDoorPhonesByType(doorPhoneConfig.type)
        }

        filter = doorPhoneConfig.buttons
      }

      if (doorPhoneList &&
        doorPhoneList.length > 0) {

        result[K_DOOR_PHONES] = _getDoorPhonePluginObjectList(
          doorPhoneList,
          filter
        )
      }

      return result

    } else {

      return false
    }
  }

  /**
   * Convert a list of door phones to their
   * plugin object for the sip plugin
   *
   * @private
   * @param {BasDoorPhone[]} basDoorPhones
   * @param {string[]} buttonIncludeList
   * @returns {TBasDoorPhoneWithCameras[]}
   */
  function _getDoorPhonePluginObjectList (
    basDoorPhones,
    buttonIncludeList
  ) {
    var result, basSip, length, i, basDoorPhone, doorPhonePluginObj
    var filter, addPhone

    result = []

    basSip = _getBasSip()

    if (BasUtil.isNEArray(basDoorPhones) && basSip) {

      filter = false
      addPhone = false

      if (BasUtil.isNEArray(buttonIncludeList)) {

        filter = true
      }

      length = basDoorPhones.length
      for (i = 0; i < length; i++) {

        /**
         * @type {BasDoorPhone}
         */
        basDoorPhone = basDoorPhones[i]

        doorPhonePluginObj = {}
        doorPhonePluginObj.name = basDoorPhone.name
        doorPhonePluginObj.uuid = basDoorPhone.uuid
        doorPhonePluginObj.doorPhoneIdentifier =
          basDoorPhone.doorPhoneIdentifier
        doorPhonePluginObj.type = basDoorPhone.subType
        doorPhonePluginObj.dtmfDoor = basDoorPhone.dtmfDoor
        doorPhonePluginObj.openClose = basDoorPhone.openClose
        doorPhonePluginObj.useDefaultVideo = true

        addPhone = false

        // Buttons

        doorPhonePluginObj.buttons = _getButtonPluginObjects(
          basDoorPhone.buttons,
          buttonIncludeList
        )

        if (doorPhonePluginObj.buttons.length > 0) addPhone = true

        // Cameras

        doorPhonePluginObj.cameras =
          _getCameraPluginObjects(basDoorPhone.cameras)

        // Decide to add phone or not

        if (!filter || addPhone) {

          result.push(doorPhonePluginObj)
        }
      }
    }

    return result
  }

  /**
   * @private
   * @param {TButton[]} buttons
   * @param {string[]} [includeList] Button UUIDs
   * @returns {TBasButtonPluginObject[]}
   */
  function _getButtonPluginObjects (buttons, includeList) {

    var result, _checkIncludeList, length, i, button, buttonObj

    result = []

    _checkIncludeList = Array.isArray(includeList)

    length = buttons.length
    for (i = 0; i < length; i++) {

      button = buttons[i]

      if (button &&
        BasUtil.isNEString(button.uuid) &&
        isIncluded(button.uuid)) {

        buttonObj = {}
        buttonObj.uuid = button.uuid
        buttonObj.name = button.name
        buttonObj.username = button.username
        buttonObj.password = button.password
        buttonObj.relay = button.relay

        result.push(buttonObj)
      }
    }

    return result

    /**
     * @private
     * @param {string} buttonUuid
     * @returns {boolean}
     */
    function isIncluded (buttonUuid) {

      return _checkIncludeList
        ? includeList.indexOf(buttonUuid) > -1
        : true
    }
  }

  /**
   * @private
   * @param {string[]} cameraUuids
   * @returns {TBasCameraPluginObject[]}
   */
  function _getCameraPluginObjects (cameraUuids) {

    var result, length, i, camera

    result = []

    length = cameraUuids.length
    for (i = 0; i < length; i++) {

      camera = RoomsHelper.getCameraByUuid(cameraUuids[i])

      if (camera) result.push(camera.getPluginObj(false))
    }

    return result
  }

  /**
   * @private
   */
  function updateSipTranslations () {

    _getBasSip()?.setTranslations(
      ['door_phone', 'intercom'].reduce((acc, curr) => {
        acc[curr] = BasUtilities.translate(curr)
        return acc
      }, {})
    )
  }

  /**
   * @private
   */
  function _resetUiSipState () {

    BAS_SIP.STATE.uiSipStateDomain = '-'
    BAS_SIP.STATE.uiSipStateUsernames = ['-']
    BAS_SIP.STATE.uiSipStateRegistrationSimple = '-'
    BAS_SIP.STATE.uiSipStateRegistrationDetailed = '-'
  }

  /**
   * Get the BasSip plugin instance
   *
   * @private
   * @returns {?BasSip}
   */
  function _getBasSip () {

    if (BasUtil.isObject($window['basalteCordova']) &&
      BasUtil.isObject($window['basalteCordova']['basSip'])) {

      return $window['basalteCordova']['basSip']
    }

    return null
  }

  function _ignore () {
    // Empty
  }
}
