'use strict'

angular
  .module('basalteApp')
  .constant('BAS_DEMO', {
    NAME: 'DEMO',
    DATA: {
      system: require('../../demo/system'),
      users: require('../../demo/users.json'),
      music: require('../../demo/music.json'),
      spotifyUsers: require('../../demo/spotify-users.json'),
      spotifyMusic: require('../../demo/spotify-music.json'),
      rooms: require('../../demo/rooms.json'),
      devices: require('../../demo/devices.json'),
      players: require('../../demo/players.json'),
      playlists: require('../../demo/playlists.json'),
      playlistInfo: require('../../demo/playlist-info.json'),
      queue: require('../../demo/queue.json'),
      favorites: require('../../demo/favourites.json'),
      alarms: require('../../demo/alarms.json'),
      presets: require('../../demo/presets.json'),
      defaultRooms: require('../../demo/default-rooms.json'),
      songs: require('../../demo/songs.json'),
      weather: require('../../demo/weather'),
      storage: require('../../demo/storage'),
      avSources: require('../../demo/av-sources.json'),
      radios: require('../../demo/radios.json'),
      messages: require('../../demo/messages.json')
    }
  })
