'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasModal', [
    'ModalService',
    'BasAppDevice',
    'BAS_HTML',
    'BAS_MODAL',
    'BasModalConfig',
    BasModal
  ])

/**
 * Service for keeping modal types
 *
 * @constructor
 * @param ModalService
 * @param {BasAppDevice} BasAppDevice
 * @param {BAS_HTML} BAS_HTML
 * @param {BAS_MODAL} BAS_MODAL
 * @param BasModalConfig
 */
function BasModal (
  ModalService,
  BasAppDevice,
  BAS_HTML,
  BAS_MODAL,
  BasModalConfig
) {

  this.show = show

  /**
   * @param {string} type
   * @param {Object} [options]
   * @param {Object} [options.modalOptions]
   * @param {BasString} [options.subtitle]
   * @param {boolean} [options.isSplashModal]
   * @returns {Promise}
   */
  function show (type, options) {

    var modalOptions, basModalConfig

    basModalConfig = getBasModalConfig(type, options)

    // Base options
    modalOptions = {
      template: BAS_HTML.templateModal,
      controller: 'basTemplateModalCtrl',
      controllerAs: 'modal',
      inputs: {
        basModalConfig: basModalConfig
      }
    }

    // BasModal options
    if (basModalConfig.modalOptions) {

      BasUtil.mergeObjects(
        modalOptions,
        basModalConfig.modalOptions
      )
    }

    // User options
    if (options && options.modalOptions) {

      BasUtil.mergeObjects(
        modalOptions,
        options.modalOptions
      )
    }

    return ModalService.showModal(modalOptions)
  }

  /**
   * Create the correct BasModalConfig based on the given type.
   *
   * @param {string} type
   * @param {Object} [options]
   * @returns {BasModalConfig}
   */
  function getBasModalConfig (type, options) {

    /**
     * @type {BasModalConfig}
     */
    var basModalConfig = new BasModalConfig()

    // Defaults
    basModalConfig.enableHeaderIcon(true)
    basModalConfig.setCancelButton('no')
    basModalConfig.setOkButton('yes')

    if (BasUtil.isObject(options)) {

      if (BasUtil.isObject(options.subtitle)) {

        basModalConfig.setSubtitle(options.subtitle)
      }

      if (BasUtil.isBool(options.isSplashModal)) {

        basModalConfig.enableSplashModal(options.isSplashModal)
      }
    }

    switch (type) {
      case BAS_MODAL.T_QUEUE_CLEAR:

        basModalConfig.addBodyLine('clear_queue_txt_l1')
        basModalConfig.addBodyLine('clear_queue_txt_l2')
        basModalConfig.setCancelButton('no_keep_it')
        basModalConfig.setOkButton('yes_delete_it')

        break

      case BAS_MODAL.T_ABOUT_TO_GO_TO_LIVE_LOGIN:

        basModalConfig.setTitle('basalte_account')
        basModalConfig.addBodyLine('go_to_login_l1')
        basModalConfig.addBodyLine('clear_queue_txt_l2')

        break

      case BAS_MODAL.T_ABOUT_TO_GO_TO_LIVE_MANAGE:

        basModalConfig.setTitle('basalte_account')
        basModalConfig.addBodyLine('go_to_manage_l1')
        basModalConfig.addBodyLine('clear_queue_txt_l2')

        break

      case BAS_MODAL.T_ABOUT_TO_CHANGE_PASSWORD:

        basModalConfig.setTitle('basalte_account')
        basModalConfig.addBodyLine('change_password_l1')
        basModalConfig.addBodyLine('clear_queue_txt_l2')

        break

      case BAS_MODAL.T_ABOUT_TO_LOG_OUT_EVERYWHERE:

        basModalConfig.setTitle('basalte_account')
        basModalConfig.addBodyLine('log_out_everywhere_l1')

        break

      case BAS_MODAL.T_ABOUT_TO_DELETE_ACCOUNT:

        basModalConfig.setTitle('delete_account')
        basModalConfig.addBodyLine('delete_account_l1')
        basModalConfig.addBodyLine('this_cannot_be_undone')
        basModalConfig.addBodyLine('do_you_want_to_continue')
        basModalConfig.setOkButton('yes_delete_it')
        basModalConfig.setOkButtonDelay(2000)

        break

      case BAS_MODAL.T_PASSWORD_CHANGED:

        basModalConfig.enableHeaderIcon(false)
        basModalConfig.setTitle('password_changed')
        basModalConfig.setOkButton('ok')
        basModalConfig.setCancelButton('')

        break

      case BAS_MODAL.T_WRONG_PASSWORD:

        basModalConfig.addBodyLine('wrong_password_txt')
        basModalConfig.setOkButton('try_again')
        basModalConfig.setCancelButton('')

        break

      case BAS_MODAL.T_UNABLE_TO_CONNECT:

        basModalConfig.addBodyLine('connection_unable')
        basModalConfig.setOkButton('ok')
        basModalConfig.setCancelButton('')

        basModalConfig.modalOptions = {
          bodyClass: BAS_MODAL.CSS_BODY_MODAL_ON_TOP
        }

        break

      case BAS_MODAL.T_RESTART_SERVER:

        basModalConfig.addBodyLine('restart_server_txt_l1')
        basModalConfig.addBodyLine('restart_server_txt_l2')

        break

      case BAS_MODAL.T_REMOVE:

        basModalConfig.addBodyLine('remove_txt_l1')
        basModalConfig.addBodyLine('remove_txt_l2')
        basModalConfig.setCancelButton('no_keep_it')
        basModalConfig.setOkButton('yes_delete_it')

        break

      case BAS_MODAL.T_DEEZER_DISCONNECT:

        basModalConfig.addBodyLine('deezer_disconnect_txt')

        break

      case BAS_MODAL.T_DEEZER_OAUTH_ERROR:

        basModalConfig.setTitle('deezer_oauth_error')
        basModalConfig.addBodyLine('something_went_wrong')
        basModalConfig.addBodyLine('please_try_again')
        basModalConfig.setOkButton('ok')
        basModalConfig.setCancelButton('')

        break

      case BAS_MODAL.T_SPOTIFY_DISCONNECT:

        basModalConfig.addBodyLine('spotify_disconnect_txt')

        break

      case BAS_MODAL.T_SPOTIFY_FREE_USER:

        basModalConfig.addBodyLine('spotify_free_user_txt')
        basModalConfig.setOkButton('ok')
        basModalConfig.setCancelButton('')

        break

      case BAS_MODAL.T_SPOTIFY_ERROR:

        basModalConfig.addBodyLine('spotify_preset_error_l1')
        basModalConfig.addBodyLine('spotify_preset_error_l2')
        basModalConfig.setOkButton('ok')
        basModalConfig.setCancelButton('')

        break

      case BAS_MODAL.T_SPOTIFY_OATH_ERROR:

        basModalConfig.setTitle('spotify_oauth_error')
        basModalConfig.addBodyLine('something_went_wrong')
        basModalConfig.addBodyLine('please_try_again')
        basModalConfig.setOkButton('ok')
        basModalConfig.setCancelButton('')

        break

      case BAS_MODAL.T_TIDAL_DISCONNECT:

        basModalConfig.addBodyLine('tidal_disconnect_txt')

        break

      case BAS_MODAL.T_TIDAL_OAUTH_ERROR:

        basModalConfig.setTitle('tidal_oauth_error')
        basModalConfig.addBodyLine('something_went_wrong')
        basModalConfig.addBodyLine('please_try_again')
        basModalConfig.setOkButton('ok')
        basModalConfig.setCancelButton('')

        break

      case BAS_MODAL.T_DEFAULT_ROOMS_WARNING:

        basModalConfig.enableHeaderIcon(false)
        basModalConfig.setTitle('default_rooms_spotify_warning_title')
        basModalConfig.addBodyLine(
          'default_rooms_spotify_warning_l1'
        )
        basModalConfig.addBodyLine(
          'default_rooms_spotify_warning_l2'
        )
        basModalConfig.setOkButton('set')
        basModalConfig.setCancelButton('not_now')
        basModalConfig.setExtraOption('do_not_show_again')

        break

      case BAS_MODAL.T_UNSUPPORTED_BROWSER:

        basModalConfig.setTitle('unsupported_browser')
        basModalConfig.addBodyLine('unsupported_browser_l1')
        basModalConfig.addBodyLine('unsupported_browser_l2')
        basModalConfig.setOkButton('continue_anyway')
        basModalConfig.setCancelButton('')

        basModalConfig.modalOptions = {
          bodyClass: BAS_MODAL.CSS_BODY_MODAL_ON_TOP
        }
        basModalConfig.closeOnSplash = false

        break

      case BAS_MODAL.T_UNSUPPORTED_API_INFO:

        basModalConfig.setTitle('server_update_required')
        basModalConfig.addBodyLine('update_info_l1')
        basModalConfig.addBodyLine('server_update_info_l2')
        basModalConfig.setOkButton('ok')
        basModalConfig.setCancelButton('')

        basModalConfig.modalOptions = {
          bodyClass: BAS_MODAL.CSS_BODY_MODAL_ON_TOP
        }

        break

      case BAS_MODAL.T_UNSUPPORTED_API_ACTION:

        basModalConfig.setTitle('server_update_required')
        basModalConfig.addBodyLine('update_info_l1')
        basModalConfig.addBodyLine('server_update_info_l2')
        basModalConfig.setOkButton('update')
        basModalConfig.setCancelButton('not_now')

        basModalConfig.modalOptions = {
          bodyClass: BAS_MODAL.CSS_BODY_MODAL_ON_TOP
        }
        basModalConfig.closeOnSplash = false

        break

      case BAS_MODAL.T_UNSUPPORTED_APP:

        basModalConfig.setTitle('app_update_required')
        basModalConfig.addBodyLine('update_info_l1')
        basModalConfig.addBodyLine('app_update_required_l2')
        basModalConfig.setOkButton('ok')
        basModalConfig.setCancelButton('')

        basModalConfig.modalOptions = {
          bodyClass: BAS_MODAL.CSS_BODY_MODAL_ON_TOP
        }
        basModalConfig.closeOnSplash = false

        break

      case BAS_MODAL.T_UNSUPPORTED_APP_ANDROID:

        basModalConfig.setTitle('app_update_required')
        basModalConfig.addBodyLine('update_info_l1')
        basModalConfig.addBodyLine(
          'app_update_required_android_l2'
        )
        basModalConfig.setOkButton('update')
        basModalConfig.setCancelButton('not_now')

        basModalConfig.modalOptions = {
          bodyClass: BAS_MODAL.CSS_BODY_MODAL_ON_TOP
        }
        basModalConfig.closeOnSplash = false

        break

      case BAS_MODAL.T_UNSUPPORTED_APP_IOS:

        basModalConfig.setTitle('app_update_required')
        basModalConfig.addBodyLine('update_info_l1')
        basModalConfig.addBodyLine('app_update_required_ios_l2')
        basModalConfig.setOkButton('update')
        basModalConfig.setCancelButton('not_now')

        basModalConfig.modalOptions = {
          bodyClass: BAS_MODAL.CSS_BODY_MODAL_ON_TOP
        }
        basModalConfig.closeOnSplash = false

        break

      case BAS_MODAL.T_UNSUPPORTED_APP_BROWSER:

        basModalConfig.setTitle('app_update_required')
        basModalConfig.addBodyLine('update_info_l1')
        basModalConfig.addBodyLine(
          'browser_app_update_required_l2'
        )
        basModalConfig.setOkButton('reload')
        basModalConfig.setCancelButton('not_now')

        basModalConfig.modalOptions = {
          bodyClass: BAS_MODAL.CSS_BODY_MODAL_ON_TOP
        }
        basModalConfig.closeOnSplash = false

        break

      case BAS_MODAL.T_DEMO_WARNING:

        basModalConfig.setTitle('demo_title')
        basModalConfig.addBodyLine('demo_warning_txt')
        basModalConfig.addBodyLine('all_off_txt_l2')
        basModalConfig.allowDismiss = false

        basModalConfig.modalOptions = {
          bodyClass: BAS_MODAL.CSS_BODY_MODAL_ON_TOP
        }

        break

      case BAS_MODAL.T_DEMO_FUNCTIONALITY_WARNING:

        basModalConfig.setTitle('demo_title')
        basModalConfig.addBodyLine('demo_functionality_warning_txt')
        basModalConfig.setOkButton('ok')
        basModalConfig.setCancelButton('')

        basModalConfig.modalOptions = {
          bodyClass: BAS_MODAL.CSS_BODY_MODAL_ON_TOP
        }

        break

      case BAS_MODAL.T_BLUETOOTH_PAIRING:

        basModalConfig.setTitle('bluetooth')
        basModalConfig.addBodyLine('bluetooth_pairing_txt')
        basModalConfig.setOkButton('ok')
        basModalConfig.setCancelButton('cancel')

        break

      case BAS_MODAL.T_SAVE_LEARN_SCENE:

        basModalConfig.enableHeaderIcon(false)
        basModalConfig.setTitle('update_scene')
        basModalConfig.addBodyLine('update_scene_l1')

        break

      case BAS_MODAL.T_NO_PERMISSION:

        basModalConfig.setTitle('not_allowed')
        basModalConfig.addBodyLine('not_allowed_l1')
        basModalConfig.setOkButton('ok')
        basModalConfig.setCancelButton('')

        break

      case BAS_MODAL.T_UNSAVED_CHANGES:

        basModalConfig.setTitle('save_changes')
        basModalConfig.addBodyLine('save_changes_l1')
        basModalConfig.addBodyLine('save_changes_l2')
        basModalConfig.setOkButton('ok')
        basModalConfig.setCancelButton('remove')

        break

      case BAS_MODAL.T_DSP_SHORTCUT:

        basModalConfig.addBodyLine('dsp_shortcut_l1')
        basModalConfig.setOkButton('ok')
        basModalConfig.setCancelButton('cancel')

        break

      case BAS_MODAL.T_CORE_CLIENT_UPDATE:

        if (BasAppDevice.isEllie()) {

          basModalConfig.setTitle('ellie_system_update')
          basModalConfig.addBodyLine('ellie_system_update_l1')

        } else if (BasAppDevice.isLisa()) {

          basModalConfig.setTitle('lisa_system_update')
          basModalConfig.addBodyLine('lisa_system_update_l1')

        } else if (BasAppDevice.isLena()) {

          basModalConfig.setTitle('lena_system_update')
          basModalConfig.addBodyLine('lena_system_update_l1')

        } else {

          basModalConfig.setTitle('software_update')
          basModalConfig.addBodyLine('install_update')
        }

        basModalConfig.setOkButton('ok')
        basModalConfig.setCancelButton('cancel')

        break

      case BAS_MODAL.T_APP_RESTART:

        basModalConfig.setTitle('restart_app_q')

        basModalConfig.setOkButton('ok')
        basModalConfig.setCancelButton('cancel')

        basModalConfig.allowDismiss = false

        break

      case BAS_MODAL.T_ERROR:

        basModalConfig.setTitle('error')
        basModalConfig.addBodyLine('something_went_wrong')
        basModalConfig.setOkButton('ok')
        basModalConfig.setCancelButton('')

        break

      case BAS_MODAL.T_ERROR_USER_EXISTS:

        basModalConfig.setTitle('account_exists')
        basModalConfig.addBodyLine('account_exists_l1')
        basModalConfig.addBodyLine('account_exists_l2')
        basModalConfig.setOkButton('ok')
        basModalConfig.setCancelButton('')

        break

      case BAS_MODAL.T_ERROR_USER_NOT_CONFIRMED:

        basModalConfig.setTitle('account_not_confirmed')
        basModalConfig.addBodyLine('account_not_confirmed_l1')
        basModalConfig.addBodyLine('account_not_confirmed_l2')
        basModalConfig.addBodyLine('account_not_confirmed_l3')
        basModalConfig.setOkButton('ok')
        basModalConfig.setCancelButton('cancel')

        break

      case BAS_MODAL.T_ERROR_INVALID_CODE:

        basModalConfig.setTitle('invalid_code')
        basModalConfig.addBodyLine('invalid_code_l1')
        basModalConfig.addBodyLine('invalid_code_l2')
        basModalConfig.setOkButton('ok')
        basModalConfig.setCancelButton('')

        break

      case BAS_MODAL.T_ERROR_INVALID_USER:

        basModalConfig.setTitle('invalid_user')
        basModalConfig.addBodyLine('invalid_user_l1')
        basModalConfig.setOkButton('ok')
        basModalConfig.setCancelButton('')

        break

      case BAS_MODAL.T_ERROR_UNKNOWN_USER:

        basModalConfig.setTitle('unknown_account')
        basModalConfig.addBodyLine('unknown_account_l1')
        basModalConfig.setOkButton('ok')
        basModalConfig.setCancelButton('')

        break

      case BAS_MODAL.T_SCENE_ERROR:

        basModalConfig.setTitle('scene_error')
        basModalConfig.addBodyLine('something_went_wrong')
        basModalConfig.setOkButton('ok')
        basModalConfig.setCancelButton('')

        break

      case BAS_MODAL.T_SCENE_IMAGE_ERROR:

        basModalConfig.setTitle('scene_image_error')
        basModalConfig.addBodyLine('something_went_wrong')
        basModalConfig.setOkButton('ok')
        basModalConfig.setCancelButton('')

        break

      case BAS_MODAL.T_OPEN_SPOTIFY:

        basModalConfig.setTitle('open_spotify')
        basModalConfig.addBodyLine('open_spotify_l1')
        basModalConfig.setOkButton('ok')
        basModalConfig.setCancelButton('cancel')

        break

      case BAS_MODAL.T_FAVOURITE_SCENES_FULL:

        basModalConfig.setTitle('favourite_scenes_full')
        basModalConfig.addBodyLine('favourite_scenes_full_sub')
        basModalConfig.setOkButton('ok')
        basModalConfig.setCancelButton('')

        break

      case BAS_MODAL.T_CONFIRMATION_CODE_SENT:

        basModalConfig.enableHeaderIcon(false)
        basModalConfig.setTitle('confirmation_code_sent')
        basModalConfig.addBodyLine('confirmation_code_sent_l1')

        if (BasUtil.isObject(options) &&
          BasUtil.isNEString(options.email)) {

          basModalConfig.addBodyLineLiteral(options.email)
        }

        basModalConfig.setOkButton('ok')
        basModalConfig.setCancelButton('')

        break

      case BAS_MODAL.T_LINK_SERVER:

        basModalConfig.setTitle('link_server')
        basModalConfig.addBodyLine('basalte_link_server_l1')
        basModalConfig.addBodyLine('basalte_link_server_l2')

        break

      case BAS_MODAL.T_LINK_SERVER_ERROR:

        basModalConfig.setTitle('link_server')
        basModalConfig.addBodyLine('something_went_wrong')
        basModalConfig.addBodyLine('please_try_again')
        basModalConfig.setOkButton('ok')
        basModalConfig.setCancelButton('')

        break

      case BAS_MODAL.T_UNLINK_SERVER:

        basModalConfig.setTitle('unlink_server')
        basModalConfig.addBodyLine('basalte_unlink_server_l1')
        basModalConfig.addBodyLine('basalte_unlink_server_l2')

        break

      case BAS_MODAL.T_UNLINK_SERVER_ERROR:

        basModalConfig.setTitle('unlink_server')
        basModalConfig.addBodyLine('something_went_wrong')
        basModalConfig.addBodyLine('please_try_again')
        basModalConfig.setOkButton('ok')
        basModalConfig.setCancelButton('')

        break

      case BAS_MODAL.T_ROOM_UNREACHABLE:

        basModalConfig.setTitle('room_intercom_unreachable')
        basModalConfig.addBodyLine('room_intercom_unreachable_sub')
        basModalConfig.setCancelButton('')
        basModalConfig.setOkButton('')

        break

      case BAS_MODAL.T_ROOM_UNAVAILABLE:

        basModalConfig.setTitle('room_intercom_unavailable')
        basModalConfig.addBodyLine('room_intercom_unavailable_sub')
        basModalConfig.setCancelButton('')
        basModalConfig.setOkButton('')

        break

      case BAS_MODAL.T_PERMISSION_DENIED_ALWAYS:

        basModalConfig.setTitle('permission_denied')
        basModalConfig.addBodyLine(
          'permission_denied_go_to_settings_l1'
        )
        basModalConfig.addBodyLine(
          'permission_denied_go_to_settings_l2'
        )
        basModalConfig.setCancelButton('')
        basModalConfig.setOkButton('')

        break

      case BAS_MODAL.T_CONNECTION_LOST:

        basModalConfig.setTitle('room_intercom_lost')
        basModalConfig.addBodyLine('room_intercom_lost_sub')
        basModalConfig.setCancelButton('')
        basModalConfig.setOkButton('')

        break

      case BAS_MODAL.T_REMOTE_ELLIE_OUTDATED:

        basModalConfig.setTitle('remote_ellie_intercom_outdated')
        basModalConfig.addBodyLine(
          'remote_ellie_intercom_outdated_l1'
        )
        basModalConfig.addBodyLine(
          'remote_ellie_intercom_outdated_l2'
        )
        basModalConfig.setCancelButton('')
        basModalConfig.setOkButton('')

        break

      case BAS_MODAL.T_CONNECT_TO_INTEGRATOR_BLOCKED:

        basModalConfig.setTitle('integrator_access_disabled')
        basModalConfig.addBodyLine('integrator_access_ask')
        basModalConfig.addBodyLine('connect_offline_server_l2')
        basModalConfig.setCancelButton('no')
        basModalConfig.setOkButton('yes')

        break

      case BAS_MODAL.T_CONNECT_TO_OFFLINE_SERVER:

        basModalConfig.setTitle('offline_server')
        basModalConfig.addBodyLine(
          'connect_offline_server_l1'
        )
        basModalConfig.addBodyLine(
          'connect_offline_server_l2'
        )
        basModalConfig.setCancelButton('no')
        basModalConfig.setOkButton('yes')

        break

      case BAS_MODAL.T_EMPTY_QUEUE:

        basModalConfig.setTitle('empty_queue')
        basModalConfig.addBodyLine('empty_queue_txt_l1')
        basModalConfig.addBodyLine('choose_favourite')
        basModalConfig.setCancelButton('no')
        basModalConfig.setOkButton('yes')

        break

      case BAS_MODAL.T_SONOS_UNREACHABLE:

        basModalConfig.setTitle('sonos_unreachable')
        basModalConfig.addBodyLine('sonos_unreachable_txt_l1')
        basModalConfig.addBodyLine('problems_continue_integrator')
        basModalConfig.setCancelButton('')
        basModalConfig.setOkButton('ok')

        break

      case BAS_MODAL.T_GENERIC_UNREACHABLE:

        basModalConfig.setTitle('unreachable')
        basModalConfig.setCancelButton('')
        basModalConfig.setOkButton('ok')

        break

      case BAS_MODAL.T_TIDAL_LEGACY_AUTH:

        basModalConfig.setTitle('tidal_legacy_auth')
        basModalConfig.addBodyLine('tidal_legacy_auth_l1')
        basModalConfig.setOkButton('ok')
        basModalConfig.setCancelButton('not_now')
        basModalConfig.setExtraOption('do_not_show_again')

        break

      case BAS_MODAL.T_RESCAN_MUSIC_LIBRARY:

        basModalConfig.setTitle('update_library')
        basModalConfig.addBodyLine('confirm_continue')
        basModalConfig.setOkButton('yes')
        basModalConfig.setCancelButton('no')

        break

      case BAS_MODAL.T_ABOUT_TO_LOG_OUT:

        basModalConfig.setTitle('log_out')
        basModalConfig.addBodyLine('confirm_continue')
        basModalConfig.setOkButton('yes')
        basModalConfig.setCancelButton('no')

        break

      case BAS_MODAL.T_SCENE_ACTIVATED:

        basModalConfig.setTitle('scene_activated')
        basModalConfig.addBodyLineLiteral(options.subtitle)
        basModalConfig.setOkButton('ok')
        basModalConfig.setCancelButton('')

        break

      case BAS_MODAL.T_OVERWRITE:

        basModalConfig.setTitle('warning')
        basModalConfig.addBodyLine('confirm_overwrite')
        basModalConfig.setOkButton('yes')
        basModalConfig.setCancelButton('no')

        break

      case BAS_MODAL.T_CLEAR_SCHEDULES:

        basModalConfig.setTitle('warning')
        basModalConfig.addBodyLine('clear_schedules')
        basModalConfig.setOkButton('yes')
        basModalConfig.setCancelButton('no')

        break

      case BAS_MODAL.T_LOGGED_OUT:

        basModalConfig.setTitle('logged_out')
        basModalConfig.addBodyLine('logged_out_description')
        basModalConfig.addBodyLine('please_login_again')
        basModalConfig.setOkButton('ok')
        basModalConfig.setCancelButton('')

        basModalConfig.closeOnSplash = false

        break
    }

    return basModalConfig
  }
}
