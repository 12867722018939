'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('thermostatCtrl', [
    '$rootScope',
    '$scope',
    '$uiRouterGlobals',
    'BAS_ROOMS',
    'CurrentRoom',
    thermostatCtrl
  ])

/**
 * @param $rootScope
 * @param $scope
 * @param $uiRouterGlobals
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {CurrentRoom} CurrentRoom
 */
function thermostatCtrl (
  $rootScope,
  $scope,
  $uiRouterGlobals,
  BAS_ROOMS,
  CurrentRoom
) {
  let _listeners = []

  var basThermostat = this

  /**
   * @type {TCurrentRoomState}
   */
  var _currentRoom = CurrentRoom.get()

  init()

  function init () {
    $scope.$on('$destroy', _destroy)

    _setThermostat()

    _listeners.push($rootScope.$on(
      BAS_ROOMS.EVT_CURRENT_ROOM_CHANGED,
      _onCurrentRoomChanged
    ))
  }

  function _setThermostat () {
    basThermostat.thermostat = BAS_ROOMS.ROOMS.rooms[_currentRoom.roomId]
      .thermostats
      .getThermostat($uiRouterGlobals.params.thermostat)
  }

  function _onCurrentRoomChanged () {

    _setThermostat()
  }

  function _destroy () {

    BasUtil.executeArray(_listeners)
    _listeners = []
  }
}
