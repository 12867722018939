'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasLight', [
    '$rootScope',
    'ModalService',
    'BAS_API',
    'BAS_HTML',
    'BAS_ROOM',
    'BAS_LIGHT',
    'BAS_ELLIE',
    'BasUtilities',
    basLightFactory
  ])

/**
 * @param $rootScope
 * @param ModalService
 * @param BAS_API
 * @param {BAS_HTML} BAS_HTML
 * @param {BAS_ROOM} BAS_ROOM
 * @param {BAS_LIGHT} BAS_LIGHT
 * @param {BAS_ELLIE} BAS_ELLIE
 * @param {BasUtilities} BasUtilities
 * @returns BasLight
 */
function basLightFactory (
  $rootScope,
  ModalService,
  BAS_API,
  BAS_HTML,
  BAS_ROOM,
  BAS_LIGHT,
  BAS_ELLIE,
  BasUtilities
) {
  /**
   * @typedef {Object} TBasLightGroupOptions
   * @property {string} name
   * @property {string} uuid
   * @property {string[]} groupLightUuids
   */

  /**
   * @typedef {Object} TBasLightGroupMinAndMaxTemp
   * @property {number} highestColorTemp
   * @property {number} lowestColorTemp
   */

  var REQUEST_TIMEOUT = 500
  var EVENT_DEBOUNCE = 600

  var DEF_COLOR_TEMPERATURE_MIN = 2000
  var DEF_COLOR_TEMPERATURE_MAX = 7000

  var CSS_LIGHT_SHOW_COLOR = 'brs-show-color'
  var CSS_LIGHT_SHOW_COLOR_CIRCLE = 'brs-show-color-circle'
  var CSS_LIGHT_SHOW_WHITE = 'brs-show-white'
  var CSS_LIGHT_SHOW_WHITE_BRIGHTNESS = 'brs-show-white-brightness'
  var CSS_LIGHT_SHOW_TEMP = 'brs-show-temp'
  var CSS_LIGHT_SHOW_COLOR_MODE = 'brs-show-mode'
  var CSS_LIGHT_SHOW_HEIGHT = 'brs-show-height'
  var CSS_LIGHT_SHOW_LEVEL = 'brs-show-level'
  var CSS_LIGHT_SHOW_SUBTITLE = 'brs-show-subtitle'
  var CSS_LIGHT_SHOW_SEPARATOR = 'brs-show-separator'
  var CSS_LIGHT_SHOW_SLIDER = 'brs-show-slider'
  var CSS_LIGHT_GROUP_SHOW_COLOR_ICON = 'light-group-show-color-icons'

  var TS_ON_STATE = 'onState'
  var TS_BRIGHTNESS = 'brightness'
  var TS_WHITE = 'white'
  var TS_TEMPERATURE = 'temperature'
  var TS_HUE = 'hue'
  var TS_SATURATION = 'saturation'

  /**
   * @constructor
   * @param {(LightDevice|string|TBasLightGroupOptions)} [device]
   * @param {BasRoomLights} [lights]
   */
  function BasLight (device, lights) {

    /**
     * @type {string}
     */
    this.name = ''

    /**
     * @type {string}
     */
    this.uuid = ''

    /**
     * @type {string}
     */
    this.customName = ''

    /**
     * @type {string}
     */
    this.position = ''

    /**
     * @type {number}
     */
    this.type = 0

    /**
     * @type {string}
     */
    this.typeStr = ''

    /**
     * @type {number}
     */
    this.location = 0

    /**
     * @type {string}
     */
    this.locationStr = ''

    /**
     * @type {string}
     */
    this.titleNameHtml = ''

    /**
     * @type {boolean}
     */
    this.onState = false

    /**
     * @type {number}
     */
    this.brightness = -1

    /**
     * @type {number}
     */
    this.brightnessSlider = 0

    /**
     * @type {string}
     */
    this.brightnessSliderStr = ''

    /**
     * @type {number}
     */
    this.white = -1

    /**
     * @type {number}
     */
    this._relTemperature = -1

    /**
     * @type {number}
     */
    this.hue = -1

    /**
     * @type {number}
     */
    this.saturation = -1

    /**
     * @type {number}
     */
    this.colorTemperatureMin = DEF_COLOR_TEMPERATURE_MIN

    /**
     * @type {number}
     */
    this.colorTemperatureMax = DEF_COLOR_TEMPERATURE_MAX

    /**
     * @type {number}
     */
    this.colorTemperatureRange = this.colorTemperatureMax -
      this.colorTemperatureMin

    /**
     * @type {number}
     */
    this.temperature = -1

    /**
     * @type {?LightDevice}
     */
    this.device = null

    /**
     * @type {Object<string, number>}
     */
    this.timeStamp = {}

    /**
     * @type {boolean}
     */
    this.canChangeMode = false

    /**
     * @type {boolean}
     */
    this._canToggle = false

    /**
     * @type {boolean}
     */
    this.canToggleOn = false

    /**
     * @type {boolean}
     */
    this.canChangeBrightness = false

    /**
     * @type {boolean}
     */
    this.canChangeWhite = false

    /**
     * @type {boolean}
     */
    this.canChangeColor = false

    /**
     * @type {boolean}
     */
    this.canChangeColorTemp = false

    /**
     * @type {boolean}
     */
    this.canChangeVertical = false

    /**
     * @type {boolean}
     */
    this.canChangeHeight = false

    /**
     * @type {string[]|null}
     */
    this.groupLightUuids = null

    /**
     * @type {boolean}
     */
    this.canSync = true

    this.toggleTimeout = null

    this.colorStyle = {}

    /**
     * @type {string}
     */
    this.mode = BAS_LIGHT.MODE_UNKNOWN

    /**
     * @type {number}
     */
    this.vertical = -1

    /**
     * @type {number}
     */
    this.height = -1

    /**
     * @type {boolean}
     */
    this._supportsWhiteViaBrightness = false

    /**
     * @type {Object}
     */
    this.css = {}
    this.resetCss()

    this._deviceListeners = []

    this.brightnessTimer = 0
    this.whiteTimer = 0
    this.colorTempTimer = 0

    this._lights = lights

    this._enableToggleInput = this.enableToggleInput.bind(this)
    this._handleToggleStateChange = this.onToggleStateChange.bind(this)
    this._handleBrightnessChange = this._onBrightnessChange.bind(this)
    this._handleWhiteChange = this._onWhiteChange.bind(this)
    this._handleColorChange = this.onColorChange.bind(this)
    this._handleModeChange = this.onModeChange.bind(this)
    this._handleHeightChange = this.onHeightChange.bind(this)
    this._handleVerticalChange = this.onVerticalChange.bind(this)
    this._handleTemperatureChange = this._onTemperatureChange.bind(this)
    this._handleReachableChanged = this.onReachableChange.bind(this)
    this._handleDeviceCapabilitiesChanged =
      this.onDeviceCapabilitiesChange.bind(this)
    this._syncBrightness = this.__syncBrightness.bind(this)
    this._syncWhite = this.__syncWhite.bind(this)

    if (device) {

      if (device instanceof BAS_API.LightDevice || BasUtil.isString(device)) {

        this.parseLightDevice(device)
      } else {

        this.parseLightGroup(lights, device)
      }
    }
  }

  /**
   * @constant {string}
   */
  BasLight.CSS_LIGHT_SHOW_COLOR = CSS_LIGHT_SHOW_COLOR

  /**
   * Translate shade type
   *
   * @param {number} key
   * @returns {string}
   */
  BasLight.getTypeStr = function (key) {

    // Compare strings with strings
    switch (key) {
      case BAS_API.LightDevice.TYPE.FLOOR_LAMP:
        return BasUtilities.translate('light_floor_lamp')
      case BAS_API.LightDevice.TYPE.TABLE_LAMP:
        return BasUtilities.translate('light_table_lamp')
      case BAS_API.LightDevice.TYPE.CEILING_MOUNTED_FIXTURE:
        return BasUtilities.translate('light_ceiling_mounted_fixture')
      case BAS_API.LightDevice.TYPE.WALL_MOUNTED_FIXTURE:
        return BasUtilities.translate('light_wall_mounted_fixture')
      case BAS_API.LightDevice.TYPE.CHANDELIER:
        return BasUtilities.translate('light_chandelier')
      case BAS_API.LightDevice.TYPE.LED:
        return BasUtilities.translate('light_led')
      case BAS_API.LightDevice.TYPE.LED_STRIP:
        return BasUtilities.translate('light_led_strip')
      case BAS_API.LightDevice.TYPE.VIA:
        return BasUtilities.translate('light_via')
      case BAS_API.LightDevice.TYPE.PENDANT:
        return BasUtilities.translate('light_pendant')
      case BAS_API.LightDevice.TYPE.SPOT:
        return BasUtilities.translate('light_spot')
      case BAS_API.LightDevice.TYPE.TRACK:
        return BasUtilities.translate('light_track')
      case BAS_API.LightDevice.TYPE.DOWN:
        return BasUtilities.translate('light_down')
      case BAS_API.LightDevice.TYPE.READING_LIGHT:
        return BasUtilities.translate('light_reading_light')
      case BAS_API.LightDevice.TYPE.CABINET:
        return BasUtilities.translate('light_cabinet')
      case BAS_API.LightDevice.TYPE.MIRROR:
        return BasUtilities.translate('light_mirror')
      case BAS_API.LightDevice.TYPE.DESK:
        return BasUtilities.translate('light_desk')
      case BAS_API.LightDevice.TYPE.LAMPSHADE:
        return BasUtilities.translate('light_lampshade')
      case BAS_API.LightDevice.TYPE.MOOD_LIGHT:
        return BasUtilities.translate('light_mood_light')
      case BAS_API.LightDevice.TYPE.ACCENT_LIGHT:
        return BasUtilities.translate('light_accent_light')
      case BAS_API.LightDevice.TYPE.ART_LIGHT:
        return BasUtilities.translate('light_art_light')
      default:
        return ''
    }
  }
  /**
   * This function is used for converting between two sets of constants.
   * One designated for the application and the other for the API.
   *
   * For example: When receiving light mode data from the WebSocket (WS),
   * the function transforms the mode constant to the application's constant,
   * distinguishing between the two sets of constants for
   * the API and the application.
   *
   * @param mode
   * @returns {string}
   */
  BasLight.getMode = function (mode) {
    switch (mode) {

      case BAS_LIGHT.MODE_COLOR:
        return BAS_API.LightDevice.MODE_COLOR

      case BAS_LIGHT.MODE_COLOR_TEMPERATURE:
        return BAS_API.LightDevice.MODE_COLOR_TEMPERATURE

      case BAS_LIGHT.MODE_WHITE:
        return BAS_API.LightDevice.MODE_WHITE

      case BAS_LIGHT.MODE_UNKNOWN:
        return BAS_API.LightDevice.MODE_UNKNOWN

      case BAS_API.LightDevice.MODE_COLOR:
        return BAS_LIGHT.MODE_COLOR

      case BAS_API.LightDevice.MODE_COLOR_TEMPERATURE:
        return BAS_LIGHT.MODE_COLOR_TEMPERATURE

      case BAS_API.LightDevice.MODE_WHITE:
        return BAS_LIGHT.MODE_WHITE

      case BAS_API.LightDevice.MODE_UNKNOWN:
        return BAS_LIGHT.MODE_UNKNOWN
    }
    return BAS_LIGHT.MODE_UNKNOWN
  }

  /**
   * Generates simple name string (taking into account type, subType, ...)
   * format: TYPE_NAME - CUSTOM_NAME (LOCATION)
   *
   * Needs to be retrieved again when translation changes!
   *
   * @returns {string}
   */
  BasLight.prototype.getName = function () {

    var result = ''

    if (this.typeStr) {

      result += this.typeStr

      if (this.name) result += ' - '
    }

    result += this.name

    if (this.locationStr) {

      result += ' (' + this.locationStr + ')'
    }

    return result
  }

  /**
   * @returns {BasLight}
   */
  BasLight.prototype.clone = function () {
    return new BasLight(this.device)
  }

  /**
   * Checks if current UUID is valid, if not, create one
   */
  BasLight.prototype.fillInUuid = function () {

    if (!BasUtil.isNEString(this.uuid)) {

      this.uuid = BasUtilities.getAppUniqueId()
    }
  }

  /**
   * @param {number} [desiredBrightness]
   */
  BasLight.prototype.toggle = function (desiredBrightness) {

    if (this.canToggleOn && this._canToggle) {

      this.updateOnState(!this.onState)
      this._canToggle = false

      if (
        this.device?.allowsWrite(BAS_API.LightDevice.C_BRIGHTNESS) ||
        this.isLightGroup()
      ) {

        if (this.onState) {

          if (BasUtil.isVNumber(desiredBrightness)) {

            this.updateBrightness(desiredBrightness)
          }
        } else {

          this.updateBrightness(0)
        }

        if (this.isLightGroup()) {
          for (const lightUuid of this.groupLightUuids) {

            const light = this._lights.basLights[lightUuid]

            if (light instanceof BasLight && light.canToggleOn) {

              light.updateOnState(!this.onState)
              light.toggle(this.brightnessSlider)
            }
          }
        }
      }

      this.toggleTimeout = setTimeout(
        this._enableToggleInput,
        REQUEST_TIMEOUT
      )

      this.timeStamp[TS_ON_STATE] = Date.now()
      this.emitInput()

      if (this.canSync &&
        this.device &&
        this.device.allowsWrite(BAS_API.LightDevice.C_ON)) {

        this.device.toggle(this.onState)
      }
    }
  }

  BasLight.prototype.openColorPicker = function () {

    this.syncGroupTemp()

    ModalService.showModal({
      controller: 'colorPickerModalCtrl',
      controllerAs: 'modal',
      inputs: {
        light: this
      },
      template: BAS_HTML.colorPickerModal
    })
  }

  BasLight.prototype.isLightGroup = function () {
    return Array.isArray(this.groupLightUuids)
  }

  BasLight.prototype.emitInput = function () {

    $rootScope.$emit(BAS_ROOM.EVT_FUNCTION_INPUT, this)
  }

  BasLight.prototype.syncGroupTemp = function () {
    // Visualize the colorTemperature of the first light inside a lightGroup

    if (this.isLightGroup()) {

      for (const lightUuid of this.groupLightUuids) {

        const light = this._lights.basLights[lightUuid]

        if (light instanceof BasLight && light.canChangeColorTemp) {

          this.temperature = light.temperature
          this.relTemperature = light.relTemperature
          return
        }
      }
    }
  }

  // Sync group light based on the first (eligible) light in group.
  BasLight.prototype.syncGroupColorStyle = function () {
    if (this.isLightGroup()) {

      for (const lightUuid of this.groupLightUuids) {

        const light = this._lights.basLights[lightUuid]

        if (
          light instanceof BasLight &&
          (light.canChangeColor || light.canChangeColorTemp)
        ) {

          this.colorStyle = BasUtil.copyObject(light.colorStyle)
          return
        }
      }
    }
  }

  /**
   * @param {number} value Range 0 - 100
   */
  BasLight.prototype.setBrightness = function (value) {

    this.timeStamp[TS_BRIGHTNESS] = Date.now()
    this.emitInput()

    if (this.canSync &&
      this.device &&
      this.device.allowsWrite(BAS_API.LightDevice.C_BRIGHTNESS)) {

      this.device.setBrightness(value / 100)
    }
  }

  /**
   * @param {number} value Range 0 - 100
   */
  BasLight.prototype.setWhite = function (value) {

    this.timeStamp[TS_WHITE] = Date.now()
    this.emitInput()

    if (this.canSync &&
      this.device &&
      this.device.allowsWrite(BAS_API.LightDevice.C_WHITE)) {

      this.device.setWhite(value / 100)
    }
  }

  /**
   * @param {number} value Range 0 - 100
   */
  BasLight.prototype.setVertical = function (value) {

    this.emitInput()

    if (this.canSync &&
      this.device &&
      this.device.allowsWrite(BAS_API.LightDevice.C_VERTICAL)) {

      this.device.setVertical(value / 100)
    }
  }

  /**
   * @param {number} value Range 0 - 100
   */
  BasLight.prototype.setHeight = function (value) {

    this.emitInput()

    if (this.canSync &&
      this.device &&
      this.device.allowsWrite(BAS_API.LightDevice.C_HEIGHT)) {

      this.device.setHeight(value / 100)
    }
  }

  /**
   * Updates the API white with the UI
   */
  BasLight.prototype.whiteChange = function () {

    if (!this.onState && this.mode === BAS_LIGHT.MODE_WHITE) {

      this.updateOnState(true)
    }

    if (this.isLightGroup()) {

      for (const groupLightUuid of this.groupLightUuids) {

        const groupLight = this._lights.basLights[groupLightUuid]

        if (groupLight?.canChangeWhite) groupLight.setWhite(this.white)

      }
    } else {
      this.setWhite(this.white)
    }

    $rootScope.$applyAsync()
  }

  /**
   * @returns {void}
   */
  BasLight.prototype.changeWhiteOrBrightness = function () {
    return (
      this._supportsWhiteViaBrightness &&
      this.mode === BAS_LIGHT.MODE_WHITE
        ? this.whiteChange()
        : this.brightnessChange()
    )
  }

  /**
   * @returns {string}
   */
  BasLight.prototype.getWhiteOrBrightnessKey = function () {
    return (
      this._supportsWhiteViaBrightness &&
      this.mode === BAS_LIGHT.MODE_WHITE
        ? 'white'
        : 'brightnessSlider'
    )
  }

  /**
   * @returns {int}
   */
  BasLight.prototype.getWhiteOrBrightness = function () {
    return (
      this._supportsWhiteViaBrightness &&
      this.mode === BAS_LIGHT.MODE_WHITE
        ? this.white
        : this.brightnessSlider
    )
  }

  /**
   * Updates the API brightness with the UI
   */
  BasLight.prototype.brightnessChange = function () {

    this.brightness = this.brightnessSlider
    this.updateOnState(this.brightness > 0)
    this.setBrightness(this.brightness)

    if (this.isLightGroup()) {

      for (const groupLightUuid of this.groupLightUuids) {

        const groupLight = this._lights.basLights[groupLightUuid]

        if (groupLight instanceof BasLight && groupLight.canToggleOn) {

          // If there is a light in white mode,
          // then we also want to adjust the white value
          if (
            groupLight.canChangeWhite &&
            groupLight.mode === BAS_LIGHT.MODE_WHITE
          ) {

            groupLight.setWhite(this.brightnessSlider)

          } else if (groupLight.canChangeBrightness) {

            groupLight.brightnessSlider = this.brightnessSlider
            groupLight.brightnessChange()
          }

          groupLight.updateOnState(this.brightness > 0)

          // Light with only toggle capability
          if (
            groupLight.device &&
            groupLight.canToggleOn &&
            !groupLight.canChangeBrightness &&
            groupLight.onState !== groupLight.device.onState
          ) {
            groupLight.device.toggle(this.brightness > 0)
          }
        }
      }
    }

    $rootScope.$applyAsync()
  }

  BasLight.prototype.verticalChange = function () {

    if (this.isLightGroup()) {

      for (const groupLightUuid of this.groupLightUuids) {

        const groupLight = this._lights.basLights[groupLightUuid]

        if (groupLight?.canChangeVertical) groupLight.setVertical(this.vertical)

      }
    } else {
      this.setVertical(this.vertical)
    }

    $rootScope.$applyAsync()
  }

  BasLight.prototype.heightChange = function () {

    if (this.isLightGroup()) {

      for (const groupLightUuid of this.groupLightUuids) {

        const groupLight = this._lights.basLights[groupLightUuid]

        if (groupLight?.canChangeHeight) groupLight.setHeight(this.height)

      }
    } else {
      this.setHeight(this.height)
    }

    $rootScope.$applyAsync()
  }

  Object.defineProperty(BasLight.prototype, 'relTemperature', {
    get: function () {
      return this._relTemperature
    },
    set: function (value) {
      this._relTemperature = value
      this.syncTemperature()
    }
  })

  BasLight.prototype.syncTemperature = function () {

    this.temperature = Math.round(
      (this._relTemperature * this.colorTemperatureRange) +
      this.colorTemperatureMin
    )
  }

  /**
   * @param {number} value Range see limit
   */
  BasLight.prototype.setColorTemperature = function (value) {

    this.timeStamp[TS_TEMPERATURE] = Date.now()
    this.emitInput()

    if (this.canSync &&
      this.device &&
      this.device.allowsWrite(BAS_API.LightDevice.C_COLOR_TEMPERATURE)) {

      this.device.setColorTemperature(value)
    }
  }

  /**
   * Updates the API color temperature with the UI
   */
  BasLight.prototype.colorTemperatureChange = function () {

    if (this.isLightGroup()) {

      for (const groupLightUuid of this.groupLightUuids) {

        const groupLight = this._lights.basLights[groupLightUuid]

        if (groupLight instanceof BasLight && groupLight.canChangeColorTemp) {

          groupLight.relTemperature = this.relTemperature
          groupLight.colorTemperatureChange()
        }
      }
    } else {
      this.setColorTemperature(this.temperature)
      this.makeColorStyle()
    }
  }

  /**
   * @param {number} hue Range 0 - 360
   * @param {number} saturation Range 0 - 100
   * @param {number} [brightness] Range 0 - 100
   */
  BasLight.prototype.setColorHSB = function (hue, saturation, brightness) {

    var time = Date.now()

    this.timeStamp[TS_HUE] = time
    this.timeStamp[TS_SATURATION] = time
    this.emitInput()

    if (this.canSync &&
      this.device &&
      this.device.allowsWrite(BAS_API.LightDevice.C_COLOR)) {

      this.device.setColorHSB(
        hue / 360,
        saturation / 100,
        brightness / 100
      )
    }
  }

  /**
   * @param {string} mode
   */
  BasLight.prototype.setColorMode = function (mode) {

    this.mode = mode

    this.syncModeUI()
    this.makeColorStyle()

    if (this.canSync && this.device) {

      // If mode is writeable, update mode
      if (this.device.allowsWrite(BAS_API.LightDevice.C_MODE)) {

        this.device.setColorMode(BasLight.getMode(this.mode))

        if (this.mode === BAS_LIGHT.MODE_COLOR_TEMPERATURE) {

          this.colorTemperatureChange()

        } else if (this.mode === BAS_LIGHT.MODE_COLOR) {

          this.colorHSBChange()
        }
      }
    }
  }

  /**
   * Updates the API color HSB with the UI
   */
  BasLight.prototype.colorHSBChange = function () {

    if (this.isLightGroup()) {

      for (const groupLightUuid of this.groupLightUuids) {

        const groupLight = this._lights.basLights[groupLightUuid]

        if (groupLight instanceof BasLight && groupLight.canChangeColor) {

          groupLight.hue = this.hue
          groupLight.saturation = this.saturation

          if (groupLight.mode !== BAS_LIGHT.MODE_COLOR) {

            groupLight.setColorMode(BAS_LIGHT.MODE_COLOR)
          }

          groupLight.colorHSBChange()
        }
      }
    }

    this.setColorHSB(this.hue, this.saturation, this.brightness)
    this.makeColorStyle()
  }

  BasLight.prototype.enableInput = function () {

    this.enableToggleInput()
  }

  BasLight.prototype.enableToggleInput = function () {

    // Clear timeouts
    clearTimeout(this.toggleTimeout)

    // Enable input
    this._canToggle = true
  }

  /**
   * @param {(LightDevice|string)} lightDevice
   */
  BasLight.prototype.parseLightDevice = function (
    lightDevice
  ) {
    if (lightDevice instanceof BAS_API.LightDevice) {

      this.device = lightDevice
      this.uuid = lightDevice.uuid
      this.setDeviceListeners()
      this.syncDevice()

    } else if (BasUtil.isNEString(lightDevice)) {

      this.uuid = lightDevice
    }
  }

  /**
   * @param {BasRoomLights} lights
   * @param {(TBasLightGroupOptions)} device
   */
  BasLight.prototype.parseLightGroup = function (lights, device) {

    if (device && lights.basLights) {

      this.name = device.name
      this.uuid = device.uuid

      this.groupLightUuids = device.groupLightUuids
      this.css[CSS_LIGHT_GROUP_SHOW_COLOR_ICON] = true
      this._syncGroupFeatures()

      const groupTempData = this.getGroupMinAndMaxTemp()

      const highestColorTemp = groupTempData.highestColorTemp
      const lowestColorTemp = groupTempData.lowestColorTemp

      this._makeTitleNameHtml()

      if (
        this.canChangeColorTemp &&
        this.canChangeColor
      ) {

        this.mode = BAS_LIGHT.MODE_COLOR_TEMPERATURE
        this.uiToggleMode(true)

      } else if (this.canChangeColor) {

        this.mode = BAS_LIGHT.MODE_COLOR
        this.uiToggleColor(true)
        this.uiToggleColorCircle(true)

      } else if (this.canChangeColorTemp) {

        this.mode = BAS_LIGHT.MODE_COLOR_TEMPERATURE
        this.uiToggleTemp(true)

      } else if (this.canChangeWhite) {

        if (this._supportsWhiteViaBrightness) {
          this.uiToggleWhiteBrightness(true)
        }

        this.mode = BAS_LIGHT.MODE_WHITE
      }

      this.uiToggleHeight(this.canChangeHeight)
      this.uiToggleVertical(this.canChangeVertical)

      for (const lightUuid of device.groupLightUuids) {

        const light = lights.basLights[lightUuid]

        if (light instanceof BasLight && light.canChangeColor) {

          this.hue = light.hue
          this.saturation = light.saturation
          this.white = light.white
          break
        }
      }

      this.updateGroupBrightness()
      this.colorTemperatureMax = highestColorTemp
      this.colorTemperatureMin = lowestColorTemp
      this.colorTemperatureRange = highestColorTemp - lowestColorTemp

      this.syncGroupColorStyle()
      this.syncGroupTemp()
    }
  }

  BasLight.prototype.updateBrightness = function (value) {

    this.brightness = value
    this.brightnessSlider = value
    this.brightnessSliderStr = value + '%'
  }

  BasLight.prototype.updateOnState = function (value) {

    this.onState = value
    this._lights?.checkLightChange()
  }

  BasLight.prototype.makeColorStyle = function () {

    if (this.isLightGroup()) {

      for (const groupLightUuid of this.groupLightUuids) {

        const groupLight = this._lights.basLights[groupLightUuid]

        if (
          groupLight instanceof BasLight &&
          (groupLight.canChangeColor || groupLight.canChangeColorTemp)
        ) {

          groupLight.makeColorStyle()
        }
      }
    }

    // Only valid for RGBW lights and light groups
    if (this.canChangeMode || (this.isLightGroup() && this.canChangeColor)) {

      switch (this.mode) {

        case BAS_LIGHT.MODE_COLOR:

          this.calculateColor()
          break

        case BAS_LIGHT.MODE_COLOR_TEMPERATURE:

          this.calculateColorTemp()
          break

        case BAS_LIGHT.MODE_WHITE:

          // Visualize mode white with color white
          this.setColorStyle([255, 255, 255])
          break
      }
    } else if (this.device) {

      if (this.device.allowsRead(BAS_API.LightDevice.C_COLOR)) {

        this.calculateColor()

      } else if (
        this.device.allowsRead(BAS_API.LightDevice.C_COLOR_TEMPERATURE)
      ) {

        this.calculateColorTemp()

      } else if (this.device.allowsRead(BAS_API.LightDevice.C_WHITE)) {
        // Visualize mode white with color white
        this.setColorStyle([255, 255, 255])
        this.calculateColor()
      } else {

        this.setColorStyle(null)
      }
    }
  }

  BasLight.prototype.calculateColor = function () {

    var color

    color = BasUtil.hsvToRgb(
      this.hue / 360,
      this.saturation / 100,
      1
    )

    this.setColorStyle(color)
  }

  BasLight.prototype.calculateColorTemp = function () {

    var color = BasUtil.temperatureToRgb(this.temperature)

    this.setColorStyle(color)
  }

  BasLight.prototype.setColorStyle = function (color) {

    if (BasUtil.isNEArray(color)) {

      this.colorStyle['background-color'] =
        'rgb(' + color[0] + ',' + color[1] + ',' + color[2] + ')'
      this.uiToggleColorCircle(true)

    } else {

      this.colorStyle['background-color'] = 'transparent'
      this.uiToggleColorCircle(false)
    }
  }

  /**
   * @param {TSceneLight} sceneLight
   * @param {number} [time] Time in milliseconds
   */
  BasLight.prototype.parseSceneLight = function (
    sceneLight,
    time
  ) {
    var _time

    _time = BasUtil.isPNumber(time) ? time : Date.now()

    if (BasUtil.isBool(sceneLight.onOff)) {

      this.updateOnState(sceneLight.onOff)
      this.timeStamp[TS_ON_STATE] = _time
      // Until an actual light device is synced,
      //  assume that on state can be changed
      this.canToggleOn = true
    }

    if (BasUtil.isPNumber(sceneLight.brightness, true)) {

      this.updateBrightness(Math.round(sceneLight.brightness))
      this.timeStamp[TS_BRIGHTNESS] = _time
      // Until an actual light device is synced,
      //  assume that brightness can be changed
      this.canChangeBrightness = true

    } else {

      this.updateBrightness(0)
    }

    if (BasUtil.isPNumber(sceneLight.colorTemperature, true)) {

      this.temperature = Math.round(sceneLight.colorTemperature)
      this.timeStamp[TS_TEMPERATURE] = _time
      // Until an actual light device is synced,
      //  assume that color temperature can be changed
      this.canChangeColorTemp = true
      this.mode = BAS_LIGHT.MODE_COLOR_TEMPERATURE

    } else {

      this.temperature = this.colorTemperatureMin
    }

    if (BasUtil.isPNumber(sceneLight.hue, true)) {

      this.hue = Math.round(sceneLight.hue)
      this.timeStamp[TS_HUE] = _time
      // Until an actual light device is synced,
      //  assume that color can be changed
      this.canChangeColor = true
      this.mode = BAS_LIGHT.MODE_COLOR

    } else {

      this.hue = 0
    }

    if (BasUtil.isPNumber(sceneLight.saturation, true)) {

      this.saturation = Math.round(sceneLight.saturation)
      this.timeStamp[TS_SATURATION] = _time
      // Until an actual light device is synced,
      //  assume that color can be  changed
      this.canChangeColor = true
      this.mode = BAS_LIGHT.MODE_COLOR

    } else {

      this.saturation = 0
    }

    if (BasUtil.isPNumber(sceneLight.white, true)) {

      this.white = Math.round(sceneLight.white)
      this.timeStamp[TS_WHITE] = _time
      // Until an actual light device is synced,
      //  assume that white can be changed
      this.canChangeWhite = true
      this.mode = BAS_LIGHT.MODE_WHITE

    } else {

      this.white = 0
    }

    if (BasUtil.isNEString(sceneLight.mode)) {

      this.mode = BasLight.getMode(sceneLight.mode)
      // Until an actual light device is synced,
      //  assume that mode can be changed
      this.canChangeMode = true
    }

    if (BasUtil.isPNumber(sceneLight.height, true)) {

      this.height = sceneLight.height
      // Until an actual light device is synced,
      //  assume that height can be changed
      this.canChangeHeight = true

    } else {

      this.height = 0
    }

    if (BasUtil.isPNumber(sceneLight.vertical, true)) {

      this.vertical = sceneLight.vertical
      // Until an actual light device is synced,
      //  assume that vertical can be changed
      this.canChangeVertical = true

    } else {

      this.vertical = 0
    }

    this.makeColorStyle()
    this.syncModeUI()
  }

  /**
   * @param {LightDevice} [device]
   */
  BasLight.prototype.syncDevice = function (device) {

    var lightDevice = device || this.device

    if (lightDevice) {

      this.resetCss()
      this.resetCapabilities()

      this.name = lightDevice.name
      this.type = lightDevice.subType
      this.location = lightDevice.location

      if (lightDevice.allowsRead(BAS_API.LightDevice.C_ON)) {

        this.updateOnState(lightDevice.onState)

        if (lightDevice.allowsWrite(BAS_API.LightDevice.C_ON)) {

          this.canToggleOn = true
        }
      }

      if (lightDevice.allowsRead(BAS_API.LightDevice.C_BRIGHTNESS)) {

        this.updateBrightness(
          Math.round(lightDevice.brightness * 100)
        )
        this.uiToggleSlider(true)

        if (lightDevice.allowsWrite(BAS_API.LightDevice.C_BRIGHTNESS)) {

          this.canChangeBrightness = true
        }
      }

      if (lightDevice.allowsRead(BAS_API.LightDevice.C_WHITE)) {

        this.white = Math.round(lightDevice.white * 100)
        this.uiToggleSlider(true)
        this.uiToggleWhite(true)

        if (lightDevice.allowsWrite(BAS_API.LightDevice.C_WHITE)) {

          this.canChangeWhite = true
        }
      }

      if (lightDevice.allowsRead(BAS_API.LightDevice.C_COLOR)) {

        this.updateBrightness(
          Math.round(lightDevice.brightness * 100)
        )
        this.saturation = Math.round(lightDevice.saturation * 100)
        this.hue = Math.round(lightDevice.hue * 360)
        this.uiToggleSlider(true)

        if (lightDevice.allowsWrite(BAS_API.LightDevice.C_COLOR)) {

          this.canChangeColor = true
        }
      }

      if (lightDevice.allowsRead(BAS_API.LightDevice.C_COLOR_TEMPERATURE)) {

        this.temperature = Math.round(lightDevice.colorTemperature)

        if (lightDevice.limitColorTemperature) {

          this.colorTemperatureMax =
            BasUtil.isVNumber(lightDevice.limitColorTemperature.max)
              ? lightDevice.limitColorTemperature.max
              : DEF_COLOR_TEMPERATURE_MAX

          this.colorTemperatureMin =
            BasUtil.isVNumber(lightDevice.limitColorTemperature.min)
              ? lightDevice.limitColorTemperature.min
              : DEF_COLOR_TEMPERATURE_MIN

        } else {

          this.colorTemperatureMin =
            DEF_COLOR_TEMPERATURE_MIN
          this.colorTemperatureMax =
            DEF_COLOR_TEMPERATURE_MAX
        }

        this.colorTemperatureRange =
          this.colorTemperatureMax - this.colorTemperatureMin
        this.syncRelTemperature()

        if (lightDevice.allowsWrite(
          BAS_API.LightDevice.C_COLOR_TEMPERATURE
        )) {

          this.canChangeColorTemp = true
        }
      }

      if (lightDevice.allowsRead(BAS_API.LightDevice.C_MODE)) {

        this.mode = BasLight.getMode(lightDevice.mode)

        if (lightDevice.allowsWrite(BAS_API.LightDevice.C_MODE)) {

          this.canChangeMode = true
        }
      }

      if (lightDevice.allowsRead(BAS_API.LightDevice.C_VERTICAL)) {

        this.vertical = Math.round(lightDevice.vertical * 100)

        if (lightDevice.allowsWrite(BAS_API.LightDevice.C_VERTICAL)) {

          this.canChangeVertical = true
        }
      }

      if (lightDevice.allowsRead(BAS_API.LightDevice.C_HEIGHT)) {

        this.height = Math.round(lightDevice.height * 100)

        if (lightDevice.allowsWrite(BAS_API.LightDevice.C_HEIGHT)) {

          this.canChangeHeight = true
        }
      }

      this._supportsWhiteViaBrightness =
        lightDevice.allowsWrite(BAS_API.LightDevice.C_MODE) &&
        lightDevice.allowsWrite(BAS_API.LightDevice.C_WHITE)
    }

    // Different modes are available when either both color and color
    //  temperature OR color and white are readable
    this.uiToggleMode(
      lightDevice.allowsRead(BAS_API.LightDevice.C_MODE) &&
      lightDevice.allowsRead(BAS_API.LightDevice.C_COLOR) &&
      lightDevice.allowsRead(BAS_API.LightDevice.C_MODE) &&
      (
        lightDevice.allowsRead(BAS_API.LightDevice.C_COLOR_TEMPERATURE) ||
        lightDevice.allowsRead(BAS_API.LightDevice.C_WHITE)
      )
    )

    // Wietse said to not care about older server versions
    // that don't support light mode (14/12/23) during scrum meeting
    // Also light groups now only support color mode came from Wietse as well

    this.makeColorStyle()
    this.syncModeUI()

    this.updateTranslation()
    this.enableInput()
  }

  BasLight.prototype.updateGroupBrightness = function () {

    const brightnessValues = this.groupLightUuids
      .map(el => this._lights.basLights[el])
      .filter(el => (
        el instanceof BasLight &&
        (
          (
            el.canChangeBrightness &&
            el.brightness >= 0
          ) || (
            el.canChangeWhite &&
            el.white >= 0 &&
            el.mode === BAS_LIGHT.MODE_WHITE
          )
        )
      )).map(el => el.mode === BAS_LIGHT.MODE_WHITE ? el.white : el.brightness)

    if (brightnessValues.length) {

      const averageBrightness =
          brightnessValues.reduce((a, b) => a + b) / brightnessValues.length

      if (this.brightness !== averageBrightness) {

        this.updateBrightness(Math.round(averageBrightness))
      }
    } else {

      this.updateBrightness(0)
    }
  }

  /**
   * @returns {TBasLightGroupMinAndMaxTemp}
   */
  BasLight.prototype.getGroupMinAndMaxTemp = function () {

    const groupTempData = {
      highestColorTemp: 0,
      lowestColorTemp: 0
    }

    const lights = this.groupLightUuids
      .map(uuid => this._lights.basLights[uuid])
      .filter(Boolean)

    if (lights.length) {

      groupTempData.highestColorTemp =
          Math.max(...lights
            .filter(light => light.canChangeColorTemp)
            .map(light => light.colorTemperatureMax))

      groupTempData.lowestColorTemp =
          Math.min(...lights
            .filter(light => light.canChangeColorTemp)
            .map(light => light.colorTemperatureMin))
    }

    return groupTempData
  }

  /**
   * @param {BasLight} [basLight]
   */
  BasLight.prototype.syncBasLight = function (basLight) {

    if (basLight instanceof BasLight) {

      this.onState = basLight.onState
      this.updateBrightness(basLight.brightness)
      this.temperature = basLight.temperature
      this.syncRelTemperature()
      this.hue = basLight.hue
      this.saturation = basLight.saturation
      this.white = basLight.white
      this.timeStamp = basLight.timeStamp
      this.mode = basLight.mode
      this.height = basLight.height
      this.vertical = basLight.vertical
      this.makeColorStyle()
      this.syncModeUI()
    }
  }

  /**
   * @returns {TSceneLight}
   */
  BasLight.prototype.getSceneObj = function () {

    var result

    result = {}

    // Use mode to determine which properties to include
    // Else: include single property (color / temperature / white) based on
    //  capabilities
    switch (this.mode) {

      case BAS_LIGHT.MODE_COLOR:

        result.hue = this.hue
        result.saturation = this.saturation
        if (this.canChangeWhite) result.white = this.white
        break

      case BAS_LIGHT.MODE_COLOR_TEMPERATURE:

        result.colorTemperature = this.temperature
        break

      case BAS_LIGHT.MODE_WHITE:

        if (this._supportsWhiteViaBrightness) {
          result.white = this.white
        } else {
          // Do nothing, brightness is used
        }

        break

      case BAS_LIGHT.MODE_UNKNOWN:

        if (this.canChangeColor) {

          result.hue = this.hue
          result.saturation = this.saturation

        } else if (this.canChangeColorTemp) {

          result.colorTemperature = this.temperature

        }
    }

    if (this.canToggleOn) result.onOff = this.onState

    if (
      this.canChangeBrightness &&
      (
        this.mode !== BAS_LIGHT.MODE_WHITE ||
        (
          this.device !== null &&
          !this.device.allowsWrite(BAS_API.LightDevice.C_MODE)
        )
      )
    ) {

      result.brightness = this.brightness
    }

    if (this.canChangeMode) result.mode = BasLight.getMode(this.mode)
    if (this.canChangeVertical) result.vertical = this.vertical
    if (this.canChangeHeight) result.height = this.height

    return result
  }

  BasLight.prototype._makeTitleNameHtml = function () {

    this.titleNameHtml = '<div class="rs-modal-title">'

    if (BasUtil.isNEString(this.typeStr)) {

      this.titleNameHtml +=
        '<div class="rs-modal-title-type ellipsis-overflow">' +
        this.typeStr + '</div>'

      if (BasUtil.isNEString(this.name)) {

        this.titleNameHtml +=
          '<div class="rs-modal-title-divider"></div>'
        this.titleNameHtml +=
          '<div class="rs-modal-title-name ellipsis-overflow">' +
          this.name + '</div>'
      }

    } else if (BasUtil.isNEString(this.name)) {

      this.titleNameHtml +=
        '<div class="rs-modal-title-name ellipsis-overflow">' +
        this.name + '</div>'
    }

    if (BasUtil.isNEString(this.locationStr)) {

      this.titleNameHtml +=
        '<div class="rs-modal-title-location ellipsis-overflow">' +
        this.locationStr + '</div>'
    }

    this.titleNameHtml += '</div>'
  }

  /**
   * @returns {boolean}
   */
  BasLight.prototype.isEditable = function () {

    return (
      BasUtil.isObject(this.device) &&
      (
        this.device.allowsWrite(BAS_API.LightDevice.C_ON) ||
        this.device.allowsWrite(BAS_API.LightDevice.C_BRIGHTNESS) ||
        this.device.allowsWrite(BAS_API.LightDevice.C_WHITE) ||
        this.device.allowsWrite(BAS_API.LightDevice.C_COLOR) ||
        this.device.allowsWrite(BAS_API.LightDevice.C_COLOR_TEMPERATURE)
      )
    )
  }

  BasLight.prototype.syncRelTemperature = function () {

    this._relTemperature =
      (this.temperature - this.colorTemperatureMin) /
      this.colorTemperatureRange
  }

  BasLight.prototype.onToggleStateChange = function () {

    if (this.canSync && this.device) {

      this.updateOnState(this.device.onState)
    }

    this.enableToggleInput()

    $rootScope.$emit(BAS_LIGHT.EVT_LIGHT_DEVICE_TOGGLE_UPDATED)

    $rootScope.$applyAsync()
  }

  /**
   * Creates plugin compatible object of this BasLight instance.
   *
   * @returns {?TToolBarWidget}
   */
  BasLight.prototype.getCordovaPluginObject = function () {

    var pluginObj, button

    if (this.canToggleOn) {

      pluginObj = {}

      pluginObj[BAS_ELLIE.K_PLUGIN_UUID] = this.uuid
      pluginObj[BAS_ELLIE.K_PLUGIN_BUTTONS] = []

      button = {}
      button[BAS_ELLIE.K_PLUGIN_UUID] = this.uuid
      button[BAS_ELLIE.K_PLUGIN_BUTTON_TYPE] = 1
      button[BAS_ELLIE.K_PLUGIN_LABELS] = []

      pluginObj.buttons.push(button)

      return pluginObj
    }

    return null
  }

  /**
   * Creates plugin compatible object of this BasLight state.
   *
   * @returns {?TToolbarWidgetState}
   */
  BasLight.prototype.getCordovaPluginStateObject = function () {

    var pluginObj, button

    if (this.canToggleOn) {

      pluginObj = {}

      pluginObj[BAS_ELLIE.K_PLUGIN_UUID] = this.uuid
      pluginObj[BAS_ELLIE.K_PLUGIN_BUTTONS] = []

      button = {}
      button[BAS_ELLIE.K_PLUGIN_UUID] = this.uuid
      button[BAS_ELLIE.K_PLUGIN_STATE] = this.onState

      pluginObj[BAS_ELLIE.K_PLUGIN_BUTTONS].push(button)

      return pluginObj
    }

    return null
  }

  BasLight.prototype._clearBrightnessTimeout = function () {

    clearTimeout(this.brightnessTimer)
  }

  BasLight.prototype._clearWhiteTimeout = function () {

    clearTimeout(this.whiteTimer)
  }

  BasLight.prototype._onBrightnessChange = function () {

    this._clearBrightnessTimeout()
    this.brightnessTimer = setTimeout(
      this._syncBrightness,
      EVENT_DEBOUNCE
    )
  }

  BasLight.prototype._onWhiteChange = function () {

    this._clearWhiteTimeout()
    this.whiteTimer = setTimeout(
      this._syncWhite,
      EVENT_DEBOUNCE
    )
  }

  BasLight.prototype.__syncBrightness = function () {

    if (this.canSync && this.device) {

      this.updateBrightness(
        Math.round(this.device.brightness * 100)
      )
      $rootScope.$emit(BAS_LIGHT.EVT_LIGHT_DEVICE_BRIGHTNESS_UPDATED)
      $rootScope.$applyAsync()
    }
  }

  BasLight.prototype.__syncWhite = function () {

    if (this.canSync && this.device) {

      this.white = Math.round(this.device.white * 100)
      $rootScope.$emit(BAS_LIGHT.EVT_LIGHT_DEVICE_WHITE_UPDATED, this)
      $rootScope.$applyAsync()
    }
  }

  BasLight.prototype._clearColorTempTimeout = function () {

    clearTimeout(this.colorTempTimer)
  }

  BasLight.prototype._onTemperatureChange = function () {

    this._clearColorTempTimeout()
    this.colorTempTimer = setTimeout(
      this.onTemperatureChange.bind(this),
      EVENT_DEBOUNCE
    )
  }

  BasLight.prototype.onTemperatureChange = function () {

    if (this.canSync && this.device) {

      this.temperature = Math.round(this.device.colorTemperature)
      this.makeColorStyle()
      this.syncRelTemperature()
      $rootScope.$applyAsync()
    }
  }

  BasLight.prototype.onColorChange = function () {

    if (this.canSync && this.device) {

      this.hue = Math.round(this.device.hue * 360)
      this.saturation = Math.round(this.device.saturation * 100)
      this.makeColorStyle()
      $rootScope.$applyAsync()
    }
  }

  BasLight.prototype.onModeChange = function () {

    if (this.canSync && this.device) {
      this.mode = BasLight.getMode(this.device.mode)
      this.syncModeUI()
      this.makeColorStyle()
      $rootScope.$applyAsync()
    }
  }

  BasLight.prototype.syncModeUI = function () {

    if (this.device) {

      if (this.isLightGroup() && this.canChangeColor) {

        this.mode = BAS_LIGHT.MODE_COLOR
        this.uiToggleColor(true)
        this.uiToggleColorCircle(true)

      } else if (
        this.canChangeColor &&
        (this.canChangeColorTemp || this.canChangeWhite)
      ) {

        this.uiToggleColor(false)
        this.uiToggleTemp(false)
        this.uiToggleWhite(false)

        if (this.device.allowsWrite(BAS_API.LightDevice.C_MODE)) {

          switch (this.mode) {

            case BAS_LIGHT.MODE_COLOR:
            case BAS_LIGHT.MODE_UNKNOWN:

              this.uiToggleColor(true)
              this.uiToggleWhite(this.canChangeWhite)

              if (this._supportsWhiteViaBrightness) {
                this.uiToggleWhiteBrightness(false)
              }

              // RGBW lights initially start with color mode
              this.mode = BAS_LIGHT.MODE_COLOR

              break

            case BAS_LIGHT.MODE_COLOR_TEMPERATURE:

              this.uiToggleColor(false)
              this.uiToggleTemp(true)
              this.uiToggleWhite(this.canChangeWhite)

              break

            case BAS_LIGHT.MODE_WHITE:

              this._supportsWhiteViaBrightness
                ? this.uiToggleWhiteBrightness(true)
                : this.uiToggleWhite(true)

              break
          }
        } else {
          // Legacy RGBW light support
          this.uiToggleColor(this.canChangeColor)
          this.uiToggleTemp(this.canChangeColorTemp)
          this.uiToggleWhite(this.canChangeWhite)
        }

      } else {

        this.uiToggleColor(this.canChangeColor)
        this.uiToggleTemp(this.canChangeColorTemp)
        this.uiToggleWhite(this.canChangeWhite)
      }

      this.uiToggleVertical(this.canChangeVertical)
      this.uiToggleHeight(this.canChangeHeight)
    }
  }

  BasLight.prototype.onDeviceCapabilitiesChange = function () {

    this.syncDevice()

    $rootScope.$applyAsync()
  }

  BasLight.prototype.onVerticalChange = function () {

    this.syncDevice()

    $rootScope.$applyAsync()
  }

  BasLight.prototype.onHeightChange = function () {

    this.syncDevice()

    $rootScope.$applyAsync()
  }

  BasLight.prototype.onReachableChange = function () {

    $rootScope.$applyAsync()
  }

  /**
   * String representation of light state
   *
   * @returns {string}
   */
  BasLight.prototype.getString = function () {

    var result

    result = ''

    result += 'onOff' + this.onState
    result += 'brightness' + this.brightness
    result += 'colorTemperature' + this.temperature
    result += 'hue' + this.hue
    result += 'saturation' + this.saturation

    return result
  }

  BasLight.prototype.updateTranslation = function () {

    this.typeStr = BasLight.getTypeStr(this.type)
    this.locationStr = BasUtilities.getDeviceLocationStr(this.location)
    this.css[CSS_LIGHT_SHOW_SUBTITLE] =
      BasUtil.isNEString(this.locationStr)
    this.css[CSS_LIGHT_SHOW_SEPARATOR] =
      BasUtil.isNEString(this.typeStr) &&
      BasUtil.isNEString(this.name)

    if (!BasUtil.isNEString(this.name) &&
      !BasUtil.isNEString(this.typeStr) &&
      !BasUtil.isNEString(this.locationStr)) {

      this.name = '-'
    }

    this._makeTitleNameHtml()
  }

  /**
   * @param {boolean} [force]
   */
  BasLight.prototype.uiToggleColor = function toggleColor (force) {

    this.css[CSS_LIGHT_SHOW_COLOR] =
      typeof force === 'boolean'
        ? force
        : !this.css[CSS_LIGHT_SHOW_COLOR]
  }

  /**
   * @param {boolean} [force]
   */
  BasLight.prototype.uiToggleColorCircle = function (force) {

    this.css[CSS_LIGHT_SHOW_COLOR_CIRCLE] =
        typeof force === 'boolean'
          ? force
          : !this.css[CSS_LIGHT_SHOW_COLOR_CIRCLE]
  }

  /**
   * @param {boolean} [force]
   */
  BasLight.prototype.uiToggleWhite = function toggleWhite (force) {

    this.css[CSS_LIGHT_SHOW_WHITE] =
      typeof force === 'boolean'
        ? force
        : !this.css[CSS_LIGHT_SHOW_WHITE]
  }

  /**
   * @param {boolean} [force]
   */
  BasLight.prototype.uiToggleWhiteBrightness = function (force) {

    this.css[CSS_LIGHT_SHOW_WHITE_BRIGHTNESS] =
        typeof force === 'boolean'
          ? force
          : !this.css[CSS_LIGHT_SHOW_WHITE_BRIGHTNESS]
  }

  /**
   * @param {boolean} [force]
   */
  BasLight.prototype.uiToggleTemp = function toggleTemp (force) {

    this.css[CSS_LIGHT_SHOW_TEMP] =
      typeof force === 'boolean'
        ? force
        : !this.css[CSS_LIGHT_SHOW_TEMP]
  }

  /**
   * @param {boolean} [force]
   */
  BasLight.prototype.uiToggleMode = function toggleMode (force) {

    this.css[CSS_LIGHT_SHOW_COLOR_MODE] =
      typeof force === 'boolean'
        ? force
        : !this.css[CSS_LIGHT_SHOW_COLOR_MODE]
  }

  /**
   * @param {boolean} [force]
   */
  BasLight.prototype.uiToggleHeight = function (force) {

    this.css[CSS_LIGHT_SHOW_HEIGHT] =
      typeof force === 'boolean'
        ? force
        : !this.css[CSS_LIGHT_SHOW_HEIGHT]
  }

  /**
   * @param {boolean} [force]
   */
  BasLight.prototype.uiToggleVertical = function (force) {

    this.css[CSS_LIGHT_SHOW_LEVEL] =
      typeof force === 'boolean'
        ? force
        : !this.css[CSS_LIGHT_SHOW_LEVEL]
  }

  /**
   * @param {boolean} [force]
   */
  BasLight.prototype.uiToggleSlider = function toggleSlider (force) {

    this.css[CSS_LIGHT_SHOW_SLIDER] =
      typeof force === 'boolean'
        ? force
        : !this.css[CSS_LIGHT_SHOW_SLIDER]
  }

  /**
   * @param {boolean} mode
   */
  BasLight.prototype.setSyncMode = function (mode) {

    this.canSync = mode === true

    if (this.canSync) this.syncDevice()
  }

  BasLight.prototype.resetCss = function resetCss () {

    this.css[CSS_LIGHT_SHOW_COLOR] = false
    this.css[CSS_LIGHT_SHOW_COLOR_CIRCLE] = false
    this.css[CSS_LIGHT_SHOW_TEMP] = false
    this.css[CSS_LIGHT_SHOW_SLIDER] = false
    this.css[CSS_LIGHT_SHOW_LEVEL] = false
    this.css[CSS_LIGHT_SHOW_HEIGHT] = false
    this.css[CSS_LIGHT_SHOW_SUBTITLE] = false
    this.css[CSS_LIGHT_SHOW_SEPARATOR] = false
  }

  BasLight.prototype.resetCapabilities = function resetCss () {

    this.canToggleOn = false
    this.canChangeBrightness = false
    this.canChangeWhite = false
    this.canChangeColor = false
    this.canChangeColorTemp = false
    this.canChangeMode = false
  }

  BasLight.prototype.setDeviceListeners = function () {

    this._clearDeviceListeners()

    if (this.device) {

      this._deviceListeners.push(BasUtil.setEventListener(
        this.device,
        BAS_API.LightDevice.EVT_ON,
        this._handleToggleStateChange
      ))
      this._deviceListeners.push(BasUtil.setEventListener(
        this.device,
        BAS_API.LightDevice.EVT_BRIGHTNESS,
        this._handleBrightnessChange
      ))
      this._deviceListeners.push(BasUtil.setEventListener(
        this.device,
        BAS_API.LightDevice.EVT_WHITE,
        this._handleWhiteChange
      ))
      this._deviceListeners.push(BasUtil.setEventListener(
        this.device,
        BAS_API.LightDevice.EVT_COLOR_TEMPERATURE,
        this._handleTemperatureChange
      ))
      this._deviceListeners.push(BasUtil.setEventListener(
        this.device,
        BAS_API.LightDevice.EVT_COLOR_HSB,
        this._handleColorChange
      ))
      this._deviceListeners.push(BasUtil.setEventListener(
        this.device,
        BAS_API.LightDevice.EVT_MODE,
        this._handleModeChange
      ))
      this._deviceListeners.push(BasUtil.setEventListener(
        this.device,
        BAS_API.LightDevice.EVT_VERTICAL,
        this._handleVerticalChange
      ))
      this._deviceListeners.push(BasUtil.setEventListener(
        this.device,
        BAS_API.LightDevice.EVT_HEIGHT,
        this._handleHeightChange
      ))
      this._deviceListeners.push(BasUtil.setEventListener(
        this.device,
        BAS_API.Device.EVT_REACHABLE,
        this._handleReachableChanged
      ))
      this._deviceListeners.push(BasUtil.setEventListener(
        this.device,
        BAS_API.Device.EVT_CAPABILITIES,
        this._handleDeviceCapabilitiesChanged
      ))
    }
  }

  /**
   * Clears the API device listeners
   *
   * @private
   */
  BasLight.prototype._clearDeviceListeners = function () {

    BasUtil.executeArray(this._deviceListeners)
    this._deviceListeners = []
  }

  /**
   * @private
   */
  BasLight.prototype._syncGroupFeatures = function () {

    this.resetCapabilities()
    let colorModeSupported = true

    for (const groupLightUuid of this.groupLightUuids) {
      const groupLight = this._lights.basLights[groupLightUuid]

      if (groupLight instanceof BasLight) {

        if (groupLight.canToggleOn) {
          this.canToggleOn = true
          this.enableToggleInput()
        }

        // Light groups only supports color mode when all it's children support
        // color mode as well.
        if (!groupLight.canChangeColor) colorModeSupported = false

        if (groupLight.canChangeBrightness) {

          this.canChangeBrightness = true
          this.uiToggleSlider(true)
        }

        if (groupLight.canChangeWhite) this.canChangeWhite = true
        if (groupLight.canChangeVertical) this.canChangeVertical = true
        if (groupLight.canChangeHeight) this.canChangeHeight = true

        this.canChangeColor = colorModeSupported

        groupLight.syncModeUI()
      }
    }
  }

  /**
   * Clears the device listeners and the device reference
   */
  BasLight.prototype.clear = function clear () {

    this._clearDeviceListeners()
    this.device = null
    this._lights = null
  }

  BasLight.prototype.suspend = function suspend () {

    this._clearDeviceListeners()
    this._clearBrightnessTimeout()
    this._clearWhiteTimeout()
    this._clearColorTempTimeout()
  }

  return BasLight
}
