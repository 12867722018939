'use strict'

const BasUtil = require('@basalte/bas-util')

angular
  .module('basalteApp')
  .controller('callHistoryCtrl', [
    '$scope',
    '$rootScope',
    '$uiRouterGlobals',
    'STATES',
    'UI_HELPER',
    'BasCallHistory',
    'BasState',
    'BasUtilities',
    'BAS_CALL_HISTORY',
    'UiHelper',
    callHistoryCtrl
  ])

/**
 * @param $scope
 * @param $rootScope
 * @param $uiRouterGlobals
 * @param {STATES} STATES
 * @param {UI_HELPER} UI_HELPER
 * @param {BasCallHistory} BasCallHistory
 * @param {BasState} BasState
 * @param {BasUtilities} BasUtilities
 * @param {BAS_CALL_HISTORY} BAS_CALL_HISTORY
 * @param {UiHelper} UiHelper
 */
function callHistoryCtrl (
  $scope,
  $rootScope,
  $uiRouterGlobals,
  STATES,
  UI_HELPER,
  BasCallHistory,
  BasState,
  BasUtilities,
  BAS_CALL_HISTORY,
  UiHelper
) {
  const callHistory = this

  /**
   * @type {BasUi}
   */
  const basUi = UiHelper.get()

  let _listeners = []

  callHistory.basUi = basUi

  /**
   * @type {TCallHistoryState}
   */
  callHistory.callHistory = BasCallHistory.get()

  callHistory.toggleHideEntries = toggleHideEntries

  callHistory.$uiRouterGlobals = $uiRouterGlobals

  callHistory.goToDetail = goToDetail

  callHistory.collapsibleHeaders = {}

  callHistory.hasCallEntries = false

  init()

  function init () {
    $scope.$on('$destroy', onDestroy)
    if (BasUtil.isNEObject(callHistory.callHistory.formattedUiCallHistory)) {

      selectFirstEntryIfScreenIsBig()

    } else {

      BasCallHistory.retrievePaginated().then(selectFirstEntryIfScreenIsBig)
    }
    _listeners.push($rootScope.$on(
      UI_HELPER.EVT_RESIZE,
      onUiSize
    ))

    _listeners.push($rootScope.$on(
      BAS_CALL_HISTORY.EVT_CALL_HISTORY_UPDATED,
      createCollapsibleHeaders
    ))

    createCollapsibleHeaders()
  }

  /**
   * @param {string} contactUuid
   * @param {string} timestamp
   */
  function goToDetail (contactUuid, timestamp) {

    BasState.go(
      STATES.CALL_HISTORY_DETAIL,
      {
        contactUuid: contactUuid,
        timeStamp: timestamp
      }
    )
  }

  /**
   * @private
   */
  function onUiSize () {
    const contactUuid = $uiRouterGlobals.params.contactUuid
    const timeStamp = $uiRouterGlobals.params.timeStamp

    if (BasUtil.isNEString(contactUuid) && BasUtil.isNEString(timeStamp)) {

      goToDetail(contactUuid, timeStamp)
    } else {

      selectFirstEntryIfScreenIsBig()
    }
  }

  /**
   * @private
   */
  function selectFirstEntryIfScreenIsBig () {

    if (
      basUi.prop.wMedium &&
      BasUtil.isNEObject(callHistory.callHistory.uiCallHistory)
    ) {

      const firstEntry = Object.values(callHistory.callHistory.uiCallHistory)[0]
      goToDetail(firstEntry.contact.uuid, firstEntry.timestamp)
    }
  }

  /**
   * @private
   */
  function createCollapsibleHeaders () {
    const timestampKeys =
      Object.keys(callHistory.callHistory.formattedUiCallHistory)
    const firstKey = timestampKeys[0]

    callHistory.hasCallEntries =
      !!Object.keys(callHistory.callHistory.uiCallHistory).length

    timestampKeys.forEach(function (key) {
      if (!Object.keys(callHistory.collapsibleHeaders).includes(key)) {
        callHistory.collapsibleHeaders[key] = {
          isCollapsed: firstKey !== key,
          translation: getTimestampTranslation(key)
        }
      }
    })

    $rootScope.$applyAsync()
  }

  /**
   * Translate the key so the translation can be used in the template
   *
   * @param {string} key
   */
  function getTimestampTranslation (key) {
    // Example key: month_april-year_2023
    if (key.includes('year')) {
      const [month, yearString] = key.split('-')
      // E.g. year_2023
      const year = yearString.split('_')[1]

      return `${BasUtilities.translate(month)} ${year}`

    } else {
      // Example key: today, yesterday, this_week, month_april
      return BasUtilities.translate(key)

    }
  }

  /**
   *
   * @param {string} key
   */
  function toggleHideEntries (key) {
    const header = callHistory.collapsibleHeaders[key]

    header.isCollapsed = !header.isCollapsed
  }

  /**
   * @private
   */
  function onDestroy () {
    BasCallHistory.clearState()
    BasUtil.executeArray(_listeners)
    _listeners = []
  }
}
