'use strict'

var BasUtil = require('@basalte/bas-util')

var BasServer = require('./bas_server')

var CONSTANTS = require('./constants')

var Demo = require('./demo')

/**
 * @constructor
 * @extends BasServer
 * @param {(Object | Demo)} demo
 * @since 3.0.0
 */
function BasDemoServer (demo) {

  BasServer.call(
    this,
    {
      macAddress: Demo.DEMO_MAC_N,
      cid: Demo.DEMO_CID
    }
  )

  if (demo instanceof Demo) {

    if (this._demo && this._demo.destroy && this._demo !== demo) {
      this._demo.destroy()
    }

    this._demo = demo

  } else if (BasUtil.isObject(demo)) {

    if (this._demo && this._demo.destroy) {
      this._demo.destroy()
    }

    this._demo = new Demo(demo)
  }

  this._socket = this._demo.socket
}

BasDemoServer.prototype = Object.create(BasServer.prototype)
BasDemoServer.prototype.constructor = BasDemoServer

/**
 * @param {TBasServerRequestConfig} config
 * @returns {Promise<TBasServerResponse>}
 */
BasDemoServer.prototype.request = function (config) {

  return this._demo.handleHttpRequest(config)
}

/**
 * @param {TBasServerRequestConfig} config
 * @returns {Promise}
 */
// eslint-disable-next-line no-unused-vars
BasDemoServer.prototype.requestProxy = function (config) {

  return Promise.reject(CONSTANTS.ERR_NO_CORE)
}

/**
 * @param {string} path
 * @returns {Promise<string>}
 */
BasDemoServer.prototype.requestImageSource = function (path) {

  return BasUtil.isNEString(path)
    ? Promise.resolve(path)
    : Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * @param {(TCoreCredentials|Array<TCoreCredentials>)} credentials
 * @returns {Promise}
 */
BasDemoServer.prototype.connectToCore = function (credentials) {

  return this._demo.openSocket(
    Array.isArray(credentials)
      ? credentials[0]
      : credentials
  )
}

/**
 * @returns {Promise}
 */
BasDemoServer.prototype.disconnectFromCore = function () {

  if (this._demo && this._demo.destroy) this._demo.destroy()

  return this._demo.socket.close()
}

/**
 * @returns {boolean}
 */
BasDemoServer.prototype.isConnected = function () {

  return true
}

/**
 * @returns {boolean}
 */
BasDemoServer.prototype.isCoreConnected = function () {

  return this._socket ? this._socket.isConnected() : false
}

/**
 * Creates a clone of this BasServer
 *
 * @returns {BasDemoServer}
 */
BasDemoServer.prototype.clone = function () {

  return new BasDemoServer(this._demo)
}

module.exports = BasDemoServer
